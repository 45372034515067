import { LOAD_PRICE } from '../../constants/actionTypes'
import React from 'react'
import agent from '../../agent'
import { connect } from 'react-redux'
import { getState } from '../../store'
import CountUp from 'react-countup'
import { getCurrency } from '../../utils/price'
import { getOrder } from '../../Stores/Order'

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload, actualSendData) => dispatch({ type: LOAD_PRICE, payload, actualSendData })
})

class PriceText extends React.Component {
  state = {
    sendData: null,
    price: 0
  }

  componentDidUpdate(prevProps, prevState) {
    const newSendData = JSON.stringify(this.props.sendData)

    if (this.props.isPriceLoaded) {
      if (newSendData !== prevState.sendData && newSendData !== this.props.actualSendData) {
        this.setState({ sendData: newSendData })

        if (newSendData.webUUId !== '') {
          this.props.onLoad(agent.Price.get(newSendData), newSendData)
        }
      }

      const siteUUID = getState().uuid
      const uuId = getOrder().getUUId()

      if (this.props.price) {
        if (this.props.price.uuid) {
          if (siteUUID === '') {
            getState().setUUId(this.props.price.uuid)
          }
          if (uuId === '') {
            getOrder().setUUId(this.props.price.uuid)
          }
        }

        if (
          (prevProps.price.insert && this.props.price.insert !== prevProps.price.insert) ||
          (!prevProps.price.insert && this.props.price.insert)
        ) {
          getOrder().setInsert(this.props.price.insert)
        }

        if (
          (prevProps.price.objId && this.props.price.objId !== prevProps.price.objId) ||
          (!prevProps.price.objId && this.props.price.objId)
        ) {
          getOrder().setObjId(this.props.price.objId)
        }
      }
    }
  }

  _onChange() {
    const newPrice = getState().getPrice()

    if (newPrice !== this.state.price) {
      this.setState({ price: newPrice })
    }
  }

  render() {
    let newPrice = 0

    if (this.props.price && this.props.price.prices && this.props.price.prices.wardrobe) {
      newPrice = this.props.price.prices.wardrobe.priceWithVat
      getState().setPrice(newPrice)
    }

    let currency = ' '

    if (this.props.price && this.props.price.currencyCode) {
      currency += getCurrency(this.props.price.currencyCode)
    }

    if (newPrice !== 0 && this.props.showPrice && process.env.REACT_APP_SHOW_PRE_PRICE === 'true') {
      return (
        <>
          <CountUp
            start={this.state.price}
            end={newPrice}
            duration={2.75}
            separator=' '
            decimals={2}
            decimal=','
            prefix=''
            suffix={' ' + currency}
            onEnd={() => this._onChange()}
          />
        </>
      )
    } else {
      return <></>
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceText)
