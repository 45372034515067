import React, { useCallback, useState } from 'react'
import { Segment, Modal, Image, Header } from 'semantic-ui-react'
import { Lang } from '../../Utils'
import { getState } from '../../store'
import { getSteps } from '../../Stores/Steps'
import { useHistory } from 'react-router-dom'
import { useOrder } from '../../Stores/Order'
import { v4 as uuid } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { isMobile } from 'react-device-detect'
import parse from 'html-react-parser'
import { ORDER_IMAGES } from '../../config'
import { getOrderTypes, isSelectOrderTypeAvailable } from '../../utils/orders'
import { setStep } from '../../utils/steps'

let dataLayer = (window.dataLayer = window.dataLayer || [])

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 100
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}))

const SelectTypeCard = ({ type, onClick }) => {
  const classes = useStyles()
  const [, setSelected] = useState(false)
  const typeName = 'Type' + type.toUpperCase() + 'Name'
  const imageName = ORDER_IMAGES[type]

  return (
    <Card className={classes.root} onPointerDown={onClick} onPointerOver={() => setSelected(true)} onPointerOut={() => setSelected(false)}>
      <CardMedia className={classes.cover} image={process.env.PUBLIC_URL + '/images/Types/' + imageName} title={Lang(typeName)} />
      <div className={classes.details}>
        <CardContent>
          <Typography component='h6' variant='h6'>
            {parse(Lang(typeName))}
          </Typography>
        </CardContent>
      </div>
    </Card>
  )
}

const SelectTypeSegment = ({ type, onClick }) => {
  const [isSelected, setSelected] = useState(false)
  const typeName = 'Type' + type.toUpperCase() + 'Name'
  const imageName = ORDER_IMAGES[type]

  return (
    <Segment
      as='a'
      color={isSelected ? 'green' : 'grey'}
      onPointerDown={onClick}
      className='selectObjType'
      onPointerOver={() => setSelected(true)}
      onPointerOut={() => setSelected(false)}
    >
      <Image size='medium' src={process.env.PUBLIC_URL + '/images/Types/' + imageName} />
      <Header color={isSelected ? 'green' : 'black'}>{parse(Lang(typeName))}</Header>
    </Segment>
  )
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const SelectTypeModal = () => {
  const history = useHistory()
  const available_orders = getOrderTypes()
  const actualStep = getSteps().actualStep
  const [isOpen, setOpen] = useState(actualStep !== 'load')
  const setObjType = getState().setObjType
  const setRoomType = getState().setRoomType
  const setUUId = useOrder((state) => state.setUUId)

  const onClickModal = useCallback(
    (e, type) => {
      e.stopPropagation() // stop it at the first intersection
      setOpen(false)
      setObjType(type)

      if (type === 'd') {
        setUUId(uuid())
        setRoomType('a')
        setStep('setWardrobeParameters')
        history.push('/' + Lang('setWardrobeParameters', 'navigation'))
      }

      const data = {
        event: 'objTypeSelected',
        yourData: {
          type: type
        }
      }

      dataLayer.push(data)
      /*
                  let event = ''

                  switch (type) {
                    case 'vd':
                      event = event + ' Komplet'
                      break
                    case 'v':
                      event = event + ' Vnutro'
                      break
                    case 'd':
                      event = event + ' Posuv'
                      break
                    default:
                      event = 'Lead'
                  }
                  */
    },
    [setOpen, setObjType, setRoomType, Lang, setUUId, uuid]
  )

  if (isSelectOrderTypeAvailable()) {
    if (isMobile) {
      return (
        <Modal open={isOpen}>
          <Modal.Header>{Lang('SelectTypeInfo')}</Modal.Header>
          <Modal.Content>
            {available_orders.map((type) => (
              <>
                <SelectTypeCard type={type} onClick={(e) => onClickModal(e, type)} />
                <Divider />
              </>
            ))}
          </Modal.Content>
        </Modal>
      )
    } else {
      return (
        <Modal open={isOpen}>
          <Modal.Header>{Lang('SelectTypeInfo')}</Modal.Header>
          <Modal.Content>
            <Segment.Group horizontal>
              {available_orders.map((type, index) => (
                <SelectTypeSegment key={index} type={type} onClick={(e) => onClickModal(e, type)} />
              ))}
            </Segment.Group>
          </Modal.Content>
        </Modal>
      )
    }
  } else {
    return <></>
  }
}

export default SelectTypeModal
