import React, { useState, useRef, useCallback } from 'react'
import { getState } from '../store'
import Popup from 'react-popup'
import { Html } from 'drei'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Lang } from '../Utils'
import { withStyles, makeStyles } from '@material-ui/core/styles'

class Prompt extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: this.props.defaultValue
    }

    this.onChange = (e) => this._onChange(e)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      this.props.onChange(this.state.value)
    }
  }

  _onChange(e) {
    let value = e.target.value

    this.setState({ value: value })
  }

  render() {
    return (
      <input
        type='text'
        placeholder={this.props.placeholder}
        className='mm-popup__input'
        value={this.state.value}
        onChange={this.onChange}
      />
    )
  }
}

/** Prompt plugin */
Popup.registerPlugin('prompt', function (defaultValue, placeholder, callback) {
  let promptValue = null
  let promptChange = function (value) {
    promptValue = value
  }

  this.create({
    title: "What's your name?",
    content: <Prompt onChange={promptChange} placeholder={placeholder} value={defaultValue} />,
    buttons: {
      left: ['cancel'],
      right: [
        {
          text: 'Save',
          key: '⌘+s',
          className: 'success',
          action: function () {
            callback(promptValue)
            Popup.close()
          }
        }
      ]
    }
  })
})

const useStyles = makeStyles(() => ({
  inputContent: {
    width: 100,
    padding: 10
  },
  inputSmallContent: {
    width: 70,
    padding: 5
  },
  inputContentField: {
    borderColor: 'green'
  }
}))

const ValidationInputAdornment = withStyles({
  root: {
    '& input': {
      borderColor: 'green',
      borderWidth: 2
    },
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important' // override inline-style
    }
  }
})(InputAdornment)

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'green'
      },
      '& fieldset error': {
        borderColor: 'red'
      },

      '&:hover fieldset': {
        borderColor: 'blue'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green'
      }
    }
  }
})(TextField)

/**
 * @param minus
 * @param children
 * @param size
 * @param color
 * @param letterSpacing
 * @param textRotation
 * @param textPositionX
 * @param textPositionY
 * @param editable
 * @param type
 * @param editType
 * @param showInput
 * @param slowScale
 * @returns {JSX.Element}
 * @constructor
 */
const Altitude = ({
  minus = 0,
  children,
  value,
  color = '#000000',
  textRotation = [0, 0, 0],
  textPositionX = 0,
  textPositionY = 0,
  type = '',
  editType = null,
  showInput = false,
  align = null
}) => {
  const classes = useStyles()
  const [, setSelected] = useState(false)
  const setShowModal = getState().setShowModal
  const textGroup = useRef()

  if (minus) {
    value -= minus
  }

  const onClickSetValue = useCallback(
    (e) => {
      e.stopPropagation() // stop it at the first intersection
      setShowModal('edit_' + editType)
    },
    [setShowModal, editType]
  )

  let positionX = 0

  if (align && align === 'right') {
    positionX = 20
  }

  return (
    <group position-y={value / 2} position-x={positionX}>
      <group position-y={value / 2}>
        <mesh position-y={-1}>
          <planeGeometry attach='geometry' args={[2, 1]} />
          <meshStandardMaterial attach='material' color={color} />
        </mesh>
        <mesh position-y={-2}>
          <planeGeometry attach='geometry' args={[4, 1]} />
          <meshStandardMaterial attach='material' color={color} />
        </mesh>
      </group>
      <mesh>
        <planeGeometry attach='geometry' args={[1, value]} />
        <meshStandardMaterial attach='material' color={color} />
      </mesh>
      <group rotation={textRotation} position-x={textPositionX} position-y={textPositionY}>
        {!showInput && (
          <group
            ref={textGroup}
            position={[5, 0, 0]}
            onPointerDown={(e) => onClickSetValue(e)}
            onPointerOver={() => setSelected(true)}
            onPointerOut={() => setSelected(false)}
          >
            {children}
          </group>
        )}
        {showInput && editType && type !== 'corpuses_space_top' && (
          <Html>
            <Paper className={classes.inputContent}>
              <CssTextField
                error={false}
                variant='outlined'
                required
                id='standard-required'
                label={Lang('EditInputLabel' + editType)}
                InputProps={{
                  endAdornment: <ValidationInputAdornment position='start'>cm</ValidationInputAdornment>
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Paper>
          </Html>
        )}
      </group>
      <group position-y={0 - value / 2}>
        <mesh position-y={1}>
          <planeGeometry attach='geometry' args={[2, 1]} />
          <meshStandardMaterial attach='material' color={color} />
        </mesh>
        <mesh position-y={2}>
          <planeGeometry attach='geometry' args={[4, 1]} />
          <meshStandardMaterial attach='material' color={color} />
        </mesh>
      </group>
    </group>
  )
}

export default Altitude
