import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Alert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { Lang } from '../../../../Utils'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  additionalStartText: {
    paddingBottom: 10
  }
}))

/**
 * @param {Object} openDialogErrors
 * @param {Boolean} openErrorDialog
 * @param {Function} setOpenErrorDialog
 * @param {String} additionalStartText
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorDialog = ({ openDialogErrors, openErrorDialog, setOpenErrorDialog, additionalStartText = null }) => {
  const classes = useStyles()

  const handleClose = () => {
    setOpenErrorDialog(false)
  }

  return (
    <Dialog open={openErrorDialog} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <Alert variant='outlined' severity='error'>
            {additionalStartText && (
              <div className={classes.additionalStartText}>
                <Typography variant='caption'>{additionalStartText}</Typography>
              </div>
            )}
            {openDialogErrors && openDialogErrors.map((openDialogError, index) => <Typography key={index}>{openDialogError}</Typography>)}
          </Alert>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary' autoFocus>
          {Lang('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ErrorDialog
