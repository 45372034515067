import React, { useMemo } from 'react'
import { MeshBasicMaterial } from 'react-three-fiber/components'

/**
 * @param {Object} roomAttributes
 * @param {Object} wardrobeAttributes
 * @returns {JSX.Element}
 * @constructor
 */
const SmallCeiling = ({ roomAttributes, wardrobeAttributes }) => {
  const roomType = roomAttributes.type
  const roomHeight = roomAttributes.height
  const wardrobeDepth = wardrobeAttributes.depth
  const wardrobeWidth = wardrobeAttributes.width
  let startPositionX = 0
  let floorWidth = wardrobeWidth

  if (roomAttributes.backWallType === 'b' && (roomAttributes.type === 'b' || roomAttributes.type === 'c')) {
    floorWidth += 100
    startPositionX = -50
  }

  const positionX = useMemo(() => (roomType !== 'b' ? startPositionX - 100 : startPositionX), [roomType, startPositionX])
  const positionY = useMemo(() => roomHeight / 2, [roomHeight])

  return (
    <mesh position={[positionX, positionY, 0]} rotation={[Math.PI / 2, 0, 0]}>
      <planeGeometry attach='geometry' args={[floorWidth, wardrobeDepth]} />
      <MeshBasicMaterial attach='material' color='#fff' />
    </mesh>
  )
}

export default React.memo(SmallCeiling)
