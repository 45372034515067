/*
import CorpusType1 from "./Preview/Corpuses/Type1";
import CorpusType2 from "./Preview/Corpuses/Type2";
import CorpusType3 from "./Preview/Corpuses/Type3";
import CorpusType4 from "./Preview/Corpuses/Type4";
import CorpusType20 from "./Preview/Corpuses/Type20";
import CorpusType21 from "./Preview/Corpuses/Type21";
import CorpusType26 from "./Preview/Corpuses/Type26";
import CorpusType29 from "./Preview/Corpuses/Type29";
import CorpusType5 from "./Preview/Corpuses/Type5";
import CorpusType6 from "./Preview/Corpuses/Type6";
import CorpusType7 from "./Preview/Corpuses/Type7";
import CorpusType8 from "./Preview/Corpuses/Type8";
import CorpusType10 from "./Preview/Corpuses/Type10";
import CorpusType9 from "./Preview/Corpuses/Type9";
import CorpusType11 from "./Preview/Corpuses/Type11";
import CorpusType12 from "./Preview/Corpuses/Type12";
import CorpusType18 from "./Preview/Corpuses/Type18";
import CorpusType13 from "./Preview/Corpuses/Type13";
import CorpusType17 from "./Preview/Corpuses/Type17";
import CorpusType14 from "./Preview/Corpuses/Type14";
import CorpusType15 from "./Preview/Corpuses/Type15";
import CorpusType16 from "./Preview/Corpuses/Type16";
import CorpusType19 from "./Preview/Corpuses/Type19";
*/
import { isSelectTypeAvailable } from './utils/room'

export const DRAWERS_HEIGHT = { 1: 23.6, 2: 43.6, 3: 63.6, 4: 83.6 }
export const BOARD_DEPTH = 1.8
export const GROUP = 'Spaces'
export const FREE_SPACE = 10
export const ROOM_DEPTH = 400
export const DEFAULT_ROOM_TYPE = 'a'
export const DEFAULT_ORDER_TYPE = 'vd'
export const DEFAULT_SPACE_MIN_WIDTH = 20
export const SHOW_DECORS = false
export const CORPUSES_CATEGORIES = ['LongHanging', 'ShortHanging', 'CombHanging', 'Shoes', 'Shelf', 'Drawers']

export const CORPUSES_CATEGORIES_LIST = {
  LongHanging: [{ id: '20', minWidth: 45 }, { id: '1' }],
  ShortHanging: [
    { id: '2' },
    { id: '4' },
    { id: '3' },
    { id: '21', minWidth: 45 },
    { id: '26' },
    { id: '27' },
    { id: '28' },
    { id: '29', minWidth: 45 },
    { id: '30' },
    { id: '31' }
  ],
  CombHanging: [
    { id: '24', minWidth: 45 },
    { id: '25', minWidth: 45 },
    { id: '22', minWidth: 45 },
    { id: '23', minWidth: 45 }
  ],
  Shoes: [{ id: '32' }],
  Shelf: [{ id: '5' }, { id: '6' }, { id: '7' }, { id: '8' }],
  Drawers: [
    { id: '10' },
    { id: '9' },
    { id: '11' },
    { id: '12' },
    { id: '18' },
    { id: '13' },
    { id: '17' },
    { id: '14' },
    { id: '15' },
    { id: '16' },
    { id: '19' }
  ]
}

const defaultDrawerMinWidth = 45

export const CORPUSES_LIST = {
  20: { minWidth: defaultDrawerMinWidth },
  1: { minWidth: null },
  2: { minWidth: null },
  4: { minWidth: null },
  3: { minWidth: null },
  21: { minWidth: defaultDrawerMinWidth },
  26: { minWidth: null, minHeight: 215 },
  29: { minWidth: defaultDrawerMinWidth },
  5: { minWidth: null },
  6: { minWidth: null },
  7: { minWidth: null },
  8: { minWidth: null },
  10: { minWidth: defaultDrawerMinWidth },
  9: { minWidth: defaultDrawerMinWidth },
  11: { minWidth: defaultDrawerMinWidth },
  12: { minWidth: defaultDrawerMinWidth },
  13: { minWidth: defaultDrawerMinWidth },
  14: { minWidth: defaultDrawerMinWidth },
  15: { minWidth: defaultDrawerMinWidth },
  16: { minWidth: defaultDrawerMinWidth },
  17: { minWidth: defaultDrawerMinWidth },
  18: { minWidth: defaultDrawerMinWidth },
  19: { minWidth: defaultDrawerMinWidth, minHeight: 215 },
  24: { minWidth: 75 },
  25: { minWidth: 75 },
  27: { minWidth: null },
  28: { minWidth: null },
  22: { minWidth: 75 },
  23: { minWidth: 75 },
  30: { minWidth: null, minHeight: 205 },
  31: { minWidth: null, minHeight: 205 },
  32: { minWidth: null }
}

/*
export const CORPUSE_TYPES = [
  "20",
  "1",
  "2",
  "4",
  "3",
  "21",
  "26",
  "29",
  "5",
  "6",
  "7",
  "8",
  "10",
  "9",
  "11",
  "12",
  "18",
  "13",
  "17",
  "14",
  "15",
  "16",
  "19"
];

export const CORPUSE_COMPONENETS_MAP = {
    "20": CorpusType20,
    "1": CorpusType1,
    "2": CorpusType2,
    "4": CorpusType4,
    "3": CorpusType3,
    "21": CorpusType21,
    "26": CorpusType26,
    "29": CorpusType29,
    "5": CorpusType5,
    "6": CorpusType6,
    "7": CorpusType7,
    "8": CorpusType8,
    "10": CorpusType10,
    "9": CorpusType9,
    "11": CorpusType11,
    "12": CorpusType12,
    "18": CorpusType18,
    "13": CorpusType13,
    "17": CorpusType17,
    "14": CorpusType14,
    "15": CorpusType15,
    "16": CorpusType16,
    "19": CorpusType19
};
*/
export const DOOR_TYPES = ['1']
/*
export const DOOR_COMPONENETS_MAP = {
    "1": CorpusType20
};
*/
export const ICONS = {
  selectCorpuses: 'columns',
  selectDTD: 'columns',
  selectDTDIn: 'columns',
  selectDTDOut: 'columns',
  selectDoors: 'pause',
  setWardrobeParameters: 'expand',
  roomType: 'home',
  order: 'cart'
}

export const STEP_MAIN_TAB = {
  roomType: 'roomType',
  setWardrobeParameters: 'setWardrobeParameters',
  selectCorpusesSetSpacesCount: 'setWardrobeParameters',
  selectCorpuses: 'selectCorpuses',
  selectDTD: 'selectCorpuses',
  selectDTDIn: 'selectCorpuses',
  selectDTDOut: 'selectCorpuses',
  selectDoorSpacesCount: 'selectDoors',
  selectProfiles: 'selectDoors',
  selectDoorsFill: 'selectDoors',
  priceCalculation: 'order',
  order: 'order'
}

//export const START_STEP = "order";
export const START_STEP = isSelectTypeAvailable() ? 'roomType' : 'setWardrobeParameters'

export const PREVIEW_STEP = {
  vd: {
    roomType: '',
    setWardrobeParameters: 'roomType',
    selectCorpusesSetSpacesCount: 'setWardrobeParameters',
    selectCorpuses: 'selectCorpusesSetSpacesCount',
    selectDTDIn: 'selectCorpuses',
    selectDTDOut: 'selectDTDIn',
    selectDoorSpacesCount: 'selectDTDOut',
    selectProfiles: 'selectDoorSpacesCount',
    selectDoorsFill: 'selectProfiles',
    accessories: 'selectDoorsFill',
    priceCalculation: 'accessories',
    order: 'priceCalculation'
  },
  d: {
    setWardrobeParameters: '',
    selectCorpusesSetSpacesCount: 'setWardrobeParameters',
    selectDoorSpacesCount: 'selectCorpusesSetSpacesCount',
    selectProfiles: 'selectDoorSpacesCount',
    selectDoorsFill: 'selectProfiles',
    selectDTDOut: 'selectDoorsFill',
    accessories: 'selectDTDOut',
    priceCalculation: 'accessories',
    order: 'priceCalculation'
  },
  v: {
    roomType: '',
    setWardrobeParameters: 'roomType',
    selectCorpusesSetSpacesCount: 'setWardrobeParameters',
    selectCorpuses: 'selectCorpusesSetSpacesCount',
    selectDTDIn: 'selectCorpuses',
    accessories: 'selectDTDIn',
    priceCalculation: 'accessories',
    order: 'priceCalculation'
  }
}

export const NEXT_STEP = {
  vd: {
    roomType: 'setWardrobeParameters',
    setWardrobeParameters: 'selectCorpusesSetSpacesCount',
    selectCorpusesSetSpacesCount: 'selectCorpuses',
    selectCorpuses: 'selectDTDIn',
    selectDTDIn: 'selectDTDOut',
    selectDTDOut: 'selectDoorSpacesCount',
    selectDoorSpacesCount: 'selectProfiles',
    selectProfiles: 'selectDoorsFill',
    selectDoorsFill: process.env.REACT_APP_LAST_STEP
  },
  d: {
    setWardrobeParameters: 'selectCorpusesSetSpacesCount',
    selectCorpusesSetSpacesCount: 'selectDoorSpacesCount',
    selectDoorSpacesCount: 'selectProfiles',
    selectProfiles: 'selectDoorsFill',
    selectDoorsFill: 'selectDTDOut',
    selectDTDOut: process.env.REACT_APP_LAST_STEP
  },
  v: {
    roomType: 'setWardrobeParameters',
    setWardrobeParameters: 'selectCorpusesSetSpacesCount',
    selectCorpusesSetSpacesCount: 'selectCorpuses',
    selectCorpuses: 'selectDTDIn',
    selectDTDIn: process.env.REACT_APP_LAST_STEP
  }
}

export const MUST_SET_SPACES_COUNT_BEFORE = {
  //selectCorpuses: "basicSettings",
  //selectDoors: "selectDoorsSetSpacesCount"
}

export const HIDE_DOORS = [
  'basicSettings',
  'selectCorpusesVariants',
  'selectCorpuses',
  'selectDTD',
  'selectDTDIn',
  'selectDTDOut',
  'selectSecondDTD'
]

export const SHOW_DOORS = ['selectDoorsVariants', 'selectDoorSpacesCount', 'selectProfiles', 'selectDoorsFill']

export const PROFILES = {
  alOpen: {
    1890082: 'white',
    2027: 'sampan',
    2002: 'silver',
    2030: 'gold'
  },
  alClosed: {
    1890091: 'white',
    2036: 'sampan',
    2035: 'silver',
    2191: 'gold'
  },
  alMinimax: {
    72721: 'silver'
  }
}

export const HIDE_TOP_PRICE = ['roomType', 'accessories', 'priceCalculation', 'order']

export const HIDE_DIMENSIONS = ['roomType']

export const HIDE_RIGHT_ATTRIBUTES_INFO_BLOCK = [
  'selectDoorsFill',
  'selectDoorSpacesCount',
  'selectCorpuses',
  'selectDTD',
  'selectDTDIn',
  'selectDTDOut',
  'selectProfiles'
]

export const SHOW_DIMENSIONS = []

export const PRIMARY_COLOR = process.env.REACT_APP_PRIMARY_COLOR ?? '#F7941D'

export const MIN_DOOR_FILL_HEIGHT = 10

export const DEFAULT_ORDERS = 'd, v, vd'

export const ORDER_IMAGES = {
  d: 'posuv640x470.jpg',
  v: 'vnutro640x470.jpg',
  vd: 'skrina640_470.jpg'
}
