import { getState } from '../store'
import { MUST_SET_SPACES_COUNT_BEFORE } from '../config'
import { getSteps } from '../Stores/Steps'

/**
 * @param {String} step
 */
export function setStep(step) {
  const actualStep = getSteps().actualStep

  if (actualStep !== step) {
    const spacesCount = getState().spacesCount
    const selectedSpace = getState().selectedSpace
    const selectedDoorsSpace = getState().selectedDoorsSpace

    if (spacesCount < 2 && MUST_SET_SPACES_COUNT_BEFORE[step]) {
      step = MUST_SET_SPACES_COUNT_BEFORE[step]
    }

    if (step === 'selectDoors') {
      let count = 0
      if (typeof selectedDoorsSpace[selectedSpace] !== 'undefined') {
        count = selectedDoorsSpace[selectedSpace]
      }

      if (!count && selectedSpace) {
        step = 'selectDoorSpacesCount'
      }
    }

    if (actualStep === 'setWardrobeParameters') {
      //ReactPixel.track('InitiateCheckout', {value: priceValue, currency: 'EUR',});
    }

    getSteps().setActualStep(step)
  }
}
