import * as THREE from 'three'
import React, { useMemo, useRef } from 'react'
import useStore, { getState } from '../../store'
import { a } from 'react-spring/three'
import { BOARD_DEPTH } from '../../config'
import { useHelper } from 'drei'
import { BoxHelper } from 'three'

/**
 * @param {String} type
 * @param {Number} width
 * @param {Number} height
 * @param {Number} depth
 * @param {String} dtdType
 * @returns {JSX.Element}
 * @constructor
 */
const Board = ({ type, width, height, depth, dtdType = 'main', showBoxHelper = false }) => {
  const mesh = useRef()
  const bumpScaleValue = useStore((state) => state.bumpScale)
  const mainDTDId = useStore((state) => state.mainDTDId)
  const secondDTDId = useStore((state) => state.secondDTDId)
  const selectedId = dtdType === 'main' ? mainDTDId : secondDTDId

  const woodMaterial = useMemo(() => {
    const woodMaterial = new THREE.MeshPhongMaterial({
      emissiveIntensity: 0.5,
      side: THREE.DoubleSide
    })

    if (selectedId) {
      const DTD = getState().getDTD(dtdType)

      woodMaterial.map = DTD['map']
      woodMaterial.bumpScale = bumpScaleValue
    } else {
      woodMaterial.emissive.set(0x3a3a3a)
    }

    return woodMaterial
  }, [dtdType, bumpScaleValue, selectedId])

  const posY = useMemo(() => {
    let posY = height / 2

    if ('horizontal' === type) {
      posY = depth / 2
    } else if ('front' === type) {
      posY = 0 - BOARD_DEPTH
    }

    return posY
  }, [type, height, depth])

  const posX = useMemo(() => {
    let posX = depth / 2

    if ('horizontal' === type) {
      posX = height / 2
    }

    return posX
  }, [type, height, depth])

  const rotation = useMemo(() => {
    if ('horizontal' === type) {
      return [0, 0, Math.PI / 2]
    } else if ('front' === type) {
      return [Math.PI, Math.PI, Math.PI / 2]
    } else {
      return [0, Math.PI / 2, 0]
    }
  }, [type])

  const woodObject = useMemo(() => {
    let boxWidth = width
    let boxHeight = height
    let boxDepth = depth

    if ('horizontal' === type) {
      boxWidth = depth
      boxDepth = width
    }

    return new THREE.Mesh(new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth), woodMaterial)
  }, [woodMaterial, type, width, height, depth])

  useHelper(mesh, BoxHelper, 'red')

  if (showBoxHelper) {
    return (
      <a.mesh position-y={posY} position-x={posX} rotation={rotation} ref={mesh}>
        <primitive object={woodObject} />
      </a.mesh>
    )
  } else {
    return (
      <a.mesh position-y={posY} position-x={posX} rotation={rotation}>
        <primitive object={woodObject} />
      </a.mesh>
    )
  }
}

export default Board
