import React, { useCallback, useState } from 'react'
import useStore, { getState } from '../../../store'
import { connect } from 'react-redux'
import { Lang } from '../../../Utils'
import { PROFILES } from '../../../config'
import Typography from '@material-ui/core/Typography'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import GridList from '@material-ui/core/GridList'
import { makeStyles } from '@material-ui/core/styles'
import GridListTile from '@material-ui/core/GridListTile'
import { Badge, Card } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import SiteAppBar from './SiteAppBar'
import ErrorDialog from '../Components/Molecules/ErrorDialog'
import { isMobile } from 'react-device-detect'
import { setStep } from '../../../utils/steps'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = () => ({})

const useCardStyles = makeStyles({
  root: {
    maxWidth: 200,
    margin: 10
  },
  media: {
    width: 200
  },
  badge: {
    top: '85%'
  }
})

function ProfileImgCard({ profile, section }) {
  const selectedId = getState().selectedProfileId
  const setDoorsProfile = getState().setDoorsProfile
  const classes = useCardStyles()

  return (
    <GridListTile key={'GridListTile' + profile.id}>
      <Badge
        invisible={selectedId !== profile.id}
        color='primary'
        overlap='circle'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<CheckIcon fontSize='small' />}
        classes={{ badge: classes.badge }}
      >
        <Card className={classes.root} key={profile.id} onPointerDown={() => setDoorsProfile(section, profile.type, profile.id)}>
          <CardActionArea>
            <CardMedia
              component='img'
              className={classes.media}
              height='140'
              image={process.env.PUBLIC_URL + '/images/products/' + profile.image}
            />
            <CardContent>
              <Typography gutterBottom>{Lang(profile.type, 'profiles')}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Badge>
    </GridListTile>
  )
}

export const SMenu = (profiles, profileSection) =>
  profiles.map((profile, index) => {
    return <ProfileImgCard profile={profile} key={index} section={profileSection} />
  })

const useListStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    margin: 0
  },
  gridList: {
    overflow: 'auto'
  }
}))

/**
 * @param menu
 * @param {string} key
 * @returns {JSX.Element}
 * @constructor
 */
function SelectProfileTab({ menu, key }) {
  const classes = useListStyles()

  return (
    <div className={classes.root} key={key}>
      <GridList className={classes.gridList} cols={2.5}>
        {menu}
      </GridList>
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-profile-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderWidth: 2,
    padding: '0'
  },
  content: {
    padding: 10
  },
  tabs: {
    backgroundColor: theme.palette.background.paper
  },
  tabsBackground: {
    backgroundColor: theme.palette.grey[100]
  },
  tabPanelContent: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

function SimpleTabs({ tabsContent, onClickSteps }) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [errorTexts, setErrorText] = useState([])
  const selectedProfileId = useStore((state) => state.selectedProfileId)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const onClickStepsModified = onClickSteps

  onClickStepsModified.next = useCallback(
    (history) => {
      const errorTexts = []

      if (!selectedProfileId) {
        errorTexts.push(Lang('PleaseSelectProfile', 'profiles'))
      }

      setErrorText(errorTexts)

      if (Object.keys(errorTexts).length) {
        setOpenErrorDialog(true)
      } else {
        setStep(onClickSteps.nextStep)
        history.push('/' + Lang(onClickSteps.nextStep, 'navigation'))
      }
    },
    [setErrorText, onClickSteps, selectedProfileId, setOpenErrorDialog]
  )

  return (
    <>
      <SiteAppBar title={Lang('SelectProfilesTitle')} onClickSteps={onClickStepsModified}>
        <Tabs textColor='primary' className={classes.tabs} value={value} onChange={handleChange}>
          {tabsContent.map((tab, index) => (
            <Tab
              key={index}
              label={tab.menuItem}
              {...a11yProps(index)}
              classes={{
                root: classes.tab, // class name, e.g. `root-x`
                selected: classes.tabSelected // class name, e.g. `disabled-x`
              }}
            />
          ))}
        </Tabs>
      </SiteAppBar>
      <div className={classes.content}>
        {tabsContent.map((tab, index) => (
          <TabPanel value={value} index={index} key={index} className={classes.tabPanelContent}>
            {tab.render}
          </TabPanel>
        ))}
      </div>
      <ErrorDialog openDialogErrors={errorTexts} openErrorDialog={openErrorDialog} setOpenErrorDialog={setOpenErrorDialog} />
    </>
  )
}

class SelectProfiles extends React.Component {
  render() {
    const self = this
    const tabsContent = []

    Object.keys(PROFILES).forEach(function (profileSection, index) {
      const profilesProducts = PROFILES[profileSection]
      const products = []

      Object.keys(profilesProducts).forEach(function (productId) {
        const product = {
          id: productId,
          type: profilesProducts[productId],
          image: self.props.productImages?.small[productId]
        }

        products.push(product)
      })

      if (products.length > 0) {
        const menu = SMenu(products, profileSection)

        let langKey = profileSection

        if (isMobile) langKey += 'Mobile'

        tabsContent.push({
          menuItem: Lang(langKey, 'profiles'),
          render: <SelectProfileTab key={'SelectProfileTab' + index} menu={menu} productImages={self.props.productImages?.large} />
        })
      }
    })

    return (
      <SimpleTabs
        tabsContent={tabsContent}
        contextRef={this.props.contextRef}
        objType={this.props.objType}
        mainSize={this.props.mainSize}
        onClickSteps={this.props.onClickSteps}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectProfiles)
