import React from 'react'
import { SwatchesPicker } from 'react-color'
import { Box, Button, Grid, Paper, Popper } from '@material-ui/core'
import { Lang } from '../../../Utils'

class SelectColor extends React.Component {
  state = {
    displayColorPicker: false,
    colorHex: '#cfd8dc',
    color: {
      r: '207',
      g: '216',
      b: '220',
      a: '1'
    }
  }

  handleClick = (event) => {
    this.setState({ displayColorPicker: this.state.displayColorPicker ? null : event.currentTarget })
  }

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  }

  handleChange = (color) => {
    this.setState({ color: color.rgb, colorHex: color.hex })
    this.props.setWallColor(color.hex)
  }

  render() {
    return (
      <div>
        <Button onClick={this.handleClick} className={this.props.classes.button}>
          {Lang('WallColorBtnLabel')}:
          <div className={this.props.classes.colorDiv} style={{ backgroundColor: this.state.colorHex ?? this.props.wallColor }}></div>
        </Button>
        <Popper open={Boolean(this.state.displayColorPicker)} anchorEl={this.state.displayColorPicker} transition>
          <Paper>
            <SwatchesPicker color={this.state.color} onChange={this.handleChange} />
          </Paper>
          <Box bgcolor='primary.main'>
            <Grid container>
              <Grid item xs={6} className={this.props.classes.mainGridCenter} />
              <Grid item xs className={this.props.classes.mainGridRight}>
                <Button variant='contained' color='primary' disableElevation onClick={this.handleClose}>
                  {Lang('Close')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Popper>
      </div>
    )
  }
}

/*

 */
//color={`rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`}
export default SelectColor
