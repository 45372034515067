export const INPUTS_LANG = {
  Width: 'Breite',
  Height: 'Höhe',
  Depth: 'Tiefe',
  MinSpaceWidthError: 'Die Mindestbreite des Raums beträgt {0}cm',
  MaxSpaceWidthError: 'Die maximale Breite des Raums beträgt {0}cm',
  WrongWidthNumberValue: 'Unzulässige Breite',
  MinWardrobeWidthError: 'Breite muss gleich oder größer als {0}cm sein',
  MaxWardrobeWidthError: 'Die maximale Breite darf nicht größer als {0}cm sein',
  MinWardrobeDepthError: 'Die Gesamttiefe des Schrankes darf nicht weniger als {0}cm betragen',
  MaxWardrobeDepthError: 'Die Gesamttiefe des Schranks darf nicht größer als {0}cm sein',
  MinWardrobeHeightError: 'Die Höhe darf nicht weniger als {0}cm betragen',
  MaxWardrobeHeightError: 'Die Höhe darf nicht größer als {0}cm sein',
  WidthIsEmpty: 'Sie müssen die Breite angeben',
  DepthIsEmpty: 'Sie müssen die Gesamttiefe des Schrankes angeben',
  LeftFrontHeightIsEmpty: 'Sie müssen die linke vordere Deckenhöhe angeben',
  RightFrontHeightIsEmpty: 'Sie müssen die rechte vordere Deckenhöhe angeben',
  LeftBackHeightIsEmpty: 'Sie müssen die linke hintere Deckenhöhe angeben',
  RightBackHeightIsEmpty: 'Sie müssen die rechte hintere Deckenhöhe angeben',
  InputCanNotBeEmpty: '{0} darf nicht leer sein',
  MinWardrobeError: '{0} darf nicht kleiner als {1}cm sein',
  MaxWardrobeError: '{0} darf nicht größer als {1}cm sein',
  drawers: 'Aufpreis für Schublade mit Auszug {0} {1}',
  d_stetiny: 'Anschlagbürsten ({0}{1})',
  p_stetiny: 'Anti-Staub-Bürsten ({0}{1})',
  pozicioner: 'Stellungsregler',
  BadEmailFormatError: 'Falsches E-Mail-Adressformat',
  ALERT20: 'Der Vorname darf nicht leer sein.',
  ALERT21: 'Der Nachname darf nicht leer sein.',
  ALERT22: 'Sie müssen eine Telefonnummer eingeben.',
  ALERT23: 'Sie müssen eine E-Mail-Adresse eingeben.',
  ALERT25: 'Sie müssen die vollständige Rechnungsadresse eingeben.',
  ALERT26: 'Sie müssen den Bedingungen zustimmen.',
  OrgDataCanNotBeEmpty: 'Sie müssen Ihre Unternehmensdaten eingeben'
}
