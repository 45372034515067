import * as THREE from 'three'
import React from 'react'
import { BOARD_DEPTH } from '../../../config'
import getMaterial from '../../Profile/material'

/**
 * @param doorAttributes
 * @param roomAttributes
 * @returns {*}
 * @constructor
 */
const WindowTop = ({ doorAttributes, wardrobeAttributes }) => {
  const objectMaterial = getMaterial(doorAttributes['profile'])

  const height = wardrobeAttributes['height']
  const width = wardrobeAttributes['width'] - BOARD_DEPTH * 2

  const posY = height
  const posX = BOARD_DEPTH + width / 2
  const posZ = 1
  const rotation = [Math.PI / 2, 0, 0]

  const topObject = new THREE.Mesh(new THREE.PlaneGeometry(width, 7.5), objectMaterial)

  const bottomObject = new THREE.Mesh(new THREE.BoxGeometry(width, 3.7, 3.2), objectMaterial)

  return (
    <group position-y={posY} position-x={posX} position-z={posZ}>
      <mesh rotation={rotation}>
        <primitive object={topObject} />
      </mesh>
      <mesh position-y={0 - 7.5 / 4} position-x={0} position-z={-2.6}>
        <primitive object={bottomObject} />
      </mesh>
    </group>
  )
}

export default WindowTop
