import React from 'react'
import { BOARD_DEPTH } from '../../config'
import Board from './Board'

/**
 * @param {Object} wardrobeAttributes
 * @param {Number} spaceHeight
 * @param {Number} roomHeight
 * @param {Number} boardWidth
 * @param {Number} posX
 * @param {String} type
 * @param {String} color
 * @param {Boolean} showBoxHelper
 * @returns {JSX.Element}
 * @constructor
 */
const WoodStand = ({ wardrobeAttributes, spaceHeight, roomHeight, boardWidth, posX, type, color = 'silver', showBoxHelper = false }) => {
  let boardHeight = 0
  let posZ = 0
  let boardDepth = BOARD_DEPTH

  if (color === 'green') {
    boardDepth = 0.5
  }

  if (type === 'in') {
    boardHeight = spaceHeight
    boardWidth -= wardrobeAttributes.freeSpace
    posZ -= wardrobeAttributes.freeSpace / 2
  } else {
    boardHeight = roomHeight - BOARD_DEPTH
  }

  return (
    <group position-y={0} position-x={posX} position-z={posZ}>
      <Board showBoxHelper={showBoxHelper} type='vertical' width={boardWidth} height={boardHeight} depth={boardDepth} />
    </group>
  )
}

export default WoodStand
