import * as THREE from 'three'
import React, { useMemo } from 'react'

/**
 * @param {String} wallColor
 * @param {Number} wallWidth
 * @param {Number} wallHeight
 * @returns {JSX.Element}
 * @constructor
 */
const BackWall = ({ wallColor, wallWidth, wallHeight }) => {
  const repeatNum = useMemo(() => 10 + wallWidth / 50, [wallWidth])

  const repeatTexture = (texture, repeatNum) => {
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(repeatNum, 20)

    return texture
  }

  const wallImgDiffuseTex = useMemo(
    () => new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Wall/Rough_Painted_Wall_001_OCC_Small.jpg'),
    []
  )
  const bumpMapTex = useMemo(
    () => new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Wall/Rough_Painted_Wall_001_DISP_Small.jpg'),
    []
  )
  const lightMapTex = useMemo(
    () => new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Wall/Rough_Painted_Wall_001_ROUGH_Small.jpg'),
    []
  )

  const wallImgDiffuse = useMemo(() => repeatTexture(wallImgDiffuseTex, repeatNum), [wallImgDiffuseTex, repeatNum])
  const bumpMap = useMemo(() => repeatTexture(bumpMapTex, repeatNum), [bumpMapTex, repeatNum])
  const lightMap = useMemo(() => repeatTexture(lightMapTex, repeatNum), [lightMapTex, repeatNum])

  const wallMaterial = useMemo(() => {
    const wallMaterial = new THREE.MeshPhongMaterial({
      color: wallColor,
      map: wallImgDiffuse,
      bumpMap: bumpMap,
      bumpScale: 0.3,
      lightMap: lightMap,
      lightMapIntensity: 0.2
    })

    return wallMaterial
  }, [wallColor, wallImgDiffuse, bumpMap, lightMap])

  const wallObject = new THREE.Mesh(new THREE.PlaneGeometry(wallWidth, wallHeight), wallMaterial)

  return <primitive object={wallObject} />

  /*
    return (
        <primitive object={wallObject}/>
        <mesh>

            <planeGeometry attach="geometry" args={[wallWidth, wallHeight]}/>
            <meshPhongMaterial
                color={wallColor}
                attach="material"
                map={wallImgDiffuse}
                bumpMap={bumpMap}
                bumpScale={0.3}
                lightMap={lightMap}
                lightMapIntensity={0.2}
            />
        </mesh>
    );*/
}

/**
 * @param {Object} roomAttributes
 * @param {Object} wardrobeAttributes
 * @param {Boolean} useLeftBackWall
 * @param {Boolean} useRightBackWall
 * @returns {JSX.Element}
 * @constructor
 */
const BackWalls = ({ roomAttributes, wardrobeAttributes }) => {
  const roomType = roomAttributes.type
  const roomWidth = roomAttributes.width
  const roomHeight = roomAttributes.height
  const wardrobeDepth = wardrobeAttributes.depth
  const wardrobeWidth = wardrobeAttributes.width
  const wallColor = roomAttributes.wallColor ? roomAttributes.wallColor : null

  const wallWidth = useMemo(() => (roomWidth - wardrobeWidth) / 2, [roomWidth, wardrobeWidth])

  const positionX = useMemo(() => {
    return roomType === 'c' || roomType === 'd' || roomType === 'a' || roomAttributes.backWallType === 'b' ? -100 : 0
  }, [roomType, roomAttributes.backWallType])

  let positionZ = useMemo(() => 0 - wardrobeDepth / 2, [wardrobeDepth])

  const rotationRight = useMemo(() => {
    return [0, Math.PI / 2 + Math.PI, 0]
  }, [])

  const rotationLeft = useMemo(() => {
    return [0, 0 - Math.PI / 2 + Math.PI, 0]
  }, [])

  const showLeftBackFrontWall = roomAttributes.backWallType === 'a' || (roomAttributes.backWallType === 'b' && roomType !== 'c')
  const showRightBackFrontWall = roomAttributes.backWallType === 'a' || (roomAttributes.backWallType === 'b' && roomType !== 'b')

  return (
    <group position={[positionX, 0, positionZ]}>
      <BackWall wallColor={wallColor} wallWidth={wardrobeWidth} wallHeight={roomHeight} />
      <group position-z={wardrobeDepth}>
        <group position-x={0 - wallWidth / 2 - wardrobeWidth / 2} position-z={showLeftBackFrontWall ? 0 : -wardrobeDepth}>
          <BackWall wallColor={wallColor} wallWidth={wallWidth} wallHeight={roomHeight} />
        </group>
        <group position-x={wallWidth / 2 + wardrobeWidth / 2} position-z={showRightBackFrontWall ? 0 : -wardrobeDepth}>
          <BackWall wallColor={wallColor} wallWidth={wallWidth} wallHeight={roomHeight} />
        </group>
      </group>
      {showLeftBackFrontWall && (
        <group position-x={-(wardrobeWidth / 2)} position-z={wardrobeDepth / 2}>
          <group rotation={rotationLeft}>
            <BackWall wallColor={wallColor} wallWidth={wardrobeDepth} wallHeight={roomHeight} />
          </group>
        </group>
      )}
      {showRightBackFrontWall && (
        <group position-x={wardrobeWidth / 2} position-z={wardrobeDepth / 2}>
          <group rotation={rotationRight}>
            <BackWall wallColor={wallColor} wallWidth={wardrobeDepth} wallHeight={roomHeight} />
          </group>
        </group>
      )}
    </group>
  )
}

export default React.memo(BackWalls)
