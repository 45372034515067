import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Lang } from '../../Utils'
import SelectCorpusesSetSpacesCount from './Steps/SelectCorpusesSetSpacesCount'
import Order from './Steps/Order'
import SetWardrobeParameters from './Steps/SetWardrobeParameters'
import RoomType from './Steps/RoomType'
import SelectDoorsFill from './Steps/SelectDoorsFill'
import SelectProfiles from './Steps/SelectProfiles'
import SelectDoorSpacesCount from './Steps/SelectDoorSpacesCount'
import SelectDTDStep from './Steps/SelectDTD'
import SelectCorpuses from './Steps/SelectCorpuses'
import SelectDTDIn from './Steps/SelectDTDIn'
import SelectDTDOut from './Steps/SelectDTDOut'
import LoadStep from './Steps/LoadStep'
import Accessories from './Steps/Accessories'
import PriceCalculation from './Steps/PriceCalculation'
import { isSelectTypeAvailable } from '../../utils/room'
import useStore from '../../store'

export default function Steps({ onClickSteps, fullScreenHandle, hideMiddleMenu = false }) {
  const _isSelectTypeAvailable = isSelectTypeAvailable()

  const height = useStore((state) => state.height)
  const width = useStore((state) => state.width)

  let isOrderStart = height && width

  return (
    <div style={{ paddingBottom: 50 }}>
      <Switch>
        <Route exact path={'/'}>
          {_isSelectTypeAvailable ? (
            <RoomType fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />
          ) : (
            <SetWardrobeParameters fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />
          )}
        </Route>
        <Route path='/load/:objId/:APIUUId'>
          <LoadStep fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />
        </Route>
        <Route path='/dev'>
          <RoomType fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} isDevSet={true} />
        </Route>
        <Route path={'/' + Lang('selectCorpusesSetSpacesCount', 'navigation')}>
          {!isOrderStart ? (
            <Redirect to='/' />
          ) : (
            <SelectCorpusesSetSpacesCount fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />
          )}
        </Route>
        <Route path={'/' + Lang('selectCorpuses', 'navigation')}>
          {!isOrderStart ? (
            <Redirect to='/' />
          ) : (
            <SelectCorpuses fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} hideMiddleMenu={hideMiddleMenu} />
          )}
        </Route>
        <Route path={'/' + Lang('selectDTD', 'navigation')}>
          {!isOrderStart ? <Redirect to='/' /> : <SelectDTDStep fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />}
        </Route>
        <Route path={'/' + Lang('selectDTDIn', 'navigation')}>
          {!isOrderStart ? <Redirect to='/' /> : <SelectDTDIn fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />}
        </Route>
        <Route path={'/' + Lang('selectDTDOut', 'navigation')}>
          {!isOrderStart ? <Redirect to='/' /> : <SelectDTDOut fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />}
        </Route>
        <Route path={'/' + Lang('selectDoorSpacesCount', 'navigation')}>
          {!isOrderStart ? (
            <Redirect to='/' />
          ) : (
            <SelectDoorSpacesCount fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} hideMiddleMenu={hideMiddleMenu} />
          )}
        </Route>
        <Route path={'/' + Lang('selectProfiles', 'navigation')}>
          {!isOrderStart ? <Redirect to='/' /> : <SelectProfiles fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />}
        </Route>
        <Route path={'/' + Lang('selectDoorsFill', 'navigation')}>
          {!isOrderStart ? (
            <Redirect to='/' />
          ) : (
            <SelectDoorsFill fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} hideMiddleMenu={hideMiddleMenu} />
          )}
        </Route>
        <Route path={'/' + Lang('selectDoorsFill', 'navigation') + '/:doorFillId'}>
          {!isOrderStart ? (
            <Redirect to='/' />
          ) : (
            <SelectDoorsFill fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} hideMiddleMenu={hideMiddleMenu} />
          )}
        </Route>
        <Route path={'/' + Lang('roomType', 'navigation')}>
          {!isOrderStart ? <Redirect to='/' /> : <RoomType fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />}
        </Route>
        <Route path={'/' + Lang('setWardrobeParameters', 'navigation')}>
          {!isOrderStart ? <Redirect to='/' /> : <SetWardrobeParameters fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />}
        </Route>
        <Route path={'/' + Lang('accessories', 'navigation')}>
          {!isOrderStart ? <Redirect to='/' /> : <Accessories fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />}
        </Route>
        <Route path={'/' + Lang('priceCalculation', 'navigation')}>
          {!isOrderStart ? <Redirect to='/' /> : <PriceCalculation fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />}
        </Route>
        <Route path={'/' + Lang('order', 'navigation')}>
          {!isOrderStart ? <Redirect to='/' /> : <Order fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />}
        </Route>
      </Switch>
    </div>
  )
}
