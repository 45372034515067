import React from 'react'
import Altitude from './Altitude'
import { BOARD_DEPTH } from '../config'

/**
 * @param children
 * @param {String} type
 * @param {Number} value
 * @param {Boolean} slowScale
 * @param {Boolean} showInput
 * @param {Null|Number} spaceHeight
 * @param {Null|Number} roomHeight
 * @returns {JSX.Element}
 * @constructor
 */
const ExtAltitude = ({ children, type, value, slowScale = false, showInput = false }) => {
  let textRotation = [0, 0, 0]
  let mainPositionX = 0
  let linePositionX = [0, 0]
  let linePositionY = [0, 0]
  let linePositionZ = [0, 0]
  let rotation = [0, 0, 0]
  let lineRotation = [0, 0, 0]
  let lineArgs = [0, 0]
  let altitudePositionY = 1
  let altitudePositionX = 0
  let altitudePositionZ = 0
  let textPositionX = 0
  let textPositionY = 0
  let editType = null
  let minus = 2

  switch (type) {
    case 'left':
      mainPositionX = -10
      linePositionX = [3, 3]
      break
    case 'right':
      mainPositionX = 10 + BOARD_DEPTH
      linePositionX = [-3, -3]
      break
    case 'bottom':
      rotation = [0, 0, -Math.PI / 2]
      textRotation = [0, 0, Math.PI / 2]
      linePositionX = [-0.5, value - 1.5]
      break
    case 'space_top':
    case 'corpuses_space_top':
      rotation = [0, 0, -Math.PI / 2]
      textRotation = [0, 0, Math.PI / 2]
      linePositionX = [-0.5, value]
      minus = 0
      break
    case 'right_bottom':
      rotation = [-Math.PI / 2, Math.PI / 2, 0]
      //textRotation = [Math.PI / 2, 0, Math.PI / 2];
      linePositionX = [-0.5, 0]
      lineRotation = [0, Math.PI / 2, 0]
      altitudePositionZ = -1
      break
    default:
      break
  }

  const lineHeight = 14
  const posY = lineHeight / 2 + BOARD_DEPTH

  switch (type) {
    case 'right':
    case 'left':
      lineArgs = [14, 1]
      linePositionY = [0.5, value - 0.5]
      break
    case 'top':
    case 'bottom':
      mainPositionX = 1
      lineArgs = [1, 14]
      linePositionY = [-7, -7]
      break
    case 'space_top':
      mainPositionX = 0
      lineArgs = [1, lineHeight]
      linePositionY = [posY, posY]
      break
    case 'corpuses_space_top':
      mainPositionX = 0
      lineArgs = [1, 16]
      linePositionY = [8, 8]
      break
    case 'right_bottom':
      lineArgs = [1, 14]
      linePositionY = [-7, -7]
      linePositionZ = [-0.5, 0 - value + 0.5]
      break
    default:
      break
  }

  switch (type) {
    case 'right':
    case 'left':
      editType = 'height'
      break
    case 'top':
    case 'bottom':
    case 'space_top':
    case 'corpuses_space_top':
      editType = 'width'
      break
    case 'right_bottom':
      editType = 'depth'
      break
    default:
      break
  }

  switch (type) {
    case 'top':
      altitudePositionY = 10
      altitudePositionX = 200
      break
    case 'bottom':
      altitudePositionY = -10
      textPositionX = 6
      break
    case 'right_bottom':
      altitudePositionY = -10
      textPositionX = 18
      break
    case 'space_top':
      altitudePositionY = 10
      textPositionY = -4
      textPositionX = -9
      break
    case 'corpuses_space_top':
      altitudePositionY = 10
      textPositionX = -9
      textPositionY = -4
      break
    case 'left':
      textPositionX = value.toString().length < 4 ? -48 : -52
      break
    default:
      break
  }

  return (
    <group position-x={mainPositionX}>
      <mesh position-y={linePositionY[0]} position-x={linePositionX[0]} position-z={linePositionZ[0]} rotation={lineRotation}>
        <planeGeometry attach='geometry' args={lineArgs} />
        <meshStandardMaterial attach='material' color='#000000' />
      </mesh>
      <mesh position-y={linePositionY[1]} position-x={linePositionX[1]} position-z={linePositionZ[1]} rotation={lineRotation}>
        <planeGeometry attach='geometry' args={lineArgs} />
        <meshStandardMaterial attach='material' color='#000000' />
      </mesh>
      <group position-y={altitudePositionY} position-x={altitudePositionX} position-z={altitudePositionZ} rotation={rotation}>
        <Altitude
          value={value}
          minus={minus}
          textRotation={textRotation}
          textPositionX={textPositionX}
          textPositionY={textPositionY}
          editable={true}
          type={type}
          editType={editType}
          showInput={showInput}
          slowScale={slowScale}
        >
          {children}
        </Altitude>
      </group>
    </group>
  )
}

export default ExtAltitude
