import { getOrder } from '../Stores/Order'
import { getState } from '../store'
import { getType as getRoomType } from './room'
import { getParametersDoorSpaceHeights, getWardrobeAttributes } from './parameters'
import { getDoorFillHeightId } from './door'
import { MIN_DOOR_FILL_HEIGHT } from '../config'
import { getSteps } from '../Stores/Steps'
import { getSpaceWidth } from '../Space'

export function getSendData() {
  const insert = getOrder().insert
  const actualStep = getSteps().actualStep
  const objId = getOrder().objId
  const webUUId = getState().getUUId()
  const uuid = getOrder().uuid
  const width = getState().width
  const height = getState().height
  const depth = getState().depth
  const priceVersion = getState().priceVersion
  const selectedRoomType = getState().roomType
  const selectedObjType = getState().objType
  const spacesCount = getState().spacesCount
  const mainDTDId = getState().mainDTDId
  const secondDTDId = getState().secondDTDId
  const doorFillsIds = getState().doorFillsIds
  const selectedCorpuses = getState().selectedCorpuses
  let dividedSpaces = JSON.parse(getState().dividedSpaces)
  let dividedSpacesWidth = JSON.parse(getState().dividedSpacesWidth)
  const additionalOptionData = JSON.parse(getState().additionalOption)
  const selectedDoorsSpace = getState().selectedDoorsSpace
  const selectedDoorsSpaceAttributes = getState().selectedDoorsSpaceAttributes
  const selectedProfile = getState().selectedProfile
  const selectedProfileId = getState().selectedProfileId
  const doorFillsAttributes = JSON.parse(getState().doorFillsAttributes)
  const doorFills = getState().doorFills
  const doorFillsData = getState().doorFillsData
  const phoneNumber = getState().phoneNumber
  const emailAddress = getState().emailAddress
  const parameters = getState().parameters
  const isDTDOutDisabled = getState().isDTDOutDisabled

  const roomType = getRoomType(selectedRoomType, selectedObjType)

  const basicSpaceWidth = getSpaceWidth(selectedObjType, width, spacesCount, roomType)

  if (basicSpaceWidth > 100) {
    dividedSpaces = {}

    for (let spaceSeq = 1; spaceSeq <= spacesCount; spaceSeq++) {
      dividedSpaces[spaceSeq] = true
    }

    dividedSpacesWidth = {}
  }

  const wardrobeAttributes = getWardrobeAttributes({
    width: width,
    height: height,
    depth: depth,
    type: roomType,
    spacesCount: spacesCount
  })

  const doorSpacesHeights = {}

  for (let doorId = 0; doorId < wardrobeAttributes.spacesCount; doorId++) {
    const doorSpacesHeight = {}
    const doorFillsCount = selectedDoorsSpace[doorId + 1]

    for (let spaceId = 0; spaceId < wardrobeAttributes.spacesCount; spaceId++) {
      if (
        doorFillsAttributes[getDoorFillHeightId(doorId, doorFillsCount, spaceId)] &&
        0 < doorFillsAttributes[getDoorFillHeightId(doorId, doorFillsCount, spaceId)]
      ) {
        const spaceHeight = doorFillsAttributes[getDoorFillHeightId(doorId, doorFillsCount, spaceId)]

        if (spaceHeight && spaceHeight >= MIN_DOOR_FILL_HEIGHT) {
          doorSpacesHeight[spaceId] = spaceHeight
        }
      }
    }

    doorSpacesHeights[doorId] = getParametersDoorSpaceHeights(wardrobeAttributes.height, wardrobeAttributes.spacesCount, doorSpacesHeight)
  }

  const sendData = {
    step: actualStep,
    insert: insert,
    objId: objId,
    webUUId: webUUId,
    uuid: uuid,
    emailAddress: emailAddress,
    phoneNumber: phoneNumber,
    width: wardrobeAttributes.width,
    height: wardrobeAttributes.height,
    depth: wardrobeAttributes.depth,
    roomType: wardrobeAttributes.type,
    objType: selectedObjType,
    spacesCount: wardrobeAttributes.spacesCount,
    isSpacesCountSet: spacesCount && spacesCount > 0,
    doorSpacesHeights: doorSpacesHeights,
    doorFillsAttributes: doorFillsAttributes,
    isDTDOutDisabled: isDTDOutDisabled,
    doorFillsData,
    priceVersion,
    doorFillsIds,
    mainDTDId,
    secondDTDId,
    selectedCorpuses: selectedObjType !== 'd' ? selectedCorpuses : {},
    dividedSpaces,
    dividedSpacesWidth,
    doorsSpace: selectedDoorsSpace,
    selectedDoorsSpaceAttributes: selectedDoorsSpaceAttributes,
    selectedProfile,
    selectedProfileId,
    additionalOptionData,
    parameters
  }

  if (doorFills) {
    const doorFillsProduct = {}

    Object.keys(doorFills).map((doorFillId) => {
      doorFillsProduct[doorFillId] = doorFills[doorFillId].productId
    })

    sendData['doorFills'] = doorFillsProduct
  }

  if (doorFillsAttributes) {
    sendData['doorFillsAttributes'] = doorFillsAttributes
  }

  return sendData
}
