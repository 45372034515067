import React, { useCallback, useMemo, useState } from 'react'
import { Lang } from '../../../../Utils'
import { Paper, Container, Grid, Typography, Divider, Button } from '@material-ui/core'
import TopMenu from '../../Menu/TopMenu'
import VerticalSidebar from '../../Components/Molecules/VerticalSidebar'
import { makeStyles } from '@material-ui/core/styles'
import MiddleMenu from '../../Menu/MiddleMenu'
import SiteAppBar from '../../Menu/SiteAppBar'
import JSONTree from 'react-json-tree'
import useStore, { getState } from '../../../../store'
import { getWardrobeAttributes } from '../../../../utils/parameters'
import { getType as getRoomType } from '../../../../utils/room'
import { getFormattedPrice } from '../../../../utils/price'
import { Redirect, useHistory } from 'react-router-dom'
import { getSteps } from '../../../../Stores/Steps'
import { START_STEP } from '../../../../config'
import disableBrowserBackButton from 'disable-browser-back-navigation'
import { getOrder } from '../../../../Stores/Order'
import SubmitOrder from '../../Modals/SubmitOrder'
import { currencyFormat, numberFormat } from '../../../../utils/format'
import { setStep } from '../../../../utils/steps'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 20,
    paddingBottom: 20
  },
  mainGridRight: {
    textAlign: 'right'
  },
  mainGridCenter: {
    textAlign: 'center'
  },
  divider: {
    height: 3,
    margin: 4,
    backgroundColor: 'black'
  },
  infoText: {
    paddingLeft: 30
  },
  priceText: {
    paddingRight: 30
  },
  nextButtonActive: {
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  },
  buttonsContainer: {
    paddingTop: 10,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30
  },
  accessoriesContainer: {
    paddingLeft: 40
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}))

/**
 * @param fullScreenHandle
 * @param onClickSteps
 * @param price
 * @returns {JSX.Element}
 * @constructor
 */
const PriceCalculation = ({ fullScreenHandle, onClickSteps, price, onSubmitOrder, savedOrder, showSummaryItemPrice }) => {
  const classes = useStyles()
  const history = useHistory()
  const width = useStore((state) => state.width)
  const height = useStore((state) => state.height)
  const depth = useStore((state) => state.depth)
  const spacesCount = useStore((state) => state.spacesCount)
  const selectedRoomType = useStore((state) => state.roomType)
  const selectedObjType = useStore((state) => state.objType)
  const emailAddress = useStore((state) => state.emailAddress)
  const openModal = getSteps().openModal
  const webUUId = getOrder().getUUId()
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false)
  const setDefaultCameraPos = getState().setDefaultCameraPos

  const isPhoneSaveAvailable = process.env.REACT_APP_IS_SAVE_ORDER_AVAILABLE === 'true'

  const roomType = useMemo(() => {
    return getRoomType(selectedRoomType, selectedObjType)
  }, [selectedRoomType, selectedObjType])

  const wardrobeAttributes = getWardrobeAttributes({
    width: width,
    height: height,
    depth: depth,
    type: roomType,
    spacesCount: spacesCount
  })

  const onClickSelectStep = useCallback(() => {
    setStep('order')
    history.push('/' + Lang('order', 'navigation'))
  }, [history])

  const handleOpenPhoneSave = useCallback(() => {
    openModal('phoneSave')
  }, [openModal])

  const onClickSubmitOrder = useCallback(
    (e) => {
      e.stopPropagation()

      disableBrowserBackButton()
      setOpenSubmitDialog(true)
      setDefaultCameraPos(true)

      setTimeout(function () {
        const canvas = document.getElementsByTagName('canvas')[0]
        const image = canvas.toDataURL('image/png')
        onSubmitOrder({
          webUUId: webUUId,
          image: image.replace(/^data:image\/[^;]/, 'data:application/octet-stream')
        })
        setDefaultCameraPos(false)
      }, 1000)
    },
    [onSubmitOrder, setOpenSubmitDialog, disableBrowserBackButton]
  )

  const parametersInfo = useMemo(() => {
    let parametersInfo = numberFormat(wardrobeAttributes.width) + 'x' + numberFormat(wardrobeAttributes.height)

    if (selectedObjType !== 'd') {
      parametersInfo += 'x' + numberFormat(wardrobeAttributes.depth)
    }

    return parametersInfo
  }, [selectedObjType, wardrobeAttributes.width, wardrobeAttributes.height, wardrobeAttributes.depth])

  if (selectedObjType === '') {
    return <Redirect to={'/' + Lang(START_STEP, 'navigation')} />
  }

  if (price && price.prices) {
    let skippSubmitOrder = false

    if (price.prices.skippSubmitOrder || price.skippSubmitOrder) {
      skippSubmitOrder = true
    }

    return (
      <>
        <TopMenu mainStep='order' />
        <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />
        <Paper>
          <SiteAppBar title={Lang('PriceCalculation')} onClickSteps={onClickSteps} />
          <Container className={classes.mainContainer}>
            <Grid container spacing={1}>
              <Grid item xs={8} spacing={5}>
                <Typography variant='h6' className={classes.infoText}>
                  {Lang('CalculationItemName' + selectedObjType.toUpperCase())} ({parametersInfo})
                </Typography>
              </Grid>
              <Grid item xs={4} spacing={5} className={classes.mainGridRight}>
                {showSummaryItemPrice && (
                  <Typography variant='h6' className={classes.priceText}>
                    {getFormattedPrice(price.prices.wardrobe['priceWithoutVat'], price.currencyCode)}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {Object.values(price.prices.accessories).length > 0 && (
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography variant='h6' className={classes.infoText}>
                    {Lang('Accessories')}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.mainGridRight}>
                  {showSummaryItemPrice && (
                    <Typography variant='h6' className={classes.priceText}>
                      {getFormattedPrice(price.prices.accessories['priceWithoutVat'], price.currencyCode)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
            {Object.values(price.prices.accessories).length > 0 &&
              price.prices.accessories.includedItems.map((item) => (
                <>
                  {item.priceWithoutVat > 0 && (
                    <Container className={classes.accessoriesContainer}>
                      <Typography className={classes.infoText}>
                        {Lang(item.name, 'accessories')}
                        {showSummaryItemPrice && <>: {getFormattedPrice(item.priceWithoutVat)}</>}
                      </Typography>
                    </Container>
                  )}
                </>
              ))}
            {typeof price.prices.packing !== 'undefined' && (
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography variant='h6' className={classes.infoText}>
                    {Lang('Packing')}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.mainGridRight}>
                  {showSummaryItemPrice && (
                    <Typography variant='h6' className={classes.priceText}>
                      {getFormattedPrice(price.prices.packing['priceWithoutVat'], price.currencyCode)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
            {typeof price.prices.delivery !== 'undefined' && (
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <Typography variant='h6' className={classes.infoText}>
                    {Lang('Delivery')}
                  </Typography>
                </Grid>
                <Grid item xs={4} className={classes.mainGridRight}>
                  {showSummaryItemPrice && (
                    <Typography variant='h6' className={classes.priceText}>
                      {getFormattedPrice(price.prices.delivery['priceWithoutVat'], price.currencyCode)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Typography variant='h5' className={classes.infoText}>
                  <strong>{Lang('TotalPrice')}</strong>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.mainGridRight}>
                {price.totalPriceWithoutVat && (
                  <Typography variant='h5' className={classes.priceText}>
                    <strong>{getFormattedPrice(price.totalPriceWithoutVat, price.currencyCode)}</strong>
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Typography variant='h5' className={classes.infoText}>
                  <strong>{Lang('TotalPriceWithVat').replace('{0}', currencyFormat(price.vat))}</strong>
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.mainGridRight}>
                {price.totalPriceWithVat && (
                  <Typography variant='h5' className={classes.priceText}>
                    <strong>{getFormattedPrice(price.totalPriceWithVat, price.currencyCode)}</strong>
                  </Typography>
                )}
              </Grid>
            </Grid>
            {emailAddress === 'sopko@its4you.sk' && <JSONTree data={price} />}
            {emailAddress === 'mericko@its4you.sk' && <JSONTree data={price} />}
          </Container>
          <Container className={classes.buttonsContainer}>
            <Grid container spacing={3}>
              {isPhoneSaveAvailable && (
                <Grid item xs={4}>
                  <Button variant='contained' className={classes.nextButtonActive} onClick={handleOpenPhoneSave}>
                    <div className={classes.buttonActiveDiv}>
                      <Typography variant='h6' className={classes.buttonNextText}>
                        {Lang('SaveOrder', 'buttons')}
                      </Typography>
                    </div>
                  </Button>
                </Grid>
              )}
              <Grid item xs={isPhoneSaveAvailable ? 8 : 12} className={classes.mainGridCenter}>
                {skippSubmitOrder && (
                  <Button variant='contained' color='primary' className={classes.nextButtonActive} onClick={(e) => onClickSubmitOrder(e)}>
                    <div className={classes.buttonActiveDiv}>
                      <Typography variant='h6' className={classes.buttonNextText}>
                        {Lang('Order', 'buttons')}
                      </Typography>
                    </div>
                  </Button>
                )}
                {!skippSubmitOrder && (
                  <Button variant='contained' color='primary' className={classes.nextButtonActive} onClick={() => onClickSelectStep()}>
                    <div className={classes.buttonActiveDiv}>
                      <Typography variant='h6' className={classes.buttonNextText}>
                        {Lang('SubmitOrder')}
                      </Typography>
                    </div>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Container>
        </Paper>
        <VerticalSidebar onClickSteps={onClickSteps} />
        <SubmitOrder isModalOpen={openSubmitDialog} classes={classes} savedOrder={savedOrder} />
      </>
    )
  } else {
    return <></>
  }
}

export default PriceCalculation
