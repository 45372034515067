import React, { useState } from 'react'
import { BOARD_DEPTH } from '../../config'
import { useFrame, useThree } from 'react-three-fiber'
import WindowTop from './Windows/WindowTop'
import WindowBottom from './Windows/WindowBottom'
import Window from './Windows/Window'

/**
 * @param {Array} doorAttributes
 * @param {Array} windowsAttributes
 * @returns {JSX.Element}
 * @constructor
 */
const Windows = ({ doorAttributes, windowsAttributes }) => {
  const { camera } = useThree()
  const [cameraPosition, setCameraPosition] = useState({ x: 0, y: 0 })
  const spacesCount = windowsAttributes['spacesCount']
  const windows = []

  const wardrobeWidth = windowsAttributes['width'] - BOARD_DEPTH * 2
  const spaceWidth = wardrobeWidth / spacesCount
  let nextDoorPosition = 0

  let i

  for (i = 1; i <= spacesCount; i++) {
    nextDoorPosition += BOARD_DEPTH

    if (i === 1) {
      nextDoorPosition += spaceWidth / 2
    } else {
      nextDoorPosition += spaceWidth
    }

    if (i === 1 || i === spacesCount) {
      nextDoorPosition += BOARD_DEPTH
    }

    windows.push({ id: i, posX: nextDoorPosition })

    if (i === 1) {
      nextDoorPosition -= BOARD_DEPTH * 3
    } else {
      nextDoorPosition -= BOARD_DEPTH * 2
    }
  }

  useFrame(() => {
    setCameraPosition({ x: camera.rotation.x, y: camera.rotation.y })
  })

  if (cameraPosition.y > -0.5) {
    return (
      <>
        <WindowTop doorAttributes={doorAttributes} wardrobeAttributes={windowsAttributes} />
        {windows.map((window) => (
          <Window
            doorAttributes={doorAttributes}
            windowsAttributes={windowsAttributes}
            key={window.id}
            id={window.id}
            posY={0}
            posX={window.posX}
          />
        ))}
        <WindowBottom doorAttributes={doorAttributes} wardrobeAttributes={windowsAttributes} />
      </>
    )
  } else {
    return <></>
  }
}

export default React.memo(Windows)
