import { DEFAULT_ROOM_TYPE } from '../config'

export function getType(roomType, objType) {
  let type = roomType

  if (null === type) {
    switch (objType) {
      case 'd':
        type = 'a'
        break
      default:
        type = DEFAULT_ROOM_TYPE
        break
    }
  }

  return type
}

/**
 * @returns {boolean}
 */
export function isSelectTypeAvailable() {
  return process.env.REACT_APP_SELECT_ROOM_TYPE_AVAILABLE !== 'false'
}
