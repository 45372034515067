export function currencyFormat(value) {
  return numberFormat(value, process.env.REACT_APP_CURRENCY_NUMBER_FORMAT_DECIMALS)
}

export function numberFormat(value, decimals, decPoint, thousandsSep) {
  decPoint = decPoint || process.env.REACT_APP_NUMBER_FORMAT_DECIMALS_SEPARATOR
  decimals = decimals !== undefined ? decimals : process.env.REACT_APP_NUMBER_FORMAT_DECIMALS
  thousandsSep = thousandsSep || process.env.REACT_APP_NUMBER_FORMAT_THOUSANDS_SEPARATOR

  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  let result = value.toLocaleString('en-US', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals
  })

  let pieces = result.split('.')
  pieces[0] = pieces[0].split(',').join(thousandsSep)

  return pieces.join(decPoint)
}
