import create from 'zustand'

const store = create((set, get) => {
  return {
    uuid: '',
    insert: false,
    objId: null,
    objNr: null,
    setInsert(insert) {
      set({ insert })
    },
    setObjId(objId) {
      set({ objId })
    },
    setUUId(uuid) {
      set({ uuid: uuid })
    },
    getUUId() {
      const { uuid } = get()

      return uuid
    }
  }
})

export const { getState: getOrder, setState, useStore: useOrder, apiStore: apiOrder } = store
