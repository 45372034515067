import React from 'react'
import { makeStyles, Modal, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'silver',
    opacity: 0.9
  }
}))

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = () => ({})

/**
 * @returns {*}
 * @constructor
 */
const AppLoadingComponent = ({ show }) => {
  const classes = useStyles()

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={show}
      closeAfterTransition
      disableEnforceFocus
      disableBackdropClick
    >
      <div>
        <CircularProgress />
      </div>
    </Modal>
  )
}

class AppLoading extends React.Component {
  render() {
    return (
      <>
        <AppLoadingComponent show={!this.props.isAppLoaded} />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLoading)
