import React from 'react'
/*
import {Icon, Menu, Sidebar, Step} from "semantic-ui-react";
import {ICONS} from "../../../config";
import {Lang} from "../../../Utils";
import {useStore} from "../../../store";
*/

const TopMenu = () => {
  return <></>
  /*
    const objType = getState().objType;
    const mainSize = getState().mainSize;

    let showBig = (mainSize !== 'mini');
    const stepSize = mainSize === 'big' ? 'tiny' : mainSize;
    const Steps = [];

    if ('d' !== objType) {
        Steps.push({name: 'selectCorpuses', label: 'Corpuses'});
    }

    if ('v' !== objType) {
        Steps.push({name: 'selectDoors', label: 'Doors', go: 'selectDoorSpacesCount'});
    }

    Steps.push({name: 'roomType', label: 'Type'});
    Steps.push({name: 'setWardrobeParameters', label: 'Attributes'});
    Steps.push({name: 'order', label: 'Order'});

    return (
        <Sidebar
            as={Menu}
            direction="top"
            visible={true}
        >
            <Step.Group unstackable widths={5} size={stepSize} className="topStepGroupContent">
                {Steps.map(stepData => (
                    <Step
                        key={stepData.name + 'Step'}
                        active={mainStep === stepData.name}
                        stackable='tablet'
                    >
                        {showBig && <Icon name={ICONS[stepData.name]} size={stepSize}/>}
                        <Step.Content>
                            {!showBig && <Icon name={ICONS[stepData.name]} size="big"/>}
                            <Step.Title title={Lang(stepData.label + 'Title')}>
                                {Lang(stepData.label)}
                            </Step.Title>
                        </Step.Content>
                    </Step>))}
            </Step.Group>
        </Sidebar>
    );
    */
}

export default TopMenu
