import * as THREE from 'three'
import React, { useMemo } from 'react'

/**
 * @param {Object} roomAttributes
 * @param {Object} wardrobeAttributes
 * @returns {JSX.Element}
 * @constructor
 */
const SmallFloor = ({ roomAttributes, wardrobeAttributes }) => {
  const roomType = roomAttributes.type
  const roomHeight = roomAttributes.height
  const wardrobeDepth = wardrobeAttributes.depth
  const wardrobeWidth = wardrobeAttributes.width
  let startPositionX = 0
  let floorWidth = wardrobeWidth

  if (roomAttributes.backWallType === 'b' && (roomAttributes.type === 'b' || roomAttributes.type === 'c')) {
    floorWidth += 100
    startPositionX = -50
  }

  const mapTexture = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Floor/WoodFloor07Small_col.jpg')
  const lightMapTexture = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Floor/WoodFloor07Small_rgh.jpg')
  const bumpMapTexture = new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Floor/WoodFloor07Small_AO.jpg')

  const repeatTexture = (texture, repeatNum) => {
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(repeatNum, 0.5)

    return texture
  }

  const repeatNum = useMemo(() => 0.5 + Math.abs(Math.floor(floorWidth / 100)), [floorWidth])

  const map = useMemo(() => repeatTexture(mapTexture, repeatNum), [mapTexture, repeatNum])
  const lightMap = useMemo(() => repeatTexture(lightMapTexture, repeatNum), [lightMapTexture, repeatNum])
  const bumpMap = useMemo(() => repeatTexture(bumpMapTexture, repeatNum), [bumpMapTexture, repeatNum])

  const positionX = useMemo(() => (roomType !== 'b' ? startPositionX - 100 : startPositionX), [roomType, startPositionX])
  const positionY = useMemo(() => 0 - roomHeight / 2, [roomHeight])

  return (
    <mesh position={[positionX, positionY, 0]} rotation={[Math.PI / 2, Math.PI, 0]}>
      <planeGeometry attach='geometry' args={[floorWidth, wardrobeDepth]} />
      <meshPhongMaterial attach='material' map={map} bumpMap={bumpMap} bumpScale={3} lightMap={lightMap} lightMapIntensity={0.5} />
    </mesh>
  )
}

export default React.memo(SmallFloor)
