import React, { useCallback } from 'react'
import { Lang } from '../../../../Utils'
import { Paper, Container, InputAdornment, Typography, Grid, Button } from '@material-ui/core'
import TopMenu from '../../Menu/TopMenu'
import VerticalSidebar from '../../Components/Molecules/VerticalSidebar'
import { makeStyles } from '@material-ui/core/styles'
import MiddleMenu from '../../Menu/MiddleMenu'
import SiteAppBar from '../../Menu/SiteAppBar'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import { getState } from '../../../../store'
import { getSteps } from '../../../../Stores/Steps'
import { Redirect } from 'react-router-dom'
import { START_STEP } from '../../../../config'

const useStyles = makeStyles(() => ({
  mainContainer: {
    paddingTop: 20,
    paddingBottom: 20
  },
  mainTypography: {
    textTransform: 'none'
  },
  mainGridCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonNextText: {
    textTransform: 'none'
  }
}))

/**
 * @param optionData
 * @returns {JSX.Element}
 * @constructor
 */
const AdditionalOption = ({ optionData }) => {
  let labelLang = Lang(optionData.name, 'inputs').replace('{0}', optionData.value).replace('{1}', optionData.measure)
  const additionalOptionData = JSON.parse(getState().additionalOption)
  const setAdditionalOption = getState().setAdditionalOption

  const handleChange = useCallback(
    (event, type, value) => {
      setAdditionalOption(type, value)
    },
    [setAdditionalOption]
  )

  if (optionData.type === 'checkbox') {
    let isDisabled = optionData.disabled ?? false
    let isActive = optionData.active

    if (additionalOptionData[optionData.name]) {
      isActive = additionalOptionData[optionData.name] === '1'
    }

    return (
      <div>
        <FormControlLabel
          control={
            <Checkbox
              disabled={isDisabled}
              checked={isActive}
              name={optionData.name}
              color='primary'
              onChange={(e) => handleChange(e, optionData.name, isActive ? '0' : '1')}
            />
          }
          label={labelLang}
        />
      </div>
    )
  } else if (optionData.type === 'input') {
    return (
      <div>
        <TextField
          id='filled-number'
          label={labelLang}
          type='number'
          defaultValue={optionData.default}
          onChange={(e) => handleChange(e, optionData.name, e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position='end'>{optionData.measure}</InputAdornment>
          }}
        />
      </div>
    )
  } else {
    return <></>
  }
}

/**
 * @param fullScreenHandle
 * @param onClickSteps
 * @param price
 * @returns {JSX.Element}
 * @constructor
 */
const Accessories = ({ fullScreenHandle, onClickSteps, price }) => {
  const classes = useStyles()
  const openModal = getSteps().openModal
  const objType = getState().objType
  let wardrobePrice = ''

  if (price && price.price) {
    wardrobePrice = parseFloat(price.price)

    if (price.prices && Object.values(price.prices)) {
      Object.keys(price.prices).map((priceKey) => {
        if (price.prices[priceKey] && priceKey === 'wardrobe') {
          wardrobePrice = price.prices[priceKey]
        }
      })
    }
  }

  if (wardrobePrice && price.currencyCode) {
    wardrobePrice += ' ' + price.currencyCode
  }

  const onClickSelectStep = useCallback(() => {
    openModal('attributes')
  }, [openModal])

  if (objType === '') {
    return <Redirect to={'/' + Lang(START_STEP, 'navigation')} />
  }

  return (
    <>
      <TopMenu mainStep='order' />
      <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />
      <Paper>
        <SiteAppBar title={Lang('Accessories')} onClickSteps={onClickSteps} />
        <Container className={classes.mainContainer}>
          {wardrobePrice && (
            <>
              <Typography variant='subtitle1' className={classes.mainTypography}>
                <strong>
                  {Lang('WardrobePriceWithoutAccessories')}: {wardrobePrice.replace('.', ',')}
                </strong>
              </Typography>
              <br />
            </>
          )}
          {price.additionalOptions &&
            price.additionalOptions.map((optionData, index) => <AdditionalOption optionData={optionData} key={index} />)}
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.mainGridCenter}>
              <Button variant='contained' color='primary' className={classes.nextButtonActive} onClick={() => onClickSelectStep()}>
                <div className={classes.buttonActiveDiv}>
                  <Typography variant='h6' className={classes.buttonNextText}>
                    {Lang('showPriceCalculation', 'buttons')}
                  </Typography>
                </div>
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <VerticalSidebar onClickSteps={onClickSteps} />
    </>
  )
}

export default Accessories
