export const TABS_LANG = {
  'EGEER Standard': 'Holz-Dekor',
  Mirrors: 'Spiegel',
  Glasses: 'Gläser',
  'EGEER Exclusive': 'Holz-Dekor exclusive',
  'EGEER StandardMobile': 'Holz-Dekor',
  MirrorsMobile: 'Spiegel',
  GlassesMobile: 'AGC Lacobel Glas',
  'EGEER ExclusiveMobile': 'Holz-Dekor <br/> exclusive'
}
