import React from 'react'
import { Image } from 'semantic-ui-react'
import useStore, { getState } from '../../../store'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import { Badge, Button } from '@material-ui/core'

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = () => ({})

const Preview = ({ corpuses }) =>
  corpuses.map((corpus, id) => {
    const url = process.env.PUBLIC_URL + '/images/Corpuses/variants/korpus' + corpus + '.png'

    return (
      <Grid key={id} item>
        <Image src={url} key={'previewImage' + id} id={id} />
      </Grid>
    )
  })

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto'
  },
  preview: {
    margin: 10
  },
  wardrobe: {
    borderTop: '2px solid black',
    borderLeft: '2px solid black',
    borderRight: '2px solid black'
  },
  paperWardrobe: {
    padding: 10
  },
  badge: {
    top: '85%'
  }
}))

const TabContent = ({ onSelect, variants, spaceHeight, isSpacesDivided }) => {
  const priceVersion = useStore((state) => state.priceVersion)
  const selectedCorpuses = useStore((state) => state.selectedCorpuses)
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {variants &&
        variants.map((corpuses, index) => {
          let isSelected = true
          const corpusIds = []

          corpuses.corpuses.forEach(function (corpusId, index) {
            corpusIds.push(corpusId)

            if (corpusId !== selectedCorpuses[index + 1]) {
              isSelected = false
            }
          })

          //const isSelected = selectedCorpuses === corpusIds;

          return (
            <Badge
              key={index + priceVersion}
              invisible={!isSelected}
              color='primary'
              overlap='circle'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              classes={{ badge: classes.badge }}
              badgeContent={<CheckIcon fontSize='small' />}
            >
              <Button onClick={() => onSelect(corpuses, isSpacesDivided)}>
                <div className={classes.preview}>
                  <div container>
                    <Grid container className={classes.wardrobe}>
                      <Preview spaceHeight={spaceHeight} corpuses={corpusIds} />
                    </Grid>
                  </div>
                </div>
              </Button>
            </Badge>
          )
        })}
    </div>
  )
}

class SelectCorpusesVariants extends React.Component {
  onSelect(corpuses, isSpacesDivided) {
    let spaceType = 'A'
    let spaceId = 1
    let i = 0

    corpuses.corpuses.forEach(function (corpusId) {
      i++

      if (isSpacesDivided) {
        getState().setCorpusType(spaceId + spaceType, corpusId)

        if (spaceType === 'A') {
          spaceType = 'B'
        } else if (spaceType === 'B') {
          spaceType = 'A'
          spaceId++
        }
      } else {
        getState().setSpaceDivided(i, false)
      }

      getState().setCorpusType(i, corpusId)
    })

    getState().setPriceVersion()
    getState().closeModal('selectCorpusVariants')
  }

  render() {
    let variants = []
    let isSpacesDivided = false

    if (this.props.corpusesVariants.variants[this.props.spacesCount]) {
      let spacesCount = this.props.spacesCount

      if (this.props.basicSpaceWidth > 100) {
        spacesCount = spacesCount * 2
        isSpacesDivided = true
      }

      variants = this.props.corpusesVariants.variants[spacesCount]
    }

    return (
      <TabContent onSelect={this.onSelect} variants={variants} spaceHeight={this.props.spaceHeight} isSpacesDivided={isSpacesDivided} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCorpusesVariants)
