import { BOARD_DEPTH } from '../config'
import { Lang } from '../Utils'
import { numberFormat } from './format'

/**
 * @param spaceHeight
 * @param corpus
 * @param type
 * @returns {string}
 */
export function getImage(spaceHeight, corpus, type) {
  return process.env.PUBLIC_URL + `/images/Corpuses/${spaceHeight}/korpus${corpus}${type}.png`
}

/**
 * @param spacesCount
 * @param dividedSpaces
 * @param selectedSpace
 * @returns {{backId: null, actualId: null, nextId: null, totalSteps: number}}
 */
export function getCorpusesSpaces(spaceWidth, spacesCount, dividedSpaces, selectedSpace) {
  const CorpusesSpaces = []
  let selectedSpaceId = selectedSpace
  let firstId = null
  let actualSeq = 0
  let isDivided = false
  let seq = 0

  if (!spacesCount) spacesCount = 2

  for (let spaceId = 1; spaceId <= spacesCount; spaceId++) {
    if (dividedSpaces[spaceId] || spaceWidth > 100) {
      CorpusesSpaces.push({ id: spaceId + 'A' })
      if (selectedSpace === spaceId + 'A' || selectedSpace === spaceId) {
        actualSeq = seq
        isDivided = true
        selectedSpaceId = spaceId
      }

      seq++
      CorpusesSpaces.push({ id: spaceId + 'B' })
      if (selectedSpace === spaceId + 'B') {
        actualSeq = seq
        isDivided = true
        selectedSpaceId = spaceId
      }
    } else {
      if (!firstId) firstId = spaceId

      CorpusesSpaces.push({ id: spaceId })

      if (selectedSpace === spaceId) actualSeq = seq
    }

    if (!firstId) firstId = dividedSpaces[spaceId] ? spaceId + 'A' : spaceId

    seq++
  }

  const totalSteps = seq

  let nextId = null

  if (CorpusesSpaces[actualSeq + 1]) {
    nextId = CorpusesSpaces[actualSeq + 1].id
  }

  let backId = null

  if (CorpusesSpaces[actualSeq - 1]) {
    backId = CorpusesSpaces[actualSeq - 1].id
  }

  const actualId = CorpusesSpaces[actualSeq].id

  return { totalSteps, firstId, backId, actualId, actualSeq, nextId, isDivided, selectedSpaceId }
}

/**
 * @param selectedSpaceId
 * @param actualId
 * @param {number} defaultSpaceWidth
 * @param dividedSpaces
 * @param dividedSpacesWidth
 * @returns {number}
 */
export function getCorpusesSpaceWidth(selectedSpaceId, actualId, defaultSpaceWidth, dividedSpaces, dividedSpacesWidth) {
  let width = defaultSpaceWidth

  if (dividedSpaces[selectedSpaceId]) {
    const otherId = actualId === selectedSpaceId + 'A' ? selectedSpaceId + 'B' : selectedSpaceId + 'A'

    if (dividedSpacesWidth[actualId]) {
      width = dividedSpacesWidth[actualId]
    } else if (dividedSpacesWidth[otherId]) {
      width = defaultSpaceWidth - BOARD_DEPTH - dividedSpacesWidth[otherId]
    } else {
      width = (defaultSpaceWidth - BOARD_DEPTH) / 2
    }
  }

  return Math.floor(width * 10) / 10
}

/**
 * @param {number} value
 * @param {number} minValue
 * @param {number} maxValue
 * @returns {string|*}
 */
export function controlCorpusesSpaceWidth(value, minValue, maxValue) {
  if (value < minValue) {
    let error = Lang('MinSpaceWidthError', 'inputs')

    return error.replace('{0}', numberFormat(minValue))
  }

  if (value > maxValue) {
    let error = Lang('MaxSpaceWidthError', 'inputs')

    return error.replace('{0}', numberFormat(maxValue))
  }

  return ''
}
