/**
 * @param {String} currencyCode
 * @returns {Object}
 */
import { currencyFormat } from './format'

export function getCurrency(currencyCode) {
  let currency = ''

  if (currencyCode === 'EUR') {
    currency = '€'
  } else if (currencyCode === 'CZK') {
    currency = 'Kč'
  }

  return currency
}

/**
 * @param {String} currencyCode
 * @returns {Object}
 */
export function getFormattedPrice(value, currencyCode = '') {
  return currencyFormat(value) + (currencyCode ? ' ' + currencyCode : '')
}
