import React, { useCallback } from 'react'
import useSteps, { getSteps } from '../../../Stores/Steps'
import { makeStyles, Button } from '@material-ui/core'
import { Modal } from 'semantic-ui-react'
import Typography from '@material-ui/core/Typography'
import { Lang } from '../../../Utils'
import Alert from '@material-ui/lab/Alert'
import useInfo from '../../../utils/useInfo'
import { AlertTitle } from '@material-ui/lab'
import parse from 'html-react-parser'
import { Icon, Menu } from 'semantic-ui-react'

const useStyles = makeStyles((theme) => ({
  mainBox: {
    paddingTop: 10,
    paddingBottom: 10
  },
  infoBox: {
    width: 330,
    paddingTop: 10,
    paddingBottom: 10
  },
  errorBox: {
    paddingTop: 10
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  mainGridCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainGridRight: {
    textAlign: 'right'
  },
  buttonActiveText: {
    paddingBottom: 0,
    marginBottom: 0
  },
  nextButtonActive: {
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  },
  buttonBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto'
  }
}))

/**
 * @returns {*}
 * @constructor
 */
const ShowInfoModal = () => {
  const classes = useStyles()
  const isAttributesModalOpen = useSteps((state) => state.isModalOpen['info'])
  const closeModal = getSteps().closeModal
  const openModal = getSteps().openModal

  const handleClose = () => {
    closeModal('info')
  }

  const infoBlocks = useInfo()

  const onClickShowInfoModal = useCallback(
    (e) => {
      e.stopPropagation() // stop it at the first intersection
      openModal('info')
    },
    [openModal]
  )

  if (infoBlocks) {
    return (
      <>
        <Modal
          open={isAttributesModalOpen}
          onClose={handleClose}
          trigger={
            <Menu.Item onClick={(e) => onClickShowInfoModal(e)}>
              <Icon name='info' />
              {Lang('Info')}
            </Menu.Item>
          }
        >
          <Modal.Content>
            <Modal.Description>
              {infoBlocks.map((infoBlock) => (
                <>
                  <Alert icon={false} severity='info' className={classes.alertInfo}>
                    <AlertTitle>{Lang(infoBlock.title, 'info')}</AlertTitle>
                    {parse(Lang(infoBlock.content, 'info'))}
                  </Alert>
                  <br />
                </>
              ))}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button variant='contained' color='primary' onClick={handleClose} className={classes.nextButtonActive}>
              <Typography className={classes.buttonActiveText}>{Lang('Close')}</Typography>
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    )
  } else {
    return <></>
  }
}

export default ShowInfoModal
