import React, { useCallback } from 'react'

import useStore, { getState } from '../../../store'
import useSteps, { getSteps } from '../../../Stores/Steps'
import { makeStyles, Modal, Grid, Button, Select, InputLabel, FormControl, MenuItem } from '@material-ui/core'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import Typography from '@material-ui/core/Typography'
import { Lang } from '../../../Utils'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useHistory } from 'react-router-dom'
import { getOrder } from '../../../Stores/Order'
import TagManager from 'react-gtm-module'
import { setStep } from '../../../utils/steps'

const tagManagerArgs = {
  dataLayer: {
    event: 'formSend',
    type: 'kalkulace'
  }
}

const useStyles = makeStyles((theme) => ({
  mainBox: {
    paddingTop: 10,
    paddingBottom: 10
  },
  infoBox: {
    width: 330,
    paddingTop: 10,
    paddingBottom: 10
  },
  errorBox: {
    paddingTop: 10
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  mainGridCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainGridRight: {
    textAlign: 'right'
  },
  buttonActiveText: {
    paddingBottom: 0,
    marginBottom: 0
  },
  buttonActiveCaption: {
    margin: 0,
    padding: 0,
    fontSize: 12
  },
  previewButtonActive: {
    textTransform: 'none'
  },
  nextButtonActive: {
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  }
}))

/**
 * @returns {*}
 * @constructor
 */
const SetDTDOut = () => {
  const classes = useStyles()
  const history = useHistory()
  const isDTDOutModalOpen = useSteps((state) => state.isModalOpen['dtdOut'])
  const closeModal = getSteps().closeModal
  const isDTDOutDisabled = useStore((state) => state.isDTDOutDisabled)

  const handleClose = () => {
    closeModal('dtdOut')
  }

  const onClickSelectStep = useCallback(
    (history) => {
      getOrder().setInsert(true)
      TagManager.dataLayer(tagManagerArgs)

      let nextStep = isDTDOutDisabled ? process.env.REACT_APP_LAST_STEP : 'selectDTDOut'

      closeModal('dtdOut')
      setStep(nextStep)
      history.push('/' + Lang(nextStep, 'navigation'))
    },
    [closeModal, isDTDOutDisabled]
  )

  const handleChange = useCallback((event) => {
    getState().setDisableDTDOut(event.target.value)
  }, [])

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={isDTDOutModalOpen}
        onClose={handleClose}
        closeAfterTransition
        disableEnforceFocus
        disableBackdropClick
      >
        <div className={classes.paper}>
          <form className={classes.root} noValidate autoComplete='off'>
            <FormControl fullWidth>
              <InputLabel>{Lang('StopBar')}</InputLabel>
              <Select value={isDTDOutDisabled} onChange={handleChange}>
                <MenuItem value={0}>{Lang('Yes')}</MenuItem>
                <MenuItem value={1}>{Lang('No')}</MenuItem>
              </Select>
            </FormControl>
          </form>

          <br />
          <Grid container spacing={3}>
            <Grid item xs>
              <Button variant='outlined' className={classes.previewButtonActive} onClick={handleClose} startIcon={<NavigateBeforeIcon />}>
                <div className={classes.buttonActiveDiv}>
                  <Typography className={classes.buttonActiveCaption}>{Lang('PreviewStep')}</Typography>
                </div>
              </Button>
            </Grid>
            <Grid item xs className={classes.mainGridRight}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => onClickSelectStep(history)}
                endIcon={<NavigateNextIcon />}
                className={classes.nextButtonActive}
              >
                <Typography variant='h6' className={classes.buttonActiveText}>
                  {Lang('NextStep')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  )
}

export default SetDTDOut
