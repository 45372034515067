import React, { useEffect, useState } from 'react'
import Preview from '../../../Preview/Preview'
import { Sticky } from 'semantic-ui-react'
import { getState } from '../../../store'

/**
 * @returns {*}
 * @constructor
 */
const SelectDTD = ({ contextRef }) => {
  const [isVisible, setVisible] = useState(false)
  const objType = getState().objType
  const selectedDTDType = getState().selectedDTDType

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)

    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])

  const scrollHandler = () => {
    setVisible(window.pageYOffset > 400)
  }

  if (isVisible && selectedDTDType === 'main' && objType !== 'd') {
    return (
      <Sticky context={contextRef} offset={50}>
        <Preview />
      </Sticky>
    )
  } else {
    return <></>
  }
}

export default SelectDTD
