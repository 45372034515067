import * as THREE from 'three'
import React from 'react'
import { BAIL_WIDTH } from './types'
import { BOARD_DEPTH } from '../../config'
import getMaterial from './material'

/**
 * @param {Object} doorAttributes
 * @param {Object} wardrobeAttributes
 * @param showStopBar
 * @returns {JSX.Element}
 * @constructor
 */
const Bail = ({ doorAttributes, wardrobeAttributes }) => {
  const objectMaterial = getMaterial(doorAttributes['profile'])

  const height = wardrobeAttributes.height
  let width = wardrobeAttributes.width

  if (wardrobeAttributes.showStopBar) {
    width -= BOARD_DEPTH * 2
  }

  const depth = wardrobeAttributes.depth

  const posY = height
  let posX = width / 2

  if (wardrobeAttributes.showStopBar) {
    posX += BOARD_DEPTH
  }

  const posZ = depth / 2 - BAIL_WIDTH / 2
  const rotation = [Math.PI / 2, 0, 0]

  const topObject = new THREE.Mesh(new THREE.PlaneGeometry(width, BAIL_WIDTH), objectMaterial)

  const bottomObject = new THREE.Mesh(new THREE.PlaneGeometry(width, BAIL_WIDTH / 2), objectMaterial)

  const centerObject = new THREE.Mesh(new THREE.PlaneGeometry(width, BAIL_WIDTH / 2), objectMaterial)

  const frontObject = new THREE.Mesh(new THREE.PlaneGeometry(width, BAIL_WIDTH / 2), objectMaterial)

  return (
    <group position-y={posY} position-x={posX} position-z={posZ}>
      <mesh rotation={rotation}>
        <primitive object={topObject} />
      </mesh>
      <mesh position-y={0 - BAIL_WIDTH / 4} position-x={0} position-z={0 - BAIL_WIDTH / 2}>
        <primitive object={bottomObject} />
      </mesh>
      <mesh position-y={0 - BAIL_WIDTH / 4} position-x={0} position-z={0}>
        <primitive object={centerObject} />
      </mesh>
      <mesh position-y={0 - BAIL_WIDTH / 4} position-x={0} position-z={BAIL_WIDTH / 2}>
        <primitive object={frontObject} />
      </mesh>
    </group>
  )
}

export default Bail
