export const BUTTONS_LANG = {
  roomType: 'Auswahl des Grundrisstyps',
  setWardrobeParameters: 'Parametereingabe',
  selectCorpusesSetSpacesCount: 'Auswahl der Anzahl der Türen',
  selectCorpuses: 'Innenansicht',
  selectDTD: 'Auswahl der Dekore',
  selectDTDSecond: 'Auswahl der Dekore der Seitenwände',
  selectDoorSpacesCount: 'Auswahl des Türtyps',
  selectProfiles: 'Profilart und Farbe auswählen',
  selectDoorsFill: 'Türfüllungen auswählen',
  order: 'Bestellformular',
  accessories: 'Zubehör',
  priceCalculation: 'Preiskalkulation',
  showPriceCalculation: 'Preis anzeigen',
  resetHeights: 'Originaleinstellungen'
}
