import React, { useCallback } from 'react'
import useStore, { getState } from '../../../../store'
import { Button, Container } from '@material-ui/core'
import MobileStepper from '@material-ui/core/MobileStepper'
import { Lang } from '../../../../Utils'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import Door from './Door'
import { makeStyles } from '@material-ui/core/styles'
import DoorParameters from './DoorParameters'

const useStyles = makeStyles(() => ({
  rootRight: {
    flexGrow: 1
  },
  mainDoorContainer: {
    padding: 10
  }
}))

/**
 * @returns {*}
 * @constructor
 */
const DoorStepper = ({ showDoorsFill, selectedSpaceId, handleNext, handleBack }) => {
  const classes = useStyles()
  const priceVersion = useStore((state) => state.priceVersion)
  const spacesCount = useStore((state) => state.spacesCount)
  const selectedDoorsSpace = useStore((state) => state.selectedDoorsSpace)
  const setSelectedDoorFillId = getState().setSelectedDoorFillId
  const setDoorFillsAttributes = getState().setDoorFillsAttributes
  const deleteDoorFillsAttributes = getState().deleteDoorFillsAttributes
  const doorFillsAttributes = JSON.parse(getState().doorFillsAttributes)

  const onSelectSpace = useCallback(
    (doorFillId) => {
      setSelectedDoorFillId(doorFillId)
    },
    [setSelectedDoorFillId]
  )

  const onSetDoorFillHeight = useCallback(
    (doorFillId, value) => {
      setDoorFillsAttributes(doorFillId, parseInt(value))
    },
    [setDoorFillsAttributes]
  )

  const onRemoveDoorsHeights = useCallback(() => {
    deleteDoorFillsAttributes()
  }, [deleteDoorFillsAttributes])

  return (
    <div className={classes.rootRight}>
      <MobileStepper
        steps={spacesCount}
        position='static'
        variant='text'
        activeStep={selectedSpaceId}
        nextButton={
          <Button size='small' onClick={handleNext} disabled={selectedSpaceId === spacesCount - 1}>
            {Lang('NextDoor')}
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size='small' onClick={handleBack} disabled={selectedSpaceId === 0}>
            <KeyboardArrowLeft />
            {Lang('BackDoor')}
          </Button>
        }
      />
      <Container className={classes.mainDoorContainer}>
        <DoorParameters
          onSetDoorFillHeight={onSetDoorFillHeight}
          doorFillsAttributes={doorFillsAttributes}
          selectedSpaceId={selectedSpaceId}
          onSelectSpace={onSelectSpace}
          key={'DoorR' + priceVersion}
          height={300}
          count={selectedDoorsSpace[selectedSpaceId + 1] ?? 1}
          showDoorsFill={showDoorsFill}
          removeDoorsHeights={onRemoveDoorsHeights}
        >
          <Door
            onSelectSpace={onSelectSpace}
            selectedSpaceId={selectedSpaceId}
            key={'DoorL' + priceVersion}
            height={300}
            count={selectedDoorsSpace[selectedSpaceId + 1] ?? 1}
            showDoorsFill={showDoorsFill}
          />
        </DoorParameters>
      </Container>
    </div>
  )
}

export default DoorStepper
