import React, { useCallback, useState, useRef, useEffect } from 'react'
import { getState } from '../../../store'
import { Lang } from '../../../Utils'
import { makeStyles, Box } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import DefInput from '../Components/Atoms/DefInput'
import { numberFormat } from '../../../utils/format'

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  infoErrorBox: {
    marginBottom: 20
  }
}))

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    htmlElRef && htmlElRef.current && htmlElRef.current.focus()
  }

  return [htmlElRef, setFocus]
}

/**
 * @param {Object} parameters
 * @param {Object} heightInputs
 * @param {Number} maxDifferenceHeightValue
 * @param {Boolean} heightIsWrong
 * @param {Object} errorTexts
 * @param {String} type
 * @returns {JSX.Element}
 * @constructor
 */
const InputForm = ({ parameters, heightInputs, maxDifferenceHeightValue, heightIsWrong, errorTexts, controlInputs }) => {
  const classes = useStyles()
  const [isFocused, setInputFocused] = useState(false)
  const [inputRef, setInputElementFocus] = useFocus()
  const setRoomWidth = getState().setRoomWidth
  const setRoomHeight = getState().setRoomHeight
  const setParameters = getState().setParameters
  const setRoomDepth = getState().setRoomDepth
  const setInputFocus = getState().setInputFocus
  const objType = getState().objType

  const onChangeSetRoomWidth = useCallback(
    (width, inputType) => {
      setRoomWidth(width)
      setParameters(inputType, width)
    },
    [setRoomWidth, setParameters]
  )

  const onBlurSetRoomWidth = useCallback(() => {
    controlInputs()
  }, [controlInputs])

  const onChangeSetRoomDepth = useCallback(
    (depth, inputType) => {
      setRoomDepth(depth)
      setParameters(inputType, depth)
    },
    [setRoomDepth, setParameters]
  )

  const onBlurSetRoomDepth = useCallback(() => {
    controlInputs()
  }, [controlInputs])

  const onChangeSetRoomHeight = useCallback(
    (height, inputType) => {
      setRoomHeight(height)
      setParameters(inputType, height)
      heightInputs.map((heightInput) => setParameters(heightInput.type, height))
    },
    [setRoomHeight, setParameters, heightInputs]
  )

  const onBlurSetRoomHeight = useCallback(() => {
    controlInputs()
  }, [controlInputs])

  const onFocusInputType = useCallback(
    (inputType) => {
      setInputFocus(inputType)
    },
    [setInputFocus]
  )

  useEffect(() => {
    if (!isFocused) {
      setTimeout(() => {
        setInputElementFocus()
      }, 100)

      setInputFocused(true)
    }
  }, [isFocused, setInputElementFocus, setInputFocused])

  return (
    <form className={classes.root} noValidate autoComplete='off'>
      {heightIsWrong && (
        <Box className={classes.infoErrorBox}>
          <Alert severity='error'>{Lang('BadHeightValuesInfo').replace('{0}', numberFormat(maxDifferenceHeightValue))}</Alert>
        </Box>
      )}
      <DefInput
        inputRef={inputRef}
        defaultValue={parameters['Width'] ?? ''}
        type='Width'
        handleChange={onChangeSetRoomWidth}
        onFocusInputType={onFocusInputType}
        onBlurInputType={onBlurSetRoomWidth}
        errorObject={errorTexts['Width'] ?? ''}
      />
      {objType !== 'd' && (
        <DefInput
          type='Depth'
          defaultValue={parameters['Depth'] ?? ''}
          handleChange={onChangeSetRoomDepth}
          onFocusInputType={onFocusInputType}
          onBlurInputType={onBlurSetRoomDepth}
          errorObject={errorTexts['Depth'] ?? ''}
        />
      )}
      <DefInput
        type='Height'
        defaultValue={parameters['Height'] ?? ''}
        handleChange={onChangeSetRoomHeight}
        onFocusInputType={onFocusInputType}
        onBlurInputType={onBlurSetRoomHeight}
        errorObject={errorTexts['Height'] ?? ''}
      />
    </form>
  )
}

export default InputForm
