import React from 'react'
import { Text } from '@react-three/drei/Text'

/**
 * @returns {*}
 * @constructor
 */
const AltitudeText = ({ maxWidth, textAlign = 'center', children }) => {
  return (
    <Text color={'black'} fontSize={12} maxWidth={maxWidth} textAlign={textAlign} anchorX='center' anchorY='middle'>
      {children}
    </Text>
  )
}

export default React.memo(AltitudeText)
