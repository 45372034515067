import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, FormControl } from '@material-ui/core'
import { connect } from 'react-redux'
import InputAdornment from '@material-ui/core/InputAdornment'
import Input from '@material-ui/core/Input'
import { getDoorFillHeightId } from '../../../../utils/door'
import { Lang } from '../../../../Utils'
import { getHtmlTooltip } from '../../../../utils/tooltip'
import { getState } from '../../../../store'
import { getParametersDoorSpaceHeights } from '../../../../utils/parameters'
import { MIN_DOOR_FILL_HEIGHT } from '../../../../config'

const HtmlTooltip = getHtmlTooltip()

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = () => ({})

const useStyles = makeStyles(() => ({
  leftGrid: {
    paddingRight: 20
  },
  rightGrid: {
    //paddingTop: 10,
  },
  boxMain: {
    backgroundColor: '#ffffff'
  },
  mainContent: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  mainPadding: {
    paddingTop: 20
  },
  mainCenter: {
    width: 200
  },
  mainGrid: {
    justifyContent: 'center'
  },
  selectedSpace: {
    width: '100%',
    height: '100%',
    zIndex: 2,
    '&:hover': {
      backgroundColor: '#80FF80'
    }
  },
  content: {
    padding: 5
  }
}))

/**
 * @param {Number} height
 * @param {Function} onSetSpaceHeight
 * @param {Number} selectedSpaceId
 * @param {Number} id
 * @returns {JSX.Element}
 * @constructor
 */
const DoorContentEdit = ({
  isDisabled,
  height,
  doorFillsCount,
  isLarge,
  onSetDoorFillHeight,
  doorFillsAttributes,
  selectedSpaceId,
  id
}) => {
  let isBadValue = false
  const doorFillHeightId = getDoorFillHeightId(selectedSpaceId, doorFillsCount, id)
  const [fillHeight, setFillHeight] = useState(null)
  const [showError, setShowError] = useState(doorFillsAttributes[doorFillHeightId] && doorFillsAttributes[doorFillHeightId] < 10)

  useEffect(() => {
    if (doorFillsAttributes[doorFillHeightId]) {
      const doorFillValue = doorFillsAttributes[doorFillHeightId]

      if (fillHeight !== doorFillValue) {
        //setFillHeight(doorFillValue)
      }
    } else {
      //setFillHeight('')
    }
  }, [doorFillsAttributes, doorFillHeightId, selectedSpaceId, setFillHeight, fillHeight])

  const onChange = useCallback(
    (doorFillHeightId, value) => {
      setFillHeight(value)
      onSetDoorFillHeight(doorFillHeightId, value)
      setShowError(false)
    },
    [setFillHeight, onSetDoorFillHeight, setShowError]
  )

  const _onBlur = useCallback(() => {
    setShowError(true)
    getState().setPriceVersion()
  }, [setShowError])

  let infoLabel = ''

  if (showError && doorFillsAttributes[doorFillHeightId] && doorFillsAttributes[doorFillHeightId] < 10) {
    isBadValue = true
    infoLabel = Lang('BadMinValueFillHeight')
  } else if (isLarge && !isDisabled) {
    isBadValue = true
    infoLabel = Lang('TotalHeightEditError')
  }

  if (isDisabled && !isBadValue) {
    infoLabel = Lang('ThisHeightIsAutomaticCalculated')
  }

  //return <></>

  return (
    <HtmlTooltip arrow {...(isBadValue ? { open: isBadValue } : {})} title={infoLabel}>
      <FormControl error={isBadValue}>
        <DoorContentEditInput
          disabled={isDisabled}
          id={'inputSpaceHeight' + doorFillHeightId}
          onChange={(e) => onChange(doorFillHeightId, e.target.value)}
          onBlur={() => _onBlur()}
          height={height}
          fillHeight={fillHeight}
          placeholder={height}
        />
      </FormControl>
    </HtmlTooltip>
  )
}

/*
const DoorContentEditInput = ({ disabled, id, onChange, onBlur }) => {
  return (
    <Input
      inputRef={this.state.inputRef}
      disabled={disabled}
      id={id}
      endAdornment={<InputAdornment position='end'>cm</InputAdornment>}
      aria-describedby='standard-weight-helper-text'
      inputProps={{
        'aria-label': 'weight'
      }}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}
*/

class DoorContentEditInput extends React.Component {
  constructor(props) {
    super(props)
    //this.inputRef = createRef()
  }

  componentDidMount() {
    //this.inputRef.current.defaultValue = this.props.height
    //if (this.props.fillHeight > 0) {
    //  this.inputRef.current.value = this.props.fillHeight
    //}
  }

  componentDidUpdate() {
    //this.state.inputRef.current.defaultValue = this.props.height
    //if (this.props.fillHeight > 0 && this.props.fillHeight !== this.props.height) {
    //  this.inputRef.current.value = this.props.fillHeight
    //}
    /*
    if (this.props.fillHeight > 0) {
      this.inputRef.current.value = this.props.fillHeight
    } else if (this.inputRef.current.value) {
      this.inputRef.current.value = this.props.height
    }

    this.inputRef.current.defaultValue = this.props.height*/
  }

  render() {
    return (
      <Input
        inputRef={this.inputRef}
        //disabled={this.props.disabled}
        //id={this.props.id}
        endAdornment={<InputAdornment position='end'>cm</InputAdornment>}
        aria-describedby='standard-weight-helper-text'
        inputProps={{
          'aria-label': 'weight'
        }}
        //onChange={this.props.onChange}
        //onBlur={this.props.onBlur}
        {...this.props}
      />
    )
  }
}

/**
 * @param onSetDoorFillHeight
 * @param selectedSpaceId
 * @param count
 * @param height
 * @param lineWidth
 * @returns {JSX.Element}
 * @constructor
 */
const DoorContent = ({ onSetDoorFillHeight, doorFillsAttributes, selectedSpaceId, count, height, lineWidth }) => {
  const roomHeight = getState().height
  const classes = useStyles()
  const bottomLineHeight = lineWidth * 2.5
  const contentHeight = height - bottomLineHeight
  const mainCenterWidth = 80
  const priceVersion = getState().priceVersion
  if (!count) count = 1

  const doorFillsCount = count

  const { totalHeight, doorSpacesHeight, doorSpacesHeights } = useMemo(() => {
    const doorSpacesHeight = {}
    const minHeight = MIN_DOOR_FILL_HEIGHT
    let height = minHeight

    for (let num = 0; num < doorFillsCount; num++) {
      if (
        doorFillsAttributes[getDoorFillHeightId(selectedSpaceId, doorFillsCount, num)] &&
        0 < doorFillsAttributes[getDoorFillHeightId(selectedSpaceId, doorFillsCount, num)]
      ) {
        const spaceHeight = doorFillsAttributes[getDoorFillHeightId(selectedSpaceId, doorFillsCount, num)]

        if (spaceHeight && spaceHeight >= minHeight) {
          height += spaceHeight
          doorSpacesHeight[num] = spaceHeight
        }
      }
    }

    const doorSpacesHeights = getParametersDoorSpaceHeights(roomHeight, doorFillsCount, doorSpacesHeight)

    return { height, doorSpacesHeight, doorSpacesHeights }
  }, [doorFillsAttributes, selectedSpaceId, priceVersion, doorFillsCount])

  const spaces = useMemo(() => {
    const totalSetSpaceHeights = Object.keys(doorSpacesHeight).length
    const isSetSpaceAvailable = doorFillsCount !== totalSetSpaceHeights + 1

    const spaces = []

    for (let num = 0; num < count; num++) {
      let height = null

      if (doorSpacesHeights[num]) {
        height = doorSpacesHeights[num]
      }

      const isActive = doorSpacesHeight[num] > 0

      spaces.push({
        id: num,
        blockHeight: contentHeight / count,
        height: height,
        isDisabled: !isActive && !isSetSpaceAvailable,
        selectedSpaceId: selectedSpaceId
      })
    }

    return spaces
  }, [count, contentHeight, doorFillsCount, doorFillsAttributes, selectedSpaceId, doorSpacesHeight])

  return (
    <div className={classes.mainCenter} style={{ width: mainCenterWidth }}>
      <Grid container direction='column' justify='flex-start' alignItems='stretch'>
        {spaces.map((space, index) => (
          <Grid
            item
            key={'contentDoorEdit' + space.id + index}
            className={classes.content}
            style={{
              height: space.blockHeight,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'auto'
            }}
          >
            <DoorContentEdit
              isDisabled={space.isDisabled}
              height={space.height}
              doorFillsCount={count}
              isLarge={roomHeight < totalHeight}
              onSetDoorFillHeight={onSetDoorFillHeight}
              doorFillsAttributes={doorFillsAttributes}
              selectedSpaceId={selectedSpaceId}
              id={space.id}
            />
          </Grid>
        ))}
        <Grid item>
          <div className={classes.bottomLine} style={{ height: bottomLineHeight }} />
        </Grid>
      </Grid>
    </div>
  )
}

class DoorParameters extends React.Component {
  render() {
    let height = this.props.height
    const lineWidth = (height / 100) * 2

    return (
      <>
        <Grid wrap='nowrap' container direction='row' justify='flex-start' alignItems='stretch' style={{ justifyContent: 'center' }}>
          <Grid item>{this.props.children}</Grid>
          {this.props.count > 1 && (
            <Grid item>
              <DoorContent
                onSetDoorFillHeight={this.props.onSetDoorFillHeight}
                doorFillsAttributes={this.props.doorFillsAttributes}
                selectedSpaceId={this.props.selectedSpaceId}
                count={this.props.count}
                height={this.props.height}
                lineWidth={lineWidth}
              />
            </Grid>
          )}
        </Grid>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoorParameters)
