import React, { useCallback, useMemo } from 'react'
import VerticalSidebar from '../Components/Molecules/VerticalSidebar'
import SelectDTD from '../Menu/SelectDTD'
import TopMenu from '../Menu/TopMenu'
import useSteps, { getState } from '../../../store'
import { Card } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import { Lang } from '../../../Utils'
import MiddleMenu from '../Menu/MiddleMenu'
import { PREVIEW_STEP, START_STEP } from '../../../config'
import useStore, { getSteps } from '../../../Stores/Steps'
import { setStep } from '../../../utils/steps'

/**
 * @returns {*}
 * @constructor
 */
const SelectDTDStep = ({ fullScreenHandle }) => {
  const objType = getState().objType
  const mainSize = getState().mainSize
  const selectedDTDType = useStore((state) => state.selectedDTDType)
  const setSelectedDTDType = getState().setSelectedDTDType
  const handleTabChange = (e, { activeIndex }) => setSelectedDTDType(!activeIndex ? 'main' : 'second')
  const actualStep = useSteps((state) => state.actualStep)
  const setShowAttributes = getState().setShowAttributes

  const onClickSelectStep = useCallback(
    /**
     * @param {Object} history
     * @param {String} step
     * @param {String} type
     */
    (history, step, type) => {
      if (type === 'next' && selectedDTDType === 'main' && objType === 'vd') {
        setSelectedDTDType('second')
      } else if (type === 'preview' && selectedDTDType === 'second' && objType === 'vd') {
        setSelectedDTDType('main')
      } else {
        setStep(step)

        if (type === 'next') {
          setShowAttributes('dtd', true)
        }

        history.push('/' + Lang(step, 'navigation'))
      }
    },
    [selectedDTDType, objType, setSelectedDTDType, setShowAttributes]
  )

  const isTypeNotSelected = useMemo(() => {
    return objType === ''
  }, [objType])

  const previewStep = PREVIEW_STEP[objType] ? PREVIEW_STEP[objType][actualStep] : ''
  const nextStepData = getSteps().getNextStep(objType ?? 'vd')
  const onClickSteps = []

  onClickSteps.preview = (history) => onClickSelectStep(history, previewStep, 'preview')
  onClickSteps.previewStep = selectedDTDType === 'second' && objType === 'vd' ? 'selectDTD' : previewStep

  onClickSteps.next = (history) => onClickSelectStep(history, nextStepData.key, 'next')
  onClickSteps.nextStep = selectedDTDType === 'main' && objType === 'vd' ? 'selectDTDSecond' : nextStepData.key

  if (isTypeNotSelected) {
    return <Redirect to={'/' + Lang(START_STEP, 'navigation')} />
  }

  return (
    <>
      <TopMenu />
      <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />
      <Card>
        <SelectDTD
          mainSize={mainSize}
          objType={objType}
          handleTabChange={handleTabChange}
          selectedDTDType={selectedDTDType}
          onClickSteps={onClickSteps}
        />
      </Card>
      <VerticalSidebar onClickSteps={onClickSteps} />
    </>
  )
}

export default SelectDTDStep
