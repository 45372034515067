import React from 'react'
import PriceCalculationTemplate from '../Components/Templates/PriceCalculation'
import { connect } from 'react-redux'
import PhoneSave from '../Modals/PhoneSave'
import { APP_SAVE_ORDER, SAVE_PHONE_EMAIL } from '../../../constants/actionTypes'
import agent from '../../../agent'
import { getSendData } from '../../../utils/api'
import Process from '../Modals/Process'
import TagManager from 'react-gtm-module'
import { getState } from '../../../store'

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = (dispatch) => ({
  savePhoneEmail: (payload, actualSendData) => dispatch({ type: SAVE_PHONE_EMAIL, payload, actualSendData }),
  onSaveOrder: (payload) => dispatch({ type: APP_SAVE_ORDER, payload })
})

const tagManagerArgs = {
  dataLayer: {
    event: 'formSend',
    type: 'objednavka'
  }
}

class PriceCalculation extends React.Component {
  _onSavePhoneEmail = () => {
    const newSendData = JSON.stringify(getSendData())
    this.props.savePhoneEmail(agent.Order.email(newSendData), newSendData)
    getState().openModal('process')
  }

  _onSubmitOrder = (saveData) => {
    TagManager.dataLayer(tagManagerArgs)

    this.props.onSaveOrder(agent.Order.submit(JSON.stringify(saveData)))
  }

  render() {
    const isPhoneSaveAvailable = process.env.REACT_APP_IS_SAVE_PHONE_AVAILABLE === 'true'
    let showSummaryItemPrice = false
    let orContactUs = ''

    if (this.props.price) {
      if (this.props.price.or_contact_us) {
        orContactUs = this.props.price.or_contact_us.replace(/<br \/>/g, '\r\n')
      }
      if (this.props.price.or_contact_us) {
        showSummaryItemPrice = this.props.price.show_summary_item_price === 'true'
      }
    }

    return (
      <>
        <PriceCalculationTemplate
          fullScreenHandle={this.props.fullScreenHandle}
          onClickSteps={this.props.onClickSteps}
          price={this.props.price}
          onSubmitOrder={this._onSubmitOrder}
          savedOrder={this.props.savedOrder}
          showSummaryItemPrice={showSummaryItemPrice}
        />
        {isPhoneSaveAvailable && <PhoneSave orContactUs={orContactUs} onSavePhoneEmail={this._onSavePhoneEmail} />}
        <Process inProgress={this.props.saveInProgress} result={this.props.savedEmail} />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceCalculation)
