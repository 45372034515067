import React from 'react'
import AccessoriesTemplate from '../Components/Templates/Accessories'
import { connect } from 'react-redux'

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = () => ({})

class Accessories extends React.Component {
  render() {
    return (
      <AccessoriesTemplate fullScreenHandle={this.props.fullScreenHandle} onClickSteps={this.props.onClickSteps} price={this.props.price} />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accessories)
