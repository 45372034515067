import React from 'react'
import 'semantic-ui-css/semantic.min.css'
import { getState } from './store'
import { connect } from 'react-redux'
import { LOAD_DATA } from './constants/actionTypes'
import agent from './agent'

function Save({ loadData }) {
  const saveSpacesCount = getState().saveSpacesCount
  const setObjType = getState().setObjType
  const setCorpusType = getState().setCorpusType

  if (loadData && loadData.data) {
    getState().setRoomHeight(loadData.data.height)
    getState().setRoomWidth(loadData.data.width)
    getState().setRoomDepth(loadData.data.depth)
    saveSpacesCount(loadData.data.spacesCount)
    setObjType(loadData.data.objType)

    for (let i = 1; i <= loadData.data.spacesCount; i++) {
      setCorpusType(i, loadData.data.selectedCorpuses[i])
    }
  }

  return <>{JSON.stringify(loadData)}</>
}

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: LOAD_DATA, payload })
})

class Load extends React.Component {
  componentDidMount() {
    this.props.onLoad(agent.Data.get())
  }

  render() {
    return <Save loadData={this.props.loadData} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Load)
