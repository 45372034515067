import * as THREE from 'three'
import React, { useState, useCallback, useMemo } from 'react'
import { BOARD_DEPTH, CORPUSES_LIST, DEFAULT_SPACE_MIN_WIDTH } from '../config'
import useStore, { getState } from '../store'
import useSteps, { getSteps } from '../Stores/Steps'
import loadable from '@loadable/component'
import { Html } from '@react-three/drei'
import { Lang } from '../Utils'
import ExtAltitude from './ExtAltitude'
import AltitudeText from '../helpers/AltitudeText'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { controlCorpusesSpaceWidth } from '../utils/corpuses'
import Input from '@material-ui/core/Input'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles({
  inputSmallContent: {
    width: 70,
    padding: 5
  }
})

/**
 * @param {Object} wardrobeAttributes
 * @param {number} id
 * @param {number} posY
 * @param {number} posX
 * @param {number} spaceWidth
 * @param {number} spaceDepth
 * @param {boolean} showAltitudes
 * @param {boolean} showBoxHelper
 * @returns {JSX.Element}
 * @constructor
 */
export const Corpuse = ({ wardrobeAttributes, id, posY, posX, spaceWidth, spaceDepth, showAltitudes, showBoxHelper = false }) => {
  const actualStep = useSteps((state) => state.actualStep)
  const selectedSpace = useStore((state) => state.selectedSpace)
  const spaceHeight = useStore((state) => state.spaceHeight)
  const corpusType = useStore((state) => state.selectedCorpuses[id])

  const CorpusesElement = useMemo(() => {
    return corpusType && spaceWidth ? loadable(() => import(`./Corpuses/Type${corpusType}`)) : false
  }, [corpusType, spaceWidth])

  return (
    <group position-y={posY} position-x={posX}>
      {corpusType && (
        <CorpusesElement
          wardrobeAttributes={wardrobeAttributes}
          showBoxHelper={showBoxHelper}
          showAltitudes={showAltitudes}
          selected={selectedSpace === id && 'selectCorpuses' === actualStep}
          spaceHeight={spaceHeight}
          spaceWidth={spaceWidth}
          spaceDepth={spaceDepth}
        />
      )}
    </group>
  )
}

/**
 * @param {number} dividedSpaceWidth
 * @param {number} defaultValue
 * @param {number} spaceId
 * @param {number} spaceSeq
 * @param {number} minValue
 * @param {number} maxValue
 * @returns {JSX.Element}
 * @constructor
 */
const AltitudeSmallSpaceInput = ({ dividedSpaceWidth, defaultValue, seq, spaceId, minValue, maxValue }) => {
  const [inputErrorValue, setInputErrorValue] = useState(null)
  const classes = useStyles()

  const onChangeValue = useCallback(
    (value) => {
      let setValue = null
      let error = ''

      if (isNaN(value)) {
        value = value.replace(',', '.') * 1
      }

      if (isNaN(value)) {
        error = Lang('WrongWidthNumberValue', 'inputs')
      } else {
        value = Math.floor(value * 10) / 10
        error = controlCorpusesSpaceWidth(value, Math.floor(minValue * 10) / 10, Math.floor(maxValue * 10) / 10)

        if (error === '') {
          setValue = value
        }
      }

      if ((setValue === null && dividedSpaceWidth < minValue) || (setValue !== null && setValue < minValue)) {
        setValue = minValue
      } else if ((setValue === null && dividedSpaceWidth > maxValue) || (setValue !== null && setValue > maxValue)) {
        setValue = maxValue
      }

      getState().setSpaceDividedWidth(seq, spaceId, setValue)
      setInputErrorValue(error)
    },
    [setInputErrorValue, seq, spaceId, minValue, maxValue, dividedSpaceWidth]
  )

  return (
    <group position-x={-25} position-y={20}>
      <Html>
        <Paper className={classes.inputSmallContent}>
          <Grid container alignItems='flex-end'>
            <Grid md={9} className={classes.rightGrid}>
              <Input
                placeholder={defaultValue}
                error={inputErrorValue !== ''}
                helperText={inputErrorValue !== '' ? Lang(inputErrorValue, 'inputs') : ''}
                id='standard-required'
                size='small'
                onChange={(e) => onChangeValue(e.target.value)}
              />
            </Grid>
            <Grid md={3} style={{ paddingBottom: 5 }}>
              cm
            </Grid>
          </Grid>
        </Paper>
      </Html>
    </group>
  )
}

/**
 * @param {Object} dividedSpacesWidth
 * @param {Object} spaceAttributes
 * @param {Object} wardrobeAttributes
 * @param {Object} roomAttributes
 * @param {Object} previewAttributes
 * @param {Object} showAltitudes
 * @param {Boolean} showBoxHelper
 * @returns {JSX.Element}
 * @constructor
 */
const Corpuses = ({
  dividedSpacesWidth,
  spaceAttributes,
  wardrobeAttributes,
  roomAttributes,
  previewAttributes,
  showAltitudes,
  showBoxHelper = false
}) => {
  const selectedSpace = useStore((state) => state.selectedSpace)
  const spacesCount = wardrobeAttributes.spacesCount
  const roomType = roomAttributes.type
  const objType = roomAttributes.objType
  const actualStep = useSteps((state) => state.actualStep)
  const dividedSpaces = JSON.parse(getState().dividedSpaces)
  const spaceHeight = spaceAttributes.height
  const spaceWidth = spaceAttributes.width
  const spaceDepth = spaceAttributes.depth
  const wardrobeDepth = wardrobeAttributes.depth
  const roomHeight = roomAttributes.height
  const selectedCorpuses = getState().selectedCorpuses

  let nextWoodStandPosition = BOARD_DEPTH

  if ((!roomType || roomType === 'c' || roomType === 'd') && objType !== 'v') {
    nextWoodStandPosition += BOARD_DEPTH
  }

  const lowerSpaceWidth = spaceWidth - BOARD_DEPTH
  const dividedSpaceWidth = lowerSpaceWidth / 2
  const Corpuses = []

  let i
  if (spacesCount > 0) {
    for (i = 1; i < spacesCount + 1; i++) {
      if (dividedSpaces[i] || spaceWidth > 100) {
        let minWidthA = DEFAULT_SPACE_MIN_WIDTH
        let minWidthB = DEFAULT_SPACE_MIN_WIDTH
        let spaceWidthA = dividedSpaceWidth
        let spaceWidthB = dividedSpaceWidth

        if (selectedCorpuses[i + 'A']) {
          const corpusTypeIdA = selectedCorpuses[i + 'A']

          if (CORPUSES_LIST[corpusTypeIdA].minWidth !== null) {
            minWidthA = CORPUSES_LIST[corpusTypeIdA].minWidth
          }
        }

        if (selectedCorpuses[i + 'B']) {
          const corpusTypeIdB = selectedCorpuses[i + 'B']

          if (CORPUSES_LIST[corpusTypeIdB].minWidth !== null) {
            minWidthB = CORPUSES_LIST[corpusTypeIdB].minWidth
          }
        }

        let maxWidthA = lowerSpaceWidth - minWidthB
        let maxWidthB = lowerSpaceWidth - minWidthA

        if (dividedSpacesWidth[i + 'A']) {
          spaceWidthA = dividedSpacesWidth[i + 'A']
          spaceWidthB = lowerSpaceWidth - spaceWidthA
        } else if (dividedSpacesWidth[i + 'B']) {
          spaceWidthB = dividedSpacesWidth[i + 'B']
          spaceWidthA = lowerSpaceWidth - spaceWidthB
        }

        Corpuses.push({
          isSelected: selectedSpace === i || selectedSpace === i + 'A',
          spaceId: i + 'A',
          seq: i,
          isDivided: true,
          spaceWidth: spaceWidthA,
          spaceDepth: spaceDepth,
          posX: nextWoodStandPosition,
          minValue: minWidthA,
          maxValue: maxWidthA
        })
        nextWoodStandPosition += spaceWidthA + BOARD_DEPTH

        Corpuses.push({
          isSelected: selectedSpace === i + 'B',
          spaceId: i + 'B',
          seq: i,
          isDivided: true,
          spaceWidth: spaceWidthB,
          spaceDepth: spaceDepth,
          posX: nextWoodStandPosition,
          minValue: minWidthB,
          maxValue: maxWidthB
        })
        nextWoodStandPosition += spaceWidthB + BOARD_DEPTH
      } else {
        Corpuses.push({
          spaceId: i,
          seq: i,
          spaceWidth: spaceWidth,
          spaceDepth: spaceDepth,
          posX: nextWoodStandPosition,
          isSelected: selectedSpace === i
        })
        nextWoodStandPosition += spaceWidth + BOARD_DEPTH
      }
    }
  }

  if ('selectCorpuses' === actualStep) {
    const extAltitudeType = previewAttributes && previewAttributes.name === 'corpuses' ? 'corpuses_space_top' : 'space_top'

    return (
      <>
        {Corpuses.map((corpus) => (
          <>
            <group
              key={'ca' + corpus.spaceId}
              position-z={wardrobeDepth / 2 - wardrobeAttributes.freeSpace}
              position-y={spaceHeight}
              position-x={corpus.posX}
            >
              <ExtAltitude spaceHeight={spaceHeight} roomHeight={roomHeight} type={extAltitudeType} value={corpus.spaceWidth}>
                {corpus.isSelected && corpus.isDivided && extAltitudeType === 'corpuses_space_top' ? (
                  <AltitudeSmallSpaceInput
                    dividedSpaceWidth={dividedSpaceWidth}
                    defaultValue={Math.floor(corpus.spaceWidth * 10) / 10}
                    seq={corpus.seq}
                    spaceId={corpus.spaceId}
                    minValue={corpus.minValue}
                    maxValue={corpus.maxValue}
                  />
                ) : (
                  <AltitudeText maxWidth={corpus.spaceWidth}>{Math.floor(corpus.spaceWidth * 10) / 10}</AltitudeText>
                )}
              </ExtAltitude>
            </group>
            <Corpuse
              wardrobeAttributes={wardrobeAttributes}
              showBoxHelper={showBoxHelper}
              showAltitudes={showAltitudes}
              spaceWidth={corpus.spaceWidth}
              spaceDepth={corpus.spaceDepth}
              key={corpus.spaceId}
              id={corpus.spaceId}
              posY={0}
              posX={corpus.posX}
            />
          </>
        ))}
        {previewAttributes.showSelected &&
          Corpuses.map((corpus) => (
            <SelectCorpuses
              wardrobeAttributes={wardrobeAttributes}
              spaceHeight={spaceHeight}
              spaceWidth={corpus.spaceWidth}
              spaceDepth={corpus.spaceDepth}
              key={corpus.spaceId}
              id={corpus.spaceId}
              posY={0}
              posX={corpus.posX}
            />
          ))}
      </>
    )
  } else {
    return (
      <>
        {Corpuses.map((corpus) => (
          <Corpuse
            wardrobeAttributes={wardrobeAttributes}
            showBoxHelper={showBoxHelper}
            showAltitudes={showAltitudes}
            spaceWidth={corpus.spaceWidth}
            spaceDepth={corpus.spaceDepth}
            key={corpus.spaceId}
            id={corpus.spaceId}
            posY={0}
            posX={corpus.posX}
          />
        ))}
      </>
    )
  }
}

/**
 * @param {Object} wardrobeAttributes
 * @param {Object} previewAttributes
 * @param {Number} spaceHeight
 * @param {Number} spaceWidth
 * @param {Number} spaceDepth
 * @param {Number} id
 * @param {Number} posY
 * @param {Number} posX
 * @returns {JSX.Element}
 * @constructor
 */
const SelectCorpuses = ({ wardrobeAttributes, spaceHeight, spaceWidth, spaceDepth, id, posY, posX }) => {
  const selectedSpace = useStore((state) => state.selectedSpace)
  const setSpace = getState().setSpace
  const setActiveTab = getSteps().setActiveTab
  const activeTab = getSteps().activeTab['selectCorpuses']
  const [isSelected, setSelected] = useState(false)

  const basicMaterial = new THREE.MeshStandardMaterial({
    color: 'green'
  })

  const basicObject = new THREE.Mesh(new THREE.BoxGeometry(spaceWidth, spaceHeight), basicMaterial)

  if (isSelected) {
    basicObject.material.opacity = 0.5
  } else {
    basicObject.material.visible = 0
  }

  const selectObject = new THREE.Mesh(new THREE.BoxGeometry(spaceWidth, spaceHeight), new THREE.MeshStandardMaterial())

  selectObject.material.visible = 0

  const onClickSelectSpace = useCallback(
    (e, id) => {
      e.stopPropagation() // stop it at the first intersection
      setSpace(id)
      setSelected(false)
      setActiveTab('selectCorpuses', '2')
    },
    [setSpace, setSelected, setActiveTab]
  )

  const width = spaceWidth + BOARD_DEPTH
  const height = spaceHeight - BOARD_DEPTH / 2 + 1

  const modelWidth1 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(width, 2, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [width])
  const modelWidth2 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(width, 2, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [width])

  const modelHeight1 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(2, height, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [height])
  const modelHeight2 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(2, height, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [height])

  if ((selectedSpace === id || selectedSpace + 'A' === id.toString()) && activeTab === '2') {
    modelWidth1.material.visible = 1
    modelWidth2.material.visible = 1
    modelHeight1.material.visible = 1
    modelHeight2.material.visible = 1
  } else {
    modelWidth1.material.visible = 0
    modelWidth2.material.visible = 0
    modelHeight1.material.visible = 0
    modelHeight2.material.visible = 0
  }

  const halfDepth = spaceDepth / 2

  return (
    <group position-y={posY + spaceHeight / 2} position-x={posX + spaceWidth / 2}>
      <group position-z={0.5 - halfDepth}>
        <primitive object={basicObject} />
      </group>
      <group
        position-z={halfDepth - 10}
        onPointerDown={(e) => onClickSelectSpace(e, id)}
        onPointerOver={() => setSelected(true)}
        onPointerOut={() => setSelected(false)}
      >
        <primitive object={selectObject} />
      </group>
      <group position-z={halfDepth - wardrobeAttributes.freeSpace} position-y={BOARD_DEPTH / 2}>
        <group position-y={height / 2}>
          <primitive object={modelWidth1} />
        </group>
        <group position-y={0 - height / 2}>
          <primitive object={modelWidth2} />
        </group>
        <group position-x={0 - width / 2}>
          <primitive object={modelHeight1} />
        </group>
        <group position-x={width / 2}>
          <primitive object={modelHeight2} />
        </group>
      </group>
    </group>
  )
}

export default Corpuses
