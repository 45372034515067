import React, { Suspense, createRef, useCallback, useMemo } from 'react'
import 'semantic-ui-css/semantic.min.css'
import Steps from './UI/Web/Steps'
import useStore, { getState } from './store'
import { connect } from 'react-redux'
import { APP_LOAD } from './constants/actionTypes'
import agent from './agent'
import Modals from './UI/Web/Modals'
//import TagManager from 'react-gtm-module'
import useSteps, { getSteps } from './Stores/Steps'
//import ReactPixel from 'react-facebook-pixel';
import Load from './Load'
import Preview from './Preview/Preview'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Header from './UI/Web/Header'
import Grid from '@material-ui/core/Grid'
import ParametersBlock from './UI/Web/ParametersBlock'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { isMobile } from 'react-device-detect'
import { Lang } from './Utils'
import { PREVIEW_STEP } from './config'
import { BrowserRouter as Router } from 'react-router-dom'
import AppLoading from './UI/Web/Modals/AppLoading'
import SetWardrobeHeights from './UI/Web/Modals/SetWardrobeHeights'
import SetDTDOut from './UI/Web/Modals/SetDTDOut'
import { setStep } from './utils/steps'

const useStyles = makeStyles((theme) => ({
  header: {},
  main: {
    backgroundColor: theme.palette.grey[50],
    backgroundImage: 'url(' + process.env.PUBLIC_URL + '/images/middle-background.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center'
  },
  mainMobile: {
    backgroundColor: theme.palette.grey[50],
    marginTop: 50
  },
  preview: {
    backgroundColor: theme.palette.common.white
  },
  previewMainBox: {
    display: 'relative',
    height: 450
  },
  previewMainBoxMobile: {
    display: 'relative',
    height: 300
  },
  previewBox: {
    flexGrow: 1
  },
  hud: {
    backgroundColor: 'red'
  },
  iconsBox: {
    position: 'absolute',
    padding: isMobile ? 30 : 5
  },
  imageRotate: {
    width: isMobile ? 40 : 70
  }
}))

function AppContent({ vr, ar, fp }) {
  const handle = useFullScreenHandle()
  const classes = useStyles()
  const contextRef = createRef()
  const showOnlyPreview = false
  const actualStep = useSteps((state) => state.actualStep)
  const objType = useStore((state) => state.objType)
  const activeTabDoorSpacesCount = useSteps((state) => state.activeTab['selectDoorSpacesCount'])
  const activeTabSelectCorpuses = useSteps((state) => state.activeTab['selectCorpuses'])
  const setSpace = getState().setSpace
  const openModal = getSteps().openModal
  const isDTDOutDisabled = useStore((state) => state.isDTDOutDisabled)

  const onClickSelectStep = useCallback(
    (history, step) => {
      if (step !== actualStep && (step === 'selectProfiles' || step === 'selectCorpuses' || step === 'selectDoorsFill')) {
        setSpace(1)
      }

      if (step === 'priceCalculation' && step === process.env.REACT_APP_LAST_STEP) {
        openModal('attributes')
      } else {
        setStep(step)

        history.push('/' + Lang(step, 'navigation'))
      }
    },
    [actualStep, setSpace, openModal]
  )

  let previewStep = ''
  let nextStep = ''
  let isPreviewDisabled = true
  let isNextDisabled = true
  let nextStepData = false

  if (actualStep !== '') {
    previewStep = PREVIEW_STEP[objType] ? PREVIEW_STEP[objType][actualStep] : ''
    isPreviewDisabled = previewStep !== '' ? false : true

    nextStepData = getSteps().getNextStep(objType ?? 'vd')

    if (nextStepData) {
      nextStep = nextStepData.key
      isNextDisabled = nextStepData.disabled
    }
  }

  previewStep = useMemo(() => {
    previewStep = PREVIEW_STEP[objType] ? PREVIEW_STEP[objType][actualStep] : ''

    if (previewStep === 'selectDTDOut' && objType === 'd' && isDTDOutDisabled) {
      previewStep = 'selectDoorsFill'
    }

    return previewStep
  }, [actualStep, objType, isDTDOutDisabled])

  nextStep = useMemo(() => {
    if (nextStep === 'selectDTDOut' && objType === 'd' && isDTDOutDisabled) {
      nextStep = 'priceCalculation'
    }

    return nextStep
  }, [nextStep, objType, isDTDOutDisabled])

  const onClickSteps = []

  if (!isPreviewDisabled) {
    onClickSteps.preview = (history) => onClickSelectStep(history, previewStep)
    onClickSteps.previewStep = previewStep
  }

  if (!isNextDisabled) {
    onClickSteps.next = (history) => onClickSelectStep(history, nextStep)
    onClickSteps.nextStep = nextStep
  }

  if (vr || ar) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Preview vr={vr} ar={ar} fp={fp} />
        <Load />
      </Suspense>
    )
  } else {
    let showOnlyParametersBlock =
      isMobile &&
      ((actualStep === 'selectDoorSpacesCount' && activeTabDoorSpacesCount === '2') ||
        actualStep === 'selectDoorsFill' ||
        (actualStep === 'selectCorpuses' && activeTabSelectCorpuses === '2'))

    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Router>
          <CssBaseline />
          <Header contextRef={contextRef} onClickSteps={onClickSteps} />
          <Box className={isMobile ? classes.mainMobile : classes.main} ref={contextRef}>
            <Container maxWidth='lg'>
              {showOnlyPreview && <Preview vr={false} ar={false} fp={fp} />}
              {!showOnlyPreview && (
                <>
                  {showOnlyParametersBlock && (
                    <>
                      <ParametersBlock contextRef={contextRef} actualStep={actualStep} />
                      <Steps onClickSteps={onClickSteps} fullScreenHandle={handle} hideMiddleMenu={true} />
                    </>
                  )}
                  {!showOnlyParametersBlock && (
                    <Grid container direction='row-reverse'>
                      <Grid md={3} className={classes.rightGrid}>
                        <ParametersBlock contextRef={contextRef} actualStep={actualStep} />
                      </Grid>
                      <Grid md={9} className={classes.leftGrid}>
                        <>
                          {objType && (
                            <Box className={isMobile ? classes.previewMainBoxMobile : classes.previewMainBox}>
                              <Box className={classes.iconsBox} zIndex='tooltip'>
                                <img src={process.env.PUBLIC_URL + '/images/icons/3d-rotate-icon.png'} className={classes.imageRotate} />
                              </Box>
                              <Box className={classes.previewBox}>
                                <FullScreen handle={handle}>
                                  <Preview vr={false} ar={false} fp={fp} fullScreenHandle={handle} />
                                </FullScreen>
                              </Box>
                            </Box>
                          )}
                          <Steps onClickSteps={onClickSteps} fullScreenHandle={handle} hideMiddleMenu={false} />
                        </>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
              <Modals />
            </Container>
          </Box>
          <SetWardrobeHeights />
          <SetDTDOut />
        </Router>
      </Suspense>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: APP_LOAD, payload })
})

class App extends React.Component {
  state = {
    objId: '',
    APIUUId: '',
    vr: false,
    ar: false,
    fp: false
  }

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search)

    this.setState({ objUUId: searchParams.get('obj') })

    if (searchParams.get('vr')) {
      this.setState({ vr: searchParams.get('vr') })
    }

    if (searchParams.get('ar')) {
      this.setState({ ar: searchParams.get('ar') })
    }

    if (searchParams.get('fp')) {
      this.setState({ fp: searchParams.get('fp') })
    }

    if (!this.state.vr && !this.state.vr && !this.state.objUUId) {
      getState().setDTDImage('main', 3299710, '3299710_size200x200.jpg')
      getState().setDTDImage('second', 3299710, '3299710_size200x200.jpg')
    }

    /*
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
            autoConfig: true, // set pixel's autoConfig
            debug: false, // enable logs
        };
        ReactPixel.init('279606939587990', advancedMatching, options);
        ReactPixel.pageView();
        */

    this.props.onLoad(
      Promise.all([
        agent.DTD.get(),
        agent.DTD.getImages(),
        agent.Corpuses.getVariants(),
        agent.Doors.getVariants(),
        agent.Doors.getFills(),
        agent.Config.get()
      ])
    )
  }

  render() {
    return (
      <>
        <AppContent objUUId={this.state.objUUId} vr={this.state.vr} ar={this.state.ar} fp={this.state.fp} />
        <AppLoading />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
