import { useEffect, useMemo, useState } from 'react'
import useStore from '../store'
import { getType as getRoomType } from './room'
import { isTranslated } from '../Languages'
import useSteps from '../Stores/Steps'

const useInfo = () => {
  const inputFocused = useStore((state) => state.inputFocused)
  const actualStep = useSteps((state) => state.actualStep)
  const objType = useStore((state) => state.objType)
  const selectedDTDType = useStore((state) => state.selectedDTDType)
  const selectedRoomType = useStore((state) => state.roomType)
  const [data, setData] = useState([])

  const roomType = useMemo(() => {
    return getRoomType(selectedRoomType, objType)
  }, [selectedRoomType, objType])

  useEffect(() => {
    let infoTitleLabelKey = ''
    let infoContentLabelKey = ''
    const infoBlocks = []

    if (actualStep === 'setWardrobeParameters') {
      switch (inputFocused) {
        case 'Width':
        case 'Depth':
          infoTitleLabelKey = actualStep + inputFocused + 'Title'
          infoContentLabelKey = actualStep + inputFocused + 'Content' + objType.toUpperCase()
          break
        case 'Height':
        case 'LeftFrontHeight':
        case 'LeftBackHeight':
        case 'RightFrontHeight':
        case 'RightBackHeight':
          infoTitleLabelKey = actualStep + 'HeightTitle'
          infoContentLabelKey = actualStep + 'HeightContent' + objType.toUpperCase()
          break
        default:
          infoTitleLabelKey = actualStep + 'Title'
          infoContentLabelKey = actualStep + 'Content' + objType.toUpperCase()
          break
      }
    } else if (actualStep === 'selectDTD') {
      const key = selectedDTDType + 'SelectDTD' + objType.toUpperCase()
      infoTitleLabelKey = key + 'Title'

      if ((objType === 'vd' && selectedDTDType === 'second') || (objType === 'd' && selectedDTDType === 'main'))
        infoTitleLabelKey += roomType.toUpperCase()

      infoContentLabelKey = key + 'Content'
    } else if (actualStep === 'selectCorpusesSetSpacesCount') {
      infoTitleLabelKey = actualStep + 'Title'
      infoContentLabelKey = actualStep + 'Content'

      if (objType === 'v') {
        infoTitleLabelKey += objType.toUpperCase()
        infoContentLabelKey += objType.toUpperCase()
      }
    } else if (actualStep === 'selectCorpuses' || actualStep === 'selectDTDIn' || actualStep === 'selectDTDOut') {
      infoTitleLabelKey = actualStep + 'Title'
      infoContentLabelKey = actualStep + 'Content'
    } else if (actualStep === 'accessories' || actualStep === 'priceCalculation' || actualStep === 'order') {
      if (isTranslated('basicInformationTitle', 'info') || isTranslated('basicInformationContent', 'info')) {
        infoBlocks.push({
          title: 'basicInformationTitle',
          content: 'basicInformationContent'
        })
      }
      if (isTranslated('setOrderTitle', 'info') || isTranslated('setOrderContent', 'info')) {
        infoBlocks.push({ title: 'setOrderTitle', content: 'setOrderContent' })
      }

      infoTitleLabelKey = 'deliveryAndDispatchTitle'
      infoContentLabelKey = 'deliveryAndDispatchContent'
    } else {
      infoTitleLabelKey = actualStep + 'Title'
      infoContentLabelKey = actualStep + 'Content' + objType.toUpperCase()
    }

    if (isTranslated(infoTitleLabelKey, 'info') || isTranslated(infoContentLabelKey, 'info')) {
      infoBlocks.push({ title: infoTitleLabelKey, content: infoContentLabelKey })
    }

    setData(infoBlocks)
  }, [inputFocused, objType, actualStep, roomType, setData, selectedDTDType])

  return data
}

export default useInfo
