import React from 'react'
import { BOARD_DEPTH } from '../../config'
import Board from './Board'

/**
 * @param {Object} wardrobeAttributes
 * @param {Object} roomAttributes
 * @param {Number} posX
 * @param {String} type
 * @param {Boolean} showBoxHelper
 * @returns {JSX.Element}
 * @constructor
 */
const SideWoodStand = ({ wardrobeAttributes, roomAttributes, posX, type, showBoxHelper = false }) => {
  const roomType = roomAttributes.type
  const boardHeight = wardrobeAttributes.height
  const wardrobeDepth = wardrobeAttributes.depth

  let boardWidth = wardrobeDepth

  let posZ = 0
  const boardDepth = BOARD_DEPTH

  if (((roomType === 'a' || roomType === 'b') && type === 'left') || ((roomType === 'a' || roomType === 'c') && type === 'right')) {
    boardWidth = wardrobeAttributes.freeSpace
    posZ += wardrobeDepth / 2 - wardrobeAttributes.freeSpace / 2
  }

  return (
    <group position-y={0} position-x={posX} position-z={posZ}>
      <Board showBoxHelper={showBoxHelper} type='vertical' width={boardWidth} height={boardHeight} depth={boardDepth} dtdType='second' />
    </group>
  )
}

export default SideWoodStand
