import { PROFILES_LANG } from './components/profiles'
import { INFO_LANG } from './components/info'
import { NAVIGATION_LANG } from './components/navigation'
import { INPUTS_LANG } from './components/inputs'
import { BUTTONS_LANG } from './components/buttons'
import { PRODUCTS_LANG } from './components/products'
import { ACCESSORIES_LANG } from './components/accessories'
import { TABS_LANG } from './components/tabs'

export const LANG = {
  roomTypeDescription:
    'Im ersten Schritt der Erstellung Ihres Einbauschranks klicken Sie auf das Bild, um die Art des Grundrisses (Typ A, B, C oder D) auszuwählen, je nachdem, wo Sie Ihren Einbauschrank platzieren möchten.',
  roomTitle: 'Parameter-Eingabe',
  selectCorpusesSetSpacesCountTitle: 'Anzahl der Segmente',
  selectCorpusesSetSpacesCountTitleDescription:
    'Wählen Sie die Anzahl der Fächer, die Ihr Einbauschrank haben soll. Das System errechnet automatisch die optimale Anzahl von Türen zur Anzahl der Segmente entsprechend den von Ihnen eingegebenen Abmessungen, aber Sie haben die Möglichkeit, diese Anzahl zu verringern oder zu erhöhen. Die optimale Größe der Fächer beträgt etwa 80 cm. Ein Segment von mehr als 100 cm wird durch die Möglichkeit, die Regale zu klappen, in zwei Hälften geteilt. Nachdem Sie alle Angaben gemacht haben, fahren Sie bitte fort, indem Sie auf die Schaltfläche "Weiter" klicken.',
  BasicSettingsTitle: 'Grundeinstellungen',
  selectDoorsSetSpacesCountTitle: 'Anzahl der Räume',
  selectCorpusesTitle: 'Innenfaltung',
  selectCorpusesModalTitle: 'Korpusauswahl',
  selectCorpusesDescription: 'Innenansicht',
  selectCorpusesVariantsTitle: 'Aus vordefinierten Körpern auswählen',
  SelectCorpusTooltipTitle: 'Zum Ansehen klicken',
  SelectCorpusTooltipText: ' Liste zur Auswahl eines Korpus in einem bestimmten Raum',
  selectDoorsVariantsTitle: 'Wählen Sie aus vordefinierten Türkombinationen',
  selectDoorSpacesCountTitle: 'Auswahl der Türtypen',
  selectDoorsTitle: 'Auswahl des Türtyps',
  selectDoorsDescription:
    'In diesem Schritt wählen Sie die Art bzw. die Teilung der Schiebetür im ausgewählten Segment. Sie haben die Wahl zwischen Volltüren und geteilten Türen (halbiert, gedrittelt, auch in kleineren Teilen).Der Bereich, für den der Typ derzeit definiert ist, ist grün markiert.',
  roomTypeTitle: 'Auswahl des Grundrisstyps',
  Attributes: 'Parameter',
  Corpuses: 'Innen',
  Doors: 'Die Tür',
  Order: 'In den Warenkorb',
  SubmitOrder: 'Bestellen',
  Next: 'Weiter',
  Preview: 'Zurück',
  NextStep: 'Weiter',
  PreviewStep: 'Zurück',
  SaveOrder: 'Speichern',
  Close: 'Schließen',
  Type: 'Typ',
  TypeA: 'Typ A',
  TypeB: 'Typ B',
  TypeC: 'Typ C',
  TypeD: 'Typ D',
  LongHanging: 'Lange Aufhängung',
  ShortHanging: 'Kurze Aufhängung',
  CombHanging: 'Kombinierte Aufhängung',
  Shoes: 'Schuhe',
  Shelf: 'Regal',
  Drawers: 'Schubladen',
  Glasses: 'Gläser',
  Mirrors: 'Spiegel',
  profiles: PROFILES_LANG,
  info: INFO_LANG,
  navigation: NAVIGATION_LANG,
  inputs: INPUTS_LANG,
  buttons: BUTTONS_LANG,
  products: PRODUCTS_LANG,
  accessories: ACCESSORIES_LANG,
  tabs: TABS_LANG,
  egeer_standard: 'Egeer standard',
  egeer_exclusive: 'Egeer exclusive',
  HideDoor: 'Tür ausblenden',
  ShowDoor: 'Tür anzeigen',
  Width: 'Breite',
  Height: 'Höhe',
  Depth: 'Tiefe',
  LeftHeight: 'Höhe links',
  RightHeight: 'Höhe rechts',
  LeftBackHeight: 'Höhe links hinten',
  LeftFrontHeight: 'Höhe links vorn',
  RightBackHeight: 'Höhe rechts hinten',
  RightFrontHeight: 'Höhe rechts vorn',
  HideDimensions: 'Abmessungen ausblenden',
  ShowDimensions: 'Abmessungen anzeigen',
  SiteTitle: 'Konfigurator für Schiebetüren',
  SelectTypeInfo: 'Konfigurator für Schiebetüren',
  TypeVDName: 'Einbauschrank komplett',
  TypeDName: 'Frontschiebesystem separat',
  TypeVName: 'Innenraum des Einbauschranks separat',
  BackOnHomeSite: 'Zurück zur Startseite',
  SelectSpacesCountTitle: 'Auswahl der Anzahl der Leerzeichen',
  SelectDoorsCountTitle: 'Wählen Sie die Anzahl der Türen',
  CorpusesVariantsTabName: 'Vordefiniert',
  ButtonShowCorpusVariantsModal: 'Liste der vordefinierten Varianten anzeigen',
  SelectVariantsDoorsTabName: 'Vordefiniert',
  SelectOwnDoorsTabName: 'Benutzerdefiniert',
  SelectCorpusesTitle: 'Innenansicht',
  SelectDTDTitle: 'Holz-Dekor auswählen',
  DTDMainTabName: 'Innen',
  DTDSecondTabName: 'Seitenwände',
  SelectDoorSpacesCountTitle: 'Auswahl des Türtyps',
  SelectProfilesTitle: 'Profilart und Farbe auswählen',
  SelectDoorsFillTitle: 'Auswahl der Türfüllungen',
  DoorFillsAreEmptyError: '{0}. die Tür hat nicht die angegebene {1} Füllung',
  DoorFillsAreEmptyErrorAnd: '{0} a {1}',
  RoomTypeTitle: 'Auswahl des Grundrisstyps',
  DivideOn: 'Den Raum aufteilen',
  DivideOff: 'Den Raum verbinden',
  OrderForm: 'Bestellformular',
  FirstName: 'Name',
  LastName: 'Nachname',
  Description: 'Anmerkung',
  Phone: 'Telefon',
  Email: 'E-Mail',
  Street: 'Straße',
  City: 'Ort',
  ZIP: 'PLZ',
  BillAddress: 'Rechnungsadresse',
  DeliveryAddress: 'Lieferadresse',
  isOrgOrder: 'Ich kaufe für das Unternehmen',
  Organization: 'Firma',
  ICO: 'Ident.-Nr.',
  DIC: 'Steuer-Nr.',
  'IC DPH': 'MwSt-Ident.-Nr.',
  TermsAndConditions: 'Ich habe die Allgemeinen Geschäftsbedingungen gelesen und stimme ihnen zu',
  OrderInfo:
    'Um Ihre Bestellung abzuschließen und eine verbindliche Bestellung abzugeben, klicken Sie bitte auf die Schaltfläche Bestellen',
  SavePhoneInfo:
    'Wenn Sie von einem unserer Fachberater kontaktiert werden möchten, hinterlassen Sie bitte Ihre telefonischen Kontaktdaten',
  SaveEmailInfo: 'Wenn Sie Ihr Angebot speichern möchten, geben Sie bitte Ihre E-Mail-Adresse ein.',
  SelectCorpuse: 'Korpus auswählen',
  ChangeCorpuse: 'Korpus ändern',
  CorpusesSpaceIsEmptyError: '{0}. das Innere ist leer',
  AllSpacesMustHaveCorpusesInfo: 'Um fortzufahren, muss für alle Räume das Innere ausgewählt werden',
  DivideSpaceOn: 'Den Raum teilen',
  DivideSpaceOff: 'Den Raum verbinden',
  DivideButtonTooltipTitle: 'Klicken Sie, um den Raum zu teilen',
  DivideButtonTooltipText: 'in zwei kleinere Einheiten, für die Sie einen Korpus auswählen',
  ChangeDoorType: 'Typ ändern',
  SelectDoorType: 'Typ auswählen',
  Icon3DRotationTooltipTitle: 'Drehung der Ansicht',
  Icon3DRotationText: 'Halten Sie die linke Maustaste gedrückt, um den Blickwinkel zu ändern',
  EditInputLabelwidth: 'Breite',
  EditInputLabelheight: 'Höhe',
  EditInputLabeldepth: 'Tiefe',
  BackDoor: 'Zurück',
  NextDoor: 'Weiter',
  EditDoorSpacesHeight: 'Abmessungen der Füllungen anpassen',
  BadMinValueFillHeight: 'Die Mindesthöhe der Füllung beträgt 10 cm',
  ThisHeightIsAutomaticCalculated: 'Die Höhe wird auf der Grundlage der angegebenen Parameter berechnet',
  TotalHeightEditError: 'Die angegebene Höhe darf die Gesamthöhe der Tür nicht überschreiten',
  ActivateFullScreen: 'Auf Vollbild zoomen',
  BadHeightValuesInfo: 'Der Höhenunterschied darf nicht größer als {0} cm sein, geben Sie das kleinste gemessene Maß ein',
  AttributeTitleDTDMain: 'Holz-Dekor',
  AttributeTitleABSMain: 'ABS-Kante',
  AttributeTitleDTDSecondA: 'Holzdekor der Anschlagleiste',
  AttributeTitleABSSecondA: 'ABS-Kante der Anschlagleiste',
  AttributeTitleDTDSecondB: 'Holz-Dekor an der Anschlagleiste und dem Seitenteil',
  AttributeTitleABSSecondB: 'ABS-Kante der Anschlagleiste und des Seitenteils',
  AttributeTitleDTDSecondC: 'Holzdekor des Seitenteils und der Anschlagleiste',
  AttributeTitleABSSecondC: 'ABS-Kante Seitenteil und Anschlagleiste',
  AttributeTitleDTDSecondD: 'Seitenwände mit Holz-Dekor',
  AttributeTitleABSSecondD: 'ABS-Kante Seitenteil',
  Profile: 'Profil',
  white: 'Weiß',
  sampan: 'Champagner',
  silver: 'Silber',
  gold: 'Gold',
  silverMinimax: 'Minimax silber',
  AllAttributesMustHaveBeenCorrectInfo: 'Alle Abmessungen müssen korrekt sein, um fortzufahren',
  WallColorBtnLabel: 'Die Farbe der Wände',
  SpaceWidthDoorInfo: 'Die Breite eines Raumes:  {0} cm',
  WidthDoorInfo: 'Breite einer Tür: ca. {0} cm',
  Visible: 'Sichtbar',
  Dimensions: 'Maße',
  SearchDTD: 'Suche Holzdekor',
  SearchDoorFill: 'Suche Füllung',
  SpacesCountIsEmptyErrorV: 'Um fortzufahren, müssen Sie die Anzahl der Leerzeichen auswählen',
  SpacesCountIsEmptyError: 'Sie müssen die Anzahl der Türen auswählen, um fortzufahren',
  Accessories: 'Zubehör',
  WardrobePriceWithoutAccessories: ' Preis eines Einbauschranks ohne Zubehör',
  PriceCalculation: 'Preisberechnung',
  CalculationItemNameVD: 'Einbauschrank',
  CalculationItemNameD: 'Frontschiebesystem',
  CalculationItemNameV: 'Das Innere des Einbauschranks',
  Delivery: 'Transport',
  Packing: 'Verpackung',
  TotalPrice: 'Gesamtpreis',
  Skip: 'Überspringen',
  Save: 'Speichern',
  selectDTDInTitle: 'Wahl der Innenfarbe',
  selectDTDOutTitle: 'Farbauswahl für die Anschlagleisten',
  selectDTDIn: 'Wahl der Innenfarbe',
  selectDTDOut: 'Farbauswahl für die Anschlagleisten',
  TotalPriceWithVat: 'Gesamtpreis inkl. MwSt. ({0}%)',
  OrderWasSuccessfullyCreated: 'Auftrag erfolgreich erstellt',
  OrderWasSuccessfullySaved: 'Ihre Bestellung wurde erfolgreich gespeichert',
  OrderWasNotSuccessfullySaved: 'Ihre Kalkulation konnte nicht gespeichert werden',
  StopBar: 'Anschlagleiste',
  Yes: 'Ja',
  No: 'Nein'
}
