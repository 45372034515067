import { combineReducers } from 'redux'
import { ADD_TODO, TOGGLE_TODO, SET_VISIBILITY_FILTER, VisibilityFilters } from './actions'
import { APP_LOAD, LOAD_PRICE, LOAD_DATA, APP_SAVE_ORDER, APP_LOAD_ORDER, SAVE_PHONE_EMAIL, ASYNC_START } from './constants/actionTypes'

const { SHOW_ALL } = VisibilityFilters

function visibilityFilter(state = SHOW_ALL, action) {
  switch (action.type) {
    case SET_VISIBILITY_FILTER:
      return action.filter
    default:
      return state
  }
}

const defaultState = {
  price: {},
  loadData: {},
  inProgress: false,
  saveInProgress: false,
  success: false,
  dtdProducts: {},
  savedOrder: null,
  loadedOrder: null,
  isAppLoaded: true,
  isPriceLoaded: true
}

function todos(state = defaultState, action) {
  switch (action.type) {
    case ASYNC_START:
      if (action.subtype === 'APP_LOAD') {
        return {
          ...state,
          isAppLoaded: false
        }
      } else if (action.subtype === 'LOAD_PRICE') {
        return {
          ...state,
          isPriceLoaded: false
        }
      } else if (action.subtype === 'SAVE_PHONE_EMAIL') {
        return {
          ...state,
          saveInProgress: true
        }
      } else {
        return state
      }
    case SAVE_PHONE_EMAIL:
      return {
        ...state,
        saveInProgress: false,
        savedSuccess: action.error ? false : true,
        savedEmail: action.error ? null : action.payload
      }
    case APP_LOAD_ORDER:
      return {
        ...state,
        loadedOrder: action.error ? null : action.payload
      }
    case APP_SAVE_ORDER:
      return {
        ...state,
        savedOrder: action.error ? null : action.payload
      }
    case LOAD_PRICE:
      return {
        ...state,
        actualSendData: action.actualSendData,
        price: action.error ? null : action.payload,
        isPriceLoaded: true
      }
    case LOAD_DATA:
      return {
        ...state,
        loadData: action.error ? null : action.payload
      }
    case APP_LOAD:
      return {
        ...state,
        products: action.error ? null : action.payload[0],
        productImages: action.error ? null : action.payload[1],
        corpusesVariants: action.error ? null : action.payload[2],
        doorsVariants: action.error ? null : action.payload[3],
        doorsFillsProducts: action.error ? null : action.payload[4],
        config: action.error ? null : action.payload[5],
        isAppLoaded: true
      }
    case ADD_TODO:
      return [
        ...state,
        {
          text: action.text,
          completed: false
        }
      ]
    case TOGGLE_TODO:
      return state.map((todo, index) => {
        if (index === action.index) {
          return Object.assign({}, todo, {
            completed: !todo.completed
          })
        }
        return todo
      })
    default:
      return state
  }
}

const todoApp = combineReducers({
  visibilityFilter,
  todos
})

export default todoApp
