import React, { useCallback, useState } from 'react'
import { CORPUSES_CATEGORIES, CORPUSES_CATEGORIES_LIST, CORPUSES_LIST } from '../../../../config'
import { getState } from '../../../../store'
import { getImage as getCorpuseImage } from '../../../../utils/corpuses'
import CheckIcon from '@material-ui/icons/Check'
import { Badge, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    cursor: 'pointer',
    display: 'inline-block'
  },
  rootDisabled: {
    backgroundColor: 'silver',
    padding: '0.5rem 1rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    opacity: '0.3'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
})

/**
 * @param corpus
 * @param isDisabled
 * @param actualCorpusesSpaceId
 * @returns {JSX.Element}
 * @constructor
 */
export const PreviewImage = ({ corpus, isDisabled, actualCorpusesSpaceId }) => {
  const classes = useStyles()
  const [isShown, setIsShown] = useState(false)
  const setCorpusType = getState().setCorpusType
  const setPriceVersion = getState().setPriceVersion
  const priceVersion = getState().priceVersion
  const corpusType = getState().selectedCorpuses[actualCorpusesSpaceId]

  const onClickSelectCorpuses = useCallback(
    (e) => {
      e.stopPropagation() // stop it at the first intersection

      if (!isDisabled) {
        setCorpusType(actualCorpusesSpaceId, corpus.id)
        setPriceVersion()
      }
    },
    [setCorpusType, actualCorpusesSpaceId, corpus, isDisabled, setPriceVersion]
  )

  return (
    <Badge
      key={priceVersion}
      invisible={!(corpusType && corpusType === corpus.id)}
      color='primary'
      overlap='circle'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      badgeContent={<CheckIcon fontSize='small' />}
    >
      <Paper
        className={isDisabled ? classes.rootDisabled : classes.root}
        onClick={(e) => onClickSelectCorpuses(e)}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <img src={isShown && !isDisabled ? corpus.image3 : corpus.image1} align='bottom' alt={corpus.name} className='ui' />
        <img src={corpus.image2} align='bottom' alt={corpus.name} className='ui' />
      </Paper>
    </Badge>
  )
}

class CorpusesMenu extends React.Component {
  state = {
    corpuses: {},
    test: 0
  }

  componentDidMount() {
    const self = this
    const corpuses = []

    CORPUSES_CATEGORIES.forEach(function (category) {
      corpuses[category] = []

      CORPUSES_CATEGORIES_LIST[category].forEach(function (corpus) {
        const image = new Image()
        const urlImage1 = getCorpuseImage(self.props.spaceHeight, corpus.id, 'k1')
        const urlImage2 = getCorpuseImage(self.props.spaceHeight, corpus.id, 'k2')
        const urlImage3 = getCorpuseImage(self.props.spaceHeight, corpus.id, '')
        image.src = urlImage1

        corpuses[category].push({
          id: corpus.id,
          minWidth: CORPUSES_LIST[corpus.id].minWidth ?? null,
          minHeight: CORPUSES_LIST[corpus.id].minHeight ?? null,
          image1: urlImage1,
          image2: urlImage2,
          image3: urlImage3
        })
      })
    })

    this.setState({ corpuses: corpuses })
  }

  render() {
    /*
    if (this.state.corpuses && this.props.category &&
      this.state.corpuses[this.props.category]) {
      return (
        {
          this.state.corpuses[this.props.category].map((corpus, index) => {
            if (corpus.minHeight === null || this.props.spaceHeight >=
              corpus.minHeight) {
              return (
                <>
                  <PreviewImage
                    key={'CorpusesPreviewImage' + index}
                    isDisabled={corpus.minWidth && this.props.spaceWidth <
                    corpus.minWidth}
                    corpus={corpus}
                    i={index}
                    actualCorpusesSpaceId={this.props.actualCorpusesSpaceId}
                  />
                </>
              )
            } else {
              return <></>
            }
          }),
        }
    </>
    )
    } else {
      return <></>
    }
*/

    return <></>
  }
}

export default CorpusesMenu
