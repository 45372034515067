import React from 'react'
import { Lang } from '../../../Utils'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { makeStyles } from '@material-ui/core/styles'
import { getState } from '../../../store'

const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: theme.palette.background.paper
  }
}))

const SelectSingleDTDType = () => {
  const classes = useStyles()
  const selectedDTDType = getState().selectedDTDType
  const setSelectedDTDType = getState().setSelectedDTDType

  const handleChange = (event, newValue) => {
    setSelectedDTDType(newValue)
  }

  return (
    <Tabs textColor='primary' value={selectedDTDType} onChange={handleChange} className={classes.tabs}>
      <Tab
        value='main'
        label={Lang('DTDMainTabName')}
        classes={{
          root: classes.tab,
          selected: classes.tabSelected
        }}
      />
      <Tab
        value='second'
        label={Lang('DTDSecondTabName')}
        classes={{
          root: classes.tab,
          selected: classes.tabSelected
        }}
      />
    </Tabs>
  )
}

export default SelectSingleDTDType
