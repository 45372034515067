import React from 'react'
import { connect } from 'react-redux'
import { APP_LOAD_ORDER } from '../../../../constants/actionTypes'
import agent from '../../../../agent'
import { getState } from '../../../../store'
import { CircularProgress, Modal } from '@material-ui/core'
import { Lang } from '../../../../Utils'
import { getOrder } from '../../../../Stores/Order'
import { v4 as uuid } from 'uuid'
import { setStep } from '../../../../utils/steps'

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = (dispatch) => ({
  onLoadOrder: (payload) => dispatch({ type: APP_LOAD_ORDER, payload })
})

class LoadOrder extends React.Component {
  state = {
    loaded: null
  }

  componentDidMount() {
    this.props.onLoadOrder(agent.Order.load(this.props.objId, this.props.APIUUId))
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loaded !== this.props.objId && this.props.loadedOrder && this.props.productImages) {
      if (this.props.loadedOrder.data) {
        const loadData = this.props.loadedOrder.data

        if (typeof loadData !== 'undefined') {
          getOrder().setUUId(uuid())
          getState().setRoomHeight(loadData.height)
          getState().setRoomWidth(loadData.width)
          getState().setRoomDepth(loadData.depth)
          getState().saveSpacesCount(loadData.spacesCount)
          getState().setObjType(loadData.objType)
          getState().setRoomType(loadData.roomType)

          for (let i = 1; i <= loadData.spacesCount; i++) {
            getState().setCorpusType(i, loadData.selectedCorpuses[i])
          }

          getState().setDividedSpacesData(loadData.dividedSpaces)
          getState().setDividedSpacesWidthData(loadData.dividedSpacesWidth)

          if (loadData.selectedProfile) {
            getState().setSelectedProfileData(loadData.selectedProfile)
          }

          getState().setSelectedProfileIdData(loadData.selectedProfileId)
          getState().setDoorFillsAttributesData(loadData.doorFillsAttributes)

          getState().setSelectedDoorsSpaceData(loadData.doorsSpace)
          getState().setSelectedDoorsSpaceAttributesData(loadData.selectedDoorsSpaceAttributes)

          if (loadData.mainDTDId && this.props.productImages && this.props.productImages.large) {
            getState().setDTDImage('main', loadData.mainDTDId, this.props.productImages.large[loadData.mainDTDId])
          }

          if (loadData.secondDTDId && this.props.productImages && this.props.productImages.large) {
            getState().setDTDImage('second', loadData.secondDTDId, this.props.productImages.large[loadData.secondDTDId])
          }

          getState().setDoorFillsIdsData(loadData.doorFillsIds)

          Object.values(loadData.doorFillsData).map((data) => {
            getState().setDoorFillForId(data.id, data.type, data.productId, this.props.productImages.large[data.productId])
          })

          getState().setSelectedCorpusesData(loadData.selectedCorpuses)
          getState().setParametersData(loadData.parameters)

          this.setState({ loaded: this.props.objId })

          setStep('setWardrobeParameters')
          this.props.history.push('/' + Lang('setWardrobeParameters', 'navigation'))
        }
      }
    }
  }

  render() {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={this.props.classes.modal}
        open={true}
        closeAfterTransition
        disableEnforceFocus
        disableBackdropClick
      >
        <>
          <div>
            <CircularProgress />
          </div>
        </>
      </Modal>
    )
  }

  /*
    render() {
        return (
            <>--{this.props.loadedOrder && this.props.loadedOrder.data.mainDTDId}--
                loadedOrder: {JSON.stringify(this.props.loadedOrder)}

                loadedOrder: {this.props.productImages && JSON.stringify(this.props.productImages.large[this.props.loadedOrder.data.mainDTDId])}
            </>
        );
    }*/
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadOrder)
