import React from 'react'
import { Sidebar, Menu, Segment } from 'semantic-ui-react'
import useSteps from '../../../../Stores/Steps'
import BottomMenu from '../../Menu/BottomMenu'
import { isMobile } from 'react-device-detect'

/**
 * @returns {*}
 * @constructor
 */
const VerticalSidebar = ({ onClickSteps, children }) => {
  const actualStep = useSteps((state) => state.actualStep)
  let className = 'testSidebarClass ' + actualStep

  if (isMobile) {
    return (
      <>
        <Sidebar as={Menu} fluid={true} direction='bottom' vertical={false} visible={true} className={className}>
          <Segment.Group className='testSegmentGroupClass'>
            {children}
            <BottomMenu onClickSteps={onClickSteps} />
          </Segment.Group>
        </Sidebar>
      </>
    )
  } else {
    return <></>
  }
}

export default VerticalSidebar
