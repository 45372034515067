import create from 'zustand'
import { NEXT_STEP } from '../config'
import { getState } from '../store'
import { START_STEP } from '../config'

const store = create((set, get) => {
  return {
    actualStep: START_STEP,
    isAnyModalOpen: false,
    isModalOpen: { selectCorpuses: false, selectCorpusVariants: false },
    openCorpusVariants: true,
    isDev: false,
    activeTab: {
      selectCorpuses: '1',
      selectDoorSpacesCount: '1'
    },
    setIsDev(isDevValue) {
      set({ isDev: isDevValue })
    },
    setNextStep() {
      set({ actualStep: 'setSpacesCount' })
    },
    setRoomStep() {
      set({ actualStep: 'room' })
    },
    setOpenCorpusVariants(value) {
      set({ openCorpusVariants: value })
    },
    openModal(type) {
      const { isModalOpen } = get()

      isModalOpen[type] = true

      set({ isModalOpen, isAnyModalOpen: true })
    },
    closeModal(type) {
      const { isModalOpen } = get()

      isModalOpen[type] = false

      let isAnyModalOpen = false

      Object.values(isModalOpen).forEach(function (status) {
        if (status) {
          isAnyModalOpen = true
        }
      })

      set({ isModalOpen, isAnyModalOpen })
    },
    setActualStep(step) {
      set({
        actualStep: step
      })
    },

    getNextStep(objType) {
      let response = false
      let disabled = false
      let { actualStep } = get()

      if (NEXT_STEP[objType]) {
        let nextStep = NEXT_STEP[objType][actualStep]

        if (nextStep) {
          if ('selectCorpuses' === actualStep) {
            const isAllCorpusesSet = getState().isAllCorpusesSet()

            if (!isAllCorpusesSet) {
              //disabled = true;
            }
          }

          if ('selectDTD' === actualStep) {
            const mainDTDId = getState().mainDTDId

            if (!mainDTDId) {
              // disabled = true;
            }
          }

          if ('selectSecondDTD' === actualStep) {
            const secondDTDId = getState().secondDTDId

            if (!secondDTDId) {
              //disabled = true;
            }
          }

          response = { disabled: disabled, key: nextStep }
        }
      }
      return response
    },
    setActiveTab(tabsName, tabId) {
      const { activeTab } = get()

      activeTab[tabsName] = tabId

      set({ activeTab })
    }
  }
})

export default store

export const { getState: getSteps, setState, apiStore: apiSteps } = store
