import React, { useCallback } from 'react'
import { getState } from '../../../store'
import { getSpacesCounts, getSpaceWidth } from '../../../Space'
import { Badge, Button, Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import Grid from '@material-ui/core/Grid'
import Door from '../Components/Molecules/Door'
import Space from '../Components/Molecules/Space'
import { makeStyles } from '@material-ui/core/styles'
import { Lang } from '../../../Utils'
import { getType as getRoomType } from '../../../utils/room'
import { getDoorWidth } from '../../../utils/door'
import { numberFormat } from '../../../utils/format'

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    border: 5,
    marginBottom: 10
  },
  test: {
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  background: {
    backgroundColor: '#F5F5F5'
  }
}))

/**
 * @param count
 * @param isSelected
 * @returns {*}
 * @constructor
 */
const SpaceCountButton = ({ count, isSelected, objType, roomType }) => {
  const classes = useStyles()
  const width = parseInt(getState().width)
  const smallerWidth = width / 1.2
  const height = parseInt(getState().height) / 1.5
  const saveSpacesCount = getState().saveSpacesCount
  const setPriceVersion = getState().setPriceVersion

  const onClickSelectSpace = useCallback(
    (e, value) => {
      e.stopPropagation() // stop it at the first intersection
      saveSpacesCount(value)
      setPriceVersion()
    },
    [saveSpacesCount, setPriceVersion]
  )

  const doors = []

  for (let i = 1; i <= count; i++) {
    doors.push({
      seq: i,
      id: i
    })
  }

  let spaceInfo = ''
  const doorHeight = height
  const lineWidth = (doorHeight / 100) * 2
  const doorWidth = (smallerWidth - lineWidth * (count + 2)) / count

  if (objType === 'd') {
    const doorWidth = Math.floor(getDoorWidth(width, count, roomType) * 10) / 10
    spaceInfo = Lang('WidthDoorInfo').replace('{0}', numberFormat(doorWidth))
  } else {
    const spaceWidth = Math.floor(getSpaceWidth(objType, width, count, roomType, 'corpuse') * 10) / 10
    spaceInfo = Lang('SpaceWidthDoorInfo').replace('{0}', numberFormat(spaceWidth))
  }

  return (
    <Badge
      invisible={!isSelected}
      color='primary'
      overlap='circle'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      badgeContent={<CheckIcon fontSize='small' />}
    >
      <Button onClick={(e) => onClickSelectSpace(e, count)}>
        <Grid container direction='column' justify='center' alignItems='center' key={count + 'seq'}>
          <Grid item>
            <div className={classes.main}>
              <div className={classes.test}>
                {doors.map((door) => (
                  <>
                    {objType === 'v' && (
                      <Space count={1} width={doorWidth} height={doorHeight} isLast={door.seq === count} classes={classes} />
                    )}
                    {objType !== 'v' && <Door count={1} width={doorWidth} height={doorHeight} />}
                  </>
                ))}
              </div>
            </div>
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>{spaceInfo}</Typography>
          </Grid>
        </Grid>
      </Button>
    </Badge>
  )
}

const SelectSpacesCount = () => {
  const mainSize = getState().mainSize
  const spacesCountValue = getState().spacesCount
  const selectedRoomType = getState().roomType
  const width = getState().width
  const objType = getState().objType

  const roomType = getRoomType(selectedRoomType, objType)
  const spacesCounts = getSpacesCounts(width, roomType, objType)
  const spaces = []

  spacesCounts.forEach(function (element) {
    const isSelected = element === spacesCountValue

    spaces.push({ count: element, isSelected: isSelected })
  })

  return (
    <Grid container justify='center' alignItems='center'>
      {spaces.map((space, index) => (
        <Grid key={index}>
          <SpaceCountButton size={mainSize} count={space.count} isSelected={space.isSelected} objType={objType} roomType={roomType} />
        </Grid>
      ))}
    </Grid>
  )
}
export default SelectSpacesCount
