import create from 'zustand'

const store = create((set) => {
  return {
    isSpotLightOn: false,
    isPointLightOn: true,

    setSpotLightOn(value) {
      const isSpotLightOn = value
      set({ isSpotLightOn })
    },
    setPointLightOn(value) {
      const isPointLightOn = value
      set({ isPointLightOn })
    }
  }
})

export default store

export const { getState: getLightStore, setState, useStore: useLightStore, apiStore: apiLightStore } = store
