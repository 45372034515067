import React, { useCallback, useMemo, useRef, useState } from 'react'
import TopStrip from '../Profile/TopStrip'
import BottomStrip from '../Profile/BottomStrip'
import * as THREE from 'three'
import { BOTTOM_STRIP_HEIGHT, STRIP_HEIGHT } from '../Profile/types'
import useStore, { getState } from '../../store'
import useSteps from '../../Stores/Steps'
import { Reflector } from 'three/examples/jsm/objects/Reflector.js'
import { getDoorFillHeights } from '../../utils/door'
import { isMobile } from 'react-device-detect'

/**
 * @param {Number} doorId
 * @param {Number} count
 * @param {Object} doorAttributes
 * @param {Object} previewAttributes
 * @returns {JSX.Element}
 * @constructor
 */
export const DoorContent = ({ doorId, count, doorAttributes, previewAttributes }) => {
  const priceVersion = getState().priceVersion
  const doorFillsAttributes = JSON.parse(getState().doorFillsAttributes)
  const group = useRef()
  const doorHeight = doorAttributes['height']
  const doorWidth = doorAttributes['width']
  const stripHeight = STRIP_HEIGHT

  const doorContents = useMemo(() => {
    const contentHeight = doorHeight / count
    let doorsSpaceAttributes = getDoorFillHeights(doorHeight, doorFillsAttributes, doorId, count)
    let controlTotalHeight = 0

    doorsSpaceAttributes.forEach(function (fillHeight) {
      controlTotalHeight += fillHeight
    })

    let nextContentPosition = 0 - stripHeight
    const newDoorContents = []

    for (let i = 1; i <= count; i++) {
      let doorContentHeight = contentHeight

      if (controlTotalHeight <= doorHeight && doorsSpaceAttributes[i - 1]) {
        doorContentHeight = doorsSpaceAttributes[i - 1]
      }

      newDoorContents.push({
        id: i,
        posY: nextContentPosition,
        height: doorContentHeight - stripHeight,
        priceVersion
      })

      nextContentPosition -= doorContentHeight
    }

    return newDoorContents
  }, [doorHeight, doorId, count, doorFillsAttributes, priceVersion, stripHeight])

  return (
    <group ref={group} position-y={doorHeight / 2}>
      <>
        {doorContents.map((doorContent) => (
          <group key={doorId + 'DoorContent' + doorContent.id}>
            <TopStrip posY={doorContent.posY} stripHeight={stripHeight} doorAttributes={doorAttributes} />
            <DoorFill
              previewAttributes={previewAttributes}
              doorId={doorId}
              doorFillId={doorContent.id}
              posY={doorContent.posY}
              width={doorWidth}
              height={doorContent.height}
            />
          </group>
        ))}
        <BottomStrip posY={0 - (doorHeight + stripHeight)} height={BOTTOM_STRIP_HEIGHT} doorAttributes={doorAttributes} />
      </>
    </group>
  )
}

/**
 * @param {Object} previewAttributes
 * @param {number} doorId
 * @param {number} doorFillId
 * @param {number} posY
 * @param {number} width
 * @param {number} height
 * @param {Object} props
 * @returns {JSX.Element}
 * @constructor
 */

function DoorFill({ previewAttributes, doorId, doorFillId, posY, width, height, ...props }) {
  const actualStep = useSteps((state) => state.actualStep)
  const setSpace = getState().setSpace
  const selectedDoorFillId = useStore((state) => state.selectedDoorFillId)
  const setSelectedDoorFillId = getState().setSelectedDoorFillId
  const doorFillsVersion = useStore((state) => state.doorFillsVersion)
  const getDoorFill = getState().getDoorFill
  const [isSelected, setSelected] = useState(false)

  const selectModel = useMemo(() => {
    const selectModel = new THREE.Mesh()
    selectModel.geometry = new THREE.PlaneBufferGeometry(width, height)
    selectModel.material = new THREE.MeshBasicMaterial({
      color: 'green'
    })
    return selectModel
  }, [width, height])

  const model = useMemo(() => {
    const geometry = new THREE.PlaneBufferGeometry(width, height)
    const WIDTH = window.innerWidth
    const HEIGHT = window.innerHeight
    let recursion = 1
    let groundMirror = false
    let model = new THREE.Mesh()
    model.key = doorFillsVersion

    model.geometry = new THREE.BoxBufferGeometry(width, height, 1)

    const doorFill = getDoorFill(doorId + '-' + doorFillId)

    let material

    if (doorFill && doorFill.type === 'dtd') {
      material = new THREE.MeshPhongMaterial({
        emissiveIntensity: 0.5,
        side: THREE.FrontSide
      })
    } else if (!isMobile && doorFill && doorFill.type === 'glass') {
      material = new THREE.MeshPhysicalMaterial({
        side: THREE.FrontSide,
        roughness: 0.1,
        metalnes: 0.2,
        reflectivity: 0.7
      })

      if (doorFill.productId !== '67000' && doorFill.productId !== '2019') {
        groundMirror = new Reflector(geometry, {
          clipBias: 0.003,
          textureWidth: WIDTH * window.devicePixelRatio,
          textureHeight: HEIGHT * window.devicePixelRatio,
          color: 0x889999,
          recursion: recursion
        })

        const filterObject = new THREE.Mesh(geometry, material)
        filterObject.position.z = 0.5
        filterObject.material.transparent = true
        filterObject.material.opacity = 0.95
        groundMirror.add(filterObject)
      }
    } else {
      material = new THREE.MeshPhysicalMaterial({
        opacity: 0.5,
        //transparent: true,
        reflectivity: 0,
        side: THREE.FrontSide
      })

      if (doorFill.type === 'mirror' && !isMobile) {
        let mirrorColor = 0x889999

        if (doorFill.productId === '2111') {
          mirrorColor = 0x99805e
        }
        if (doorFill.productId === '3439763') {
          mirrorColor = 0x000000
        }

        groundMirror = new Reflector(geometry, {
          clipBias: 0.003,
          textureWidth: WIDTH * window.devicePixelRatio,
          textureHeight: HEIGHT * window.devicePixelRatio,
          color: mirrorColor,
          recursion: recursion
        })

        if (doorFill.productId !== '2110') {
          material.transparent = true
          material.opacity = 0.5
          material.map = doorFill.texture

          const filterObject = new THREE.Mesh(geometry, material)
          filterObject.position.z = 1
          groundMirror.add(filterObject)
        }
      }
    }

    if (doorFill) {
      if (doorFill.productId === '67000') {
        material.transparent = true
        material.opacity = 0.2
      } else if (doorFill.productId === '2019') {
        material.transparent = true
        material.opacity = 0.95
      } else {
        material.map = doorFill.texture
      }
    } else {
      if (actualStep === 'selectCorpusesSetSpacesCount') {
        material.transparent = true
        material.opacity = 0.7
      }
    }

    model.material = material

    if (groundMirror && !isMobile) {
      model = groundMirror
    }

    return model
  }, [doorId, doorFillId, width, height, doorFillsVersion, getDoorFill, actualStep])

  const modelWidth1 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(width - 2, 2, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [width])
  const modelWidth2 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(width - 2, 2, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [width])

  const modelHeight1 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(2, height, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [height])
  const modelHeight2 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(2, height, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [height])

  const isSelectFillAvailable = 'selectDoorsFill' === actualStep && previewAttributes.showSelected

  if (isSelected && isSelectFillAvailable) {
    selectModel.material.opacity = 0.5
    selectModel.material.visible = 1
  } else {
    selectModel.material.visible = 0
  }

  const mainDoorFillId = doorId + '-' + doorFillId

  if (selectedDoorFillId === mainDoorFillId) {
    modelWidth1.material.visible = 1
    modelWidth2.material.visible = 1
    modelHeight1.material.visible = 1
    modelHeight2.material.visible = 1
  } else {
    modelWidth1.material.visible = 0
    modelWidth2.material.visible = 0
    modelHeight1.material.visible = 0
    modelHeight2.material.visible = 0
  }

  const onClickSelectSpace = useCallback(
    (e, doorId) => {
      if (isSelectFillAvailable) {
        e.stopPropagation() // stop it at the first intersection
        setSpace(doorId)
        setSelectedDoorFillId(mainDoorFillId)

        setSelected(false)
      }
    },
    [setSpace, setSelected, isSelectFillAvailable, mainDoorFillId, setSelectedDoorFillId]
  )

  return (
    <group
      position-y={posY - height / 2}
      onPointerDown={(e) => onClickSelectSpace(e, doorId, doorFillId)}
      onPointerOver={() => setSelected(true)}
      onPointerOut={() => setSelected(false)}
    >
      <primitive object={model} {...props} />
      {'selectDoorsFill' === actualStep && previewAttributes.showSelected && (
        <group position-z={1}>
          <primitive object={selectModel} {...props} />
          <group position-z={4}>
            <group position-y={height / 2}>
              <primitive object={modelWidth1} {...props} />
            </group>
            <group position-y={0 - height / 2}>
              <primitive object={modelWidth2} {...props} />
            </group>
            <group position-x={0 - width / 2 + 2}>
              <primitive object={modelHeight1} {...props} />
            </group>
            <group position-x={width / 2 - 2}>
              <primitive object={modelHeight2} {...props} />
            </group>
          </group>
        </group>
      )}
    </group>
  )
}
