import React from 'react'
import useStore, { getState } from '../../../store'
import { connect } from 'react-redux'
import { Lang } from '../../../Utils'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import { Badge, Grid, Paper } from '@material-ui/core'
import { Image } from 'semantic-ui-react'
import CheckIcon from '@material-ui/icons/Check'
import SiteAppBar from './SiteAppBar'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import useSteps, { getSteps } from '../../../Stores/Steps'

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = () => ({})

const useCardStyles = makeStyles({
  root: {
    maxWidth: 200,
    margin: 10
  },
  media: {
    width: 200
  },
  badge: {
    top: '85%'
  }
})

const useListStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    margin: 0
  },
  gridList: {
    overflow: 'auto'
  }
}))

function DTDImgCard({ woodDecor, productImages }) {
  const objType = useStore((state) => state.objType)
  const mainDTDId = useStore((state) => state.mainDTDId)
  const secondDTDId = useStore((state) => state.secondDTDId)
  const actualStep = useSteps((state) => state.actualStep)
  const type = actualStep === 'selectDTDIn' ? 'main' : 'second'
  const selectedId = type === 'main' && objType !== 'd' ? mainDTDId : secondDTDId
  const setDTDImage = getState().setDTDImage
  const classes = useCardStyles()

  return (
    <GridListTile key={'GridListTile' + woodDecor.productid}>
      <Badge
        invisible={selectedId.toString() !== woodDecor.productid.toString()}
        color='primary'
        overlap='circle'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        badgeContent={<CheckIcon fontSize='small' />}
        classes={{ badge: classes.badge }}
      >
        <Card
          className={classes.root}
          key={woodDecor.productid}
          onPointerDown={() => {
            setDTDImage(type, woodDecor.productid, productImages[woodDecor.productid])
          }}
        >
          <CardActionArea>
            <CardMedia
              component='img'
              className={classes.media}
              alt={Lang(woodDecor.name, 'products')}
              height='140'
              image={woodDecor.img1}
              title={Lang(woodDecor.name, 'products')}
            />
            <CardContent>
              <Typography variant='body2' color='textSecondary' component='p'>
                {woodDecor.code}
              </Typography>
              <Typography gutterBottom>{Lang(woodDecor.name, 'products')}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Badge>
    </GridListTile>
  )
}

class SelectDTDTabContent extends React.Component {
  state = {
    woodDecors: [],
    selectedDTDType: null
  }

  componentDidMount() {
    this._actualizeWoodDecors()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDTDType !== this.props.selectedDTDType) {
      this._actualizeWoodDecors()
    }
  }

  _actualizeWoodDecors() {
    const mainWoodDecors = []
    const otherWoodDecors = []

    this.props.woodDecors.map((product) => {
      if (
        this.props.mainDTDId.toString() === product.productid.toString() ||
        this.props.secondDTDId.toString() === product.productid.toString()
      ) {
        mainWoodDecors.push(product)
      } else {
        otherWoodDecors.push(product)
      }

      return true
    })

    this.setState({ woodDecors: [...mainWoodDecors, ...otherWoodDecors] })
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <GridList className={this.props.classes.gridList} cols={2.5}>
          {this.state.woodDecors &&
            this.state.woodDecors.map((woodDecor, index) => (
              <DTDImgCard key={index} woodDecor={woodDecor} productImages={this.props.productImages} />
            ))}
        </GridList>
      </div>
    )
  }
}

function SelectDTDTab({ woodDecors, productImages }) {
  const classes = useListStyles()
  const mainDTDId = getState().mainDTDId
  const secondDTDId = getState().secondDTDId
  const selectedDTDType = getState().selectedDTDType
  const objType = getState().objType

  return (
    <SelectDTDTabContent
      classes={classes}
      productImages={productImages}
      woodDecors={woodDecors}
      mainDTDId={objType !== 'v' ? mainDTDId : secondDTDId}
      secondDTDId={secondDTDId}
      selectedDTDType={selectedDTDType}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    borderWidth: 2,
    padding: '0'
  },
  content: {
    padding: 10
  },
  tabs: {
    backgroundColor: theme.palette.background.paper
  },
  tabsBackground: {
    backgroundColor: theme.palette.grey[100]
  },
  divider: {
    height: 28,
    margin: 4
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  }
}))

function SimpleTabs({ count, searchText, tabsContent, onClickSteps, onChangeSearchText, subtitle }) {
  const classes = useStyles()

  const handleChangeSearch = (event, newValue) => {
    onChangeSearchText(newValue)
  }

  const actualStep = getSteps().actualStep

  return (
    <>
      <SiteAppBar title={Lang(actualStep + 'Title')} subtitle={subtitle} onClickSteps={onClickSteps}>
        <Paper square>
          <Grid container direction='row' justify='space-between' alignItems='center'>
            <Grid item></Grid>
            <Grid item>
              <Grid container alignItems='center'>
                <Grid item></Grid>
                <Grid item>
                  <InputBase
                    className={classes.input}
                    placeholder={Lang('SearchDTD')}
                    inputProps={{ 'aria-label': Lang('SearchDTD') }}
                    startAdornment={<SearchIcon color='primary' />}
                    onChange={(e) => handleChangeSearch(e, e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </SiteAppBar>
      <div className={classes.content}>
        {tabsContent.map((tab, index) => (
          <div key={count + '-' + index + '-' + searchText}>{tab.render}</div>
        ))}
      </div>
    </>
  )
}

class SelectDTDSimple extends React.Component {
  state = {
    count: 0,
    searchText: null,
    tabsContent: [],
    subtitle: ''
  }

  constructor(props) {
    super(props)

    this.setSubtitle(this.props.dtdId)
  }

  componentDidMount() {
    this.actualizeTabContents(this.state.searchText)
    this.setSubtitle(this.props.dtdId)
  }

  componentDidUpdate(prevProps) {
    if (this.props.dtdId !== prevProps.dtdId) {
      this.setSubtitle(this.props.dtdId)
    }
  }

  actualizeTabContents(searchText) {
    let count = 0
    const self = this
    const tabsContent = []

    Object.keys(this.props.products.products).forEach(function (category) {
      const woodDecors = []

      if (self.props.products.products[category] && Object.values(self.props.products.products[category]).length) {
        self.props.products.products[category].map((product) => {
          let skipProduct = false

          if (searchText) {
            const productName = product.code + ' ' + Lang(product.name, 'products')

            skipProduct = productName.toLowerCase().search(searchText.toLowerCase()) === -1
          }

          if (!skipProduct) {
            woodDecors.push(product)
            count++
          }

          return true
        })

        let url = null

        if (category === 'egeer_standard') {
          url = process.env.PUBLIC_URL + '/images/DTD/egeer-standard.png'
        } else if (category === 'egeer_exclusive') {
          url = process.env.PUBLIC_URL + '/images/DTD/egeer-exclusive.png'
        }

        tabsContent.push({
          menuItem: url ? <Image src={url} /> : Lang(category),
          render: <SelectDTDTab woodDecors={woodDecors} productImages={self.props.productImages?.large} />
        })
      }
    })

    this.setState({ tabsContent: tabsContent, count: count })
  }

  setSubtitle(dtdId) {
    let productName = ''
    const self = this

    if (dtdId) {
      Object.keys(this.props.products.products).forEach(function (category) {
        if (self.props.products.products[category] && Object.values(self.props.products.products[category]).length) {
          self.props.products.products[category].map((product) => {
            if (dtdId.toString() === product.productid.toString()) {
              productName = product.code + ' ' + Lang(product.name, 'products')
            }
          })
        }
      })
    }

    self.setState({ subtitle: productName })
  }

  /**
   * @param {String} text
   * @private
   */
  _onChangeSearchText = (text) => {
    this.setState({ searchText: text })

    this.actualizeTabContents(text)
  }

  render() {
    return (
      <SimpleTabs
        count={this.state.count}
        searchText={this.state.searchText}
        tabsContent={this.state.tabsContent}
        objType={this.props.objType}
        mainSize={this.props.mainSize}
        handleTabChange={this.props.handleTabChange}
        selectedDTDType={this.props.selectedDTDType}
        onClickSteps={this.props.onClickSteps}
        onChangeSearchText={this._onChangeSearchText}
        subtitle={this.state.subtitle}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDTDSimple)
