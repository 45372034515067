import React from 'react'
import Strip from './Strip'

/**
 * @param {Number} posY
 * @param {Number} stripHeight
 * @param {Array} doorAttributes
 * @returns {JSX.Element}
 * @constructor
 */
const TopStrip = ({ posY, stripHeight, doorAttributes }) => {
  return <Strip posY={posY} height={stripHeight} doorAttributes={doorAttributes} />
}

export default TopStrip
