export const PROFILES_LANG = {
  alOpen: 'Profil AL otvorený',
  alClosed: 'Profil AL zatvorený',
  alMinimax: 'Profil AL MINIMAX',
  white: 'Profil biely',
  sampan: 'Profil šampaň',
  silver: 'Profil strieborný',
  gold: 'Profil zlatý',
  silverMinimax: 'Profil strieborný Minimax',
  PleaseSelectProfile: 'Prosím vyberte typ a farbu profilu',
  alOpenMobile: 'Otvorený',
  alClosedMobile: 'Zatvorený',
  alMinimaxMobile: 'MINIMAX'
}
