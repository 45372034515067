import React from 'react'
import { BOARD_DEPTH } from '../config'
import { Door } from './Doors/Door'

/**
 * @param {Object} doorAttributes
 * @param {Object} wardrobeAttributes
 * @param {Object} previewAttributes
 * @returns {JSX.Element}
 * @constructor
 */
const Doors = ({ doorAttributes, wardrobeAttributes, previewAttributes }) => {
  const spacesCount = wardrobeAttributes['spacesCount']
  const Doors = []

  let wardrobeWidth = wardrobeAttributes['width']

  if (wardrobeAttributes['showStopBar']) {
    wardrobeWidth -= BOARD_DEPTH * 2
  } else {
    wardrobeWidth -= 2
  }

  const spaceWidth = wardrobeWidth / spacesCount
  let nextDoorPosition = 0

  let i

  for (i = 1; i <= spacesCount; i++) {
    nextDoorPosition += BOARD_DEPTH

    if (i === 1) {
      nextDoorPosition += spaceWidth / 2
    } else {
      nextDoorPosition += spaceWidth
    }

    if (i === 1 || i === spacesCount) {
      nextDoorPosition += BOARD_DEPTH
    }

    Doors.push({ id: i, posX: nextDoorPosition })

    if (i === 1) {
      nextDoorPosition -= BOARD_DEPTH * 3
    } else {
      nextDoorPosition -= BOARD_DEPTH * 2
    }
  }

  return (
    <>
      {Doors.map((door) => (
        <Door
          doorsCount={Doors.length}
          doorAttributes={doorAttributes}
          wardrobeAttributes={wardrobeAttributes}
          previewAttributes={previewAttributes}
          key={door.id}
          id={door.id}
          posY={0}
          posX={door.posX}
        />
      ))}
    </>
  )
}

export default Doors
