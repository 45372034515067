import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback, useState } from 'react'
import { Lang } from '../../../../Utils'
import { isEmailAddressValid } from '../../../../utils/control'
import TopMenu from '../../Menu/TopMenu'
import MiddleMenu from '../../Menu/MiddleMenu'
import { Button, Container, Paper, Typography } from '@material-ui/core'
import SiteAppBar from '../../Menu/SiteAppBar'
import { Checkbox, Form, Grid, Header, Icon, Input, Message, TextArea } from 'semantic-ui-react'
import VerticalSidebar from '../Molecules/VerticalSidebar'
import ErrorDialog from '../Molecules/ErrorDialog'
import { getOrder } from '../../../../Stores/Order'
import SubmitOrder from '../../Modals/SubmitOrder'
import { getState } from '../../../../store'
import disableBrowserBackButton from 'disable-browser-back-navigation'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: 20,
    paddingBottom: 20
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}))

/**
 * @returns {*}
 * @constructor
 */
const Order = ({ fullScreenHandle, onClickSteps, onSubmitOrder, savedOrder }) => {
  const classes = useStyles()
  const webUUId = getOrder().getUUId()
  const emailAddress = getState().emailAddress
  const phoneNumber = getState().phoneNumber
  const [formData, setFormData] = React.useState({ isOrgOrder: '0', TermsAndConditions: '0', email: emailAddress, phone: phoneNumber })
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false)
  const [errorTexts, setErrorText] = useState([])

  const onClickIsOrg = useCallback(() => {
    formData['isOrgOrder'] = '1' === formData['isOrgOrder'] ? '0' : '1'

    setFormData({ ...formData })
  }, [formData, setFormData])

  const onChangeValue = useCallback(
    (e) => {
      formData[e.target.name] = e.target.value

      setFormData({ ...formData })
    },
    [formData, setFormData]
  )

  const onClickTermsAndConditions = useCallback(() => {
    formData['TermsAndConditions'] = '1' === formData['TermsAndConditions'] ? '0' : '1'

    setFormData({ ...formData })
  }, [formData, setFormData])

  const controlInputs = [
    { input: 'firstName', error: 'ALERT20' },
    { input: 'lastName', error: 'ALERT21' },
    { input: 'phone', error: 'ALERT22' },
    { input: 'email', error: 'ALERT23' }
  ]

  const onClickSubmitOrder = useCallback(
    (e) => {
      e.stopPropagation()
      const errorTexts = []

      if (
        formData['isOrgOrder'] === '1' &&
        (!formData['orgName'] ||
          formData['orgName'] === '' ||
          !formData['ico'] ||
          formData['ico'] === '' ||
          !formData['dic'] ||
          formData['dic'] === '')
      ) {
        errorTexts.push(Lang('OrgDataCanNotBeEmpty', 'inputs'))
      }

      controlInputs.map((inputData) => {
        if (!formData[inputData.input] || formData[inputData.input] === '') {
          errorTexts.push(Lang(inputData.error, 'inputs'))
        }
      })

      if (
        !formData['billStreet'] ||
        formData['billStreet'] === '' ||
        !formData['billCity'] ||
        formData['billCity'] === '' ||
        !formData['billZIP'] ||
        formData['billZIP'] === ''
      ) {
        errorTexts.push(Lang('ALERT25', 'inputs'))
      }

      if (formData['TermsAndConditions'] === '0') {
        errorTexts.push(Lang('ALERT26', 'inputs'))
      }

      if (formData['email'] !== '' && !isEmailAddressValid(formData['email'])) {
        errorTexts.push(Lang('BadEmailFormatError', 'inputs'))
      }

      if (Object.keys(errorTexts).length) {
        setErrorText(errorTexts)
        setOpenErrorDialog(true)
      } else {
        disableBrowserBackButton()
        setOpenSubmitDialog(true)
        onSubmitOrder({ webUUId: webUUId, data: formData })
        //ReactPixel.track('Purchase', {value: priceValue, currency: 'EUR', objType: objType});
      }
    },
    [onSubmitOrder, formData, controlInputs, setOpenSubmitDialog, disableBrowserBackButton]
  )

  return (
    <>
      <TopMenu mainStep='order' />
      <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />
      <Paper>
        <SiteAppBar title={Lang('OrderForm')} onClickSteps={onClickSteps} />
        <Container className={classes.mainContainer}>
          <Message icon info>
            <Icon name='info' size='mini' />
            <Message.Content>{Lang('OrderInfo')}</Message.Content>
          </Message>
          <Form>
            <Form.Field
              control={Checkbox}
              name='isOrgOrder'
              label={Lang('isOrgOrder')}
              onClick={(e) => onClickIsOrg(e)}
              checked={'1' === formData['isOrgOrder']}
            />
            {'1' === formData['isOrgOrder'] && (
              <>
                <Form.Group widths='equal'>
                  <Form.Field
                    required
                    control={Input}
                    name='orgName'
                    value={formData['orgName']}
                    label={Lang('Organization')}
                    placeholder={Lang('Organization')}
                    onChange={(e) => onChangeValue(e)}
                  />
                  <Form.Field
                    required
                    control={Input}
                    name='ico'
                    value={formData['ico']}
                    label={Lang('ICO')}
                    placeholder={Lang('ICO')}
                    onChange={(e) => onChangeValue(e)}
                  />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.Field
                    required
                    control={Input}
                    name='dic'
                    value={formData['dic']}
                    label={Lang('DIC')}
                    placeholder={Lang('DIC')}
                    onChange={(e) => onChangeValue(e)}
                  />
                  <Form.Field
                    control={Input}
                    name='icdph'
                    value={formData['icdph']}
                    label={Lang('ICDPH')}
                    placeholder={Lang('ICDPH')}
                    onChange={(e) => onChangeValue(e)}
                  />
                </Form.Group>
              </>
            )}
            <Grid columns={2} padded='vertically'>
              <Grid.Column>
                <Form.Field
                  required
                  control={Input}
                  name='firstName'
                  value={formData['firstName']}
                  label={Lang('FirstName')}
                  placeholder={Lang('FirstName')}
                  onChange={(e) => onChangeValue(e)}
                />
                <Form.Field
                  required
                  control={Input}
                  name='lastName'
                  value={formData['lastName']}
                  label={Lang('LastName')}
                  placeholder={Lang('LastName')}
                  onChange={(e) => onChangeValue(e)}
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Field
                  required
                  control={Input}
                  name='phone'
                  value={formData['phone']}
                  label={Lang('Phone')}
                  placeholder={Lang('Phone')}
                  onChange={(e) => onChangeValue(e)}
                />
                <Form.Field
                  required
                  control={Input}
                  name='email'
                  value={formData['email']}
                  label={Lang('Email')}
                  placeholder={Lang('Email')}
                  onChange={(e) => onChangeValue(e)}
                />
              </Grid.Column>
            </Grid>
            <br />
            <Grid columns={2} padded='vertically'>
              <Grid.Column>
                <Header>{Lang('BillAddress')}</Header>
                <Form.Field
                  required
                  control={Input}
                  name='billStreet'
                  value={formData['billStreet']}
                  label={Lang('Street')}
                  placeholder={Lang('Street')}
                  onChange={(e) => onChangeValue(e)}
                />
                <Form.Field
                  required
                  control={Input}
                  name='billCity'
                  value={formData['billCity']}
                  label={Lang('City')}
                  placeholder={Lang('City')}
                  onChange={(e) => onChangeValue(e)}
                />
                <Form.Field
                  required
                  control={Input}
                  name='billZIP'
                  value={formData['billZIP']}
                  label={Lang('ZIP')}
                  placeholder={Lang('ZIP')}
                  onChange={(e) => onChangeValue(e)}
                />
              </Grid.Column>
              <Grid.Column>
                <Header>{Lang('DeliveryAddress')}</Header>
                <Form.Field
                  control={Input}
                  name='deliveryStreet'
                  value={formData['deliveryStreet']}
                  label={Lang('Street')}
                  placeholder={Lang('Street')}
                  onChange={(e) => onChangeValue(e)}
                />
                <Form.Field
                  control={Input}
                  name='deliveryCity'
                  value={formData['deliveryCity']}
                  label={Lang('City')}
                  placeholder={Lang('City')}
                  onChange={(e) => onChangeValue(e)}
                />
                <Form.Field
                  control={Input}
                  name='deliveryZIP'
                  value={formData['deliveryZIP']}
                  label={Lang('ZIP')}
                  placeholder={Lang('ZIP')}
                  onChange={(e) => onChangeValue(e)}
                />
              </Grid.Column>
            </Grid>
            <Form.Field
              control={TextArea}
              name='description'
              value={formData['description']}
              label={Lang('Description')}
              onChange={(e) => onChangeValue(e)}
            />
            <Form.Field control={Checkbox} label={Lang('TermsAndConditions')} onClick={(e) => onClickTermsAndConditions(e)} />
          </Form>
          <br />
          <br />
          <Container className={classes.buttonContainer}>
            <Button variant='contained' color='primary' className={classes.nextButtonActive} onClick={(e) => onClickSubmitOrder(e)}>
              <div className={classes.buttonActiveDiv}>
                <Typography variant='h6' className={classes.buttonNextText}>
                  {Lang('Order', 'buttons')}
                </Typography>
              </div>
            </Button>
          </Container>
        </Container>
      </Paper>
      <VerticalSidebar onClickSteps={onClickSteps} />
      <ErrorDialog openDialogErrors={errorTexts} openErrorDialog={openErrorDialog} setOpenErrorDialog={setOpenErrorDialog} />
      <SubmitOrder isModalOpen={openSubmitDialog} classes={classes} savedOrder={savedOrder} />
    </>
  )
}

export default Order
