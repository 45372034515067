import { LANG as SK_LANG } from './sk/lang'
import { LANG as CZ_LANG } from './cz/lang'
import { LANG as DE_LANG } from './de/lang'

function getLangData() {
  let LANG

  switch (process.env.REACT_APP_WEBSITE_LANG) {
    case 'cz':
      LANG = CZ_LANG
      break
    case 'de':
      LANG = DE_LANG
      break
    default:
      LANG = SK_LANG
  }

  return LANG
}

export function isTranslated(label, type) {
  const LANG = getLangData()

  return (type && LANG[type][label] && LANG[type][label] !== '') || (!type && LANG[label] !== '')
}

export default function get(label, type) {
  const LANG = getLangData()

  if (type && LANG[type][label]) {
    label = LANG[type][label]
  } else if (LANG[label]) {
    label = LANG[label]
  }

  return label
}
