export const NAVIGATION_LANG = {
  basicSettings: 'Grundeinstellungen',
  selectCorpusesSetSpacesCount: 'TurenAuswahlen',
  selectCorpuses: 'KorpusAuswahlen',
  selectDTD: 'Holz-DekorAuswahlen',
  selectDoorSpacesCount: 'TuranzahlAuswahlen',
  selectProfiles: 'ProfilAuswahlen',
  selectDoorsFill: 'TurfullungAuswahlen',
  roomType: 'TypAuswahlen',
  setWardrobeParameters: 'ParameterEinstellen',
  accessories: 'Extras',
  priceCalculation: 'Preiskalkulation',
  order: 'Bestellung',
  selectDTDIn: 'InnenfarbeWahlen',
  selectDTDOut: 'AussenfarbeWahlen'
}
