import React from 'react'
import TopStrip from '../../Profile/TopStrip'
import * as THREE from 'three'

/**
 * @param count
 * @param doorAttributes
 * @returns {*}
 * @constructor
 */
const WindowContent = ({ count, doorAttributes }) => {
  const doorHeight = doorAttributes['height']
  const doorWidth = doorAttributes['width']
  const stripHeight = 2
  const contentHeight = doorHeight / count
  let nextContentPosition = 0 - stripHeight
  const doorContents = []

  for (let i = 1; i <= count; i++) {
    const doorContentHeight = contentHeight - stripHeight

    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(doorWidth, doorContentHeight, 1)
    model.material = new THREE.MeshPhysicalMaterial({
      opacity: 0.5,
      transparent: true,
      reflectivity: 0,
      side: THREE.FrontSide
    })

    doorContents.push({ id: i, posY: nextContentPosition, height: doorContentHeight, model: model })
    nextContentPosition -= doorContentHeight + stripHeight
  }

  return (
    <group position-y={doorHeight / 2}>
      {doorContents.map((doorContent, index) => (
        <group key={index + 'WindowContent' + doorContent.id}>
          <TopStrip posY={doorContent.posY} stripHeight={stripHeight} doorAttributes={doorAttributes} />
          <group position-y={doorContent.posY - doorContent.height / 2}>
            <primitive object={doorContent.model} />
          </group>
        </group>
      ))}
    </group>
  )
}

export default React.memo(WindowContent)
