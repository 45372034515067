import React, { useMemo, createRef, useCallback, useState } from 'react'
import { Redirect } from 'react-router-dom'
import VerticalSidebar from '../Components/Molecules/VerticalSidebar'
import useStore, { getState } from '../../../store'
import { getSteps } from '../../../Stores/Steps'
import { CORPUSES_CATEGORIES } from '../../../config'
import { Lang } from '../../../Utils'
import TopMenu from '../Menu/TopMenu'
import '../../../App.css'
import CorpusesMenu from '../Components/Molecules/CorpusesMenu'
import SelectCorpusesVariants from '../Modals/SelectCorpusesVariants'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Divider } from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import TabList from '@material-ui/lab/TabList'
import TabContext from '@material-ui/lab/TabContext'
import TabPanel from '@material-ui/lab/TabPanel'
import { getCorpusesSpaces, getCorpusesSpaceWidth } from '../../../utils/corpuses'
import { getSpaceWidth } from '../../../Space'
import { get as getWidth } from '../../../utils/width'
import { getType as getRoomType } from '../../../utils/room'
import MiddleMenu from '../Menu/MiddleMenu'
import { START_STEP } from '../../../config'
import ErrorDialog from '../Components/Molecules/ErrorDialog'
import SiteAppBar from '../Menu/SiteAppBar'
import { setStep } from '../../../utils/steps'

const useStyles = makeStyles((theme) => ({
  tabs: {
    backgroundColor: theme.palette.background.paper
  },
  tabPanel: {
    padding: 0,
    margin: 0
  },
  sectionDivider: {
    marginTop: 10,
    marginBottom: 20
  },
  sectionTitle: {
    paddingBottom: 20
  }
}))

/**
 * @param fullScreenHandle
 * @param onClickSteps
 * @param hideMiddleMenu
 * @returns {JSX.Element}
 * @constructor
 */
const SelectCorpuses = ({ fullScreenHandle, onClickSteps, hideMiddleMenu = false }) => {
  const contextRef = createRef()
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [errorTexts, setErrorText] = useState([])
  const classes = useStyles()
  let selectedSpace = useStore((state) => state.selectedSpace)
  const setSpace = getState().setSpace
  const selected = useStore((state) => state.selectedCorpuses[selectedSpace])
  const spaceHeight = getState().spaceHeight
  const setCorpusType = getState().setCorpusType
  const setPriceVersion = getState().setPriceVersion
  const spacesCount = useStore((state) => state.spacesCount)
  const closeModal = getSteps().closeModal
  let isVariantsModalOpen = getSteps().isModalOpen['selectCorpusVariants']
  const objType = getState().objType
  let activeTab = getSteps().activeTab['selectCorpuses']

  if (spacesCount === 1) {
    activeTab = '2'
  }

  const setActiveTab = getSteps().setActiveTab
  let dividedSpaces = JSON.parse(getState().dividedSpaces)
  const dividedSpacesWidth = JSON.parse(getState().dividedSpacesWidth)
  const width = getState().width
  const selectedRoomType = getState().roomType
  const selectedCorpuses = getState().selectedCorpuses

  const onClickStepsModified = onClickSteps

  let roomType = useMemo(() => {
    return getRoomType(selectedRoomType, objType)
  }, [selectedRoomType, objType])

  onClickStepsModified.next = useCallback(
    (history) => {
      const errorTexts = []
      let seq = 1

      const basicSpaceWidth = getSpaceWidth(objType, width, spacesCount, roomType)

      if (basicSpaceWidth > 100) {
        dividedSpaces = {}

        for (let spaceSeq = 1; spaceSeq <= spacesCount; spaceSeq++) {
          dividedSpaces[spaceSeq] = true
        }
      }

      for (let spaceId = 1; spaceId <= spacesCount; spaceId++) {
        if (dividedSpaces[spaceId]) {
          if (!selectedCorpuses[spaceId + 'A']) {
            errorTexts.push(Lang('CorpusesSpaceIsEmptyError').replace('{0}', seq))
          }
          seq++

          if (!selectedCorpuses[spaceId + 'B']) {
            errorTexts.push(Lang('CorpusesSpaceIsEmptyError').replace('{0}', seq))
          }
        } else {
          if (!selectedCorpuses[spaceId]) {
            errorTexts.push(Lang('CorpusesSpaceIsEmptyError').replace('{0}', seq))
          }
        }

        seq++
      }

      setErrorText(errorTexts)

      if (Object.keys(errorTexts).length) {
        setOpenErrorDialog(true)
      } else {
        setStep(onClickSteps.nextStep)
        history.push('/' + Lang(onClickSteps.nextStep, 'navigation'))
      }
    },
    [objType, roomType, onClickSteps, dividedSpaces, selectedCorpuses, setErrorText, setOpenErrorDialog, spacesCount]
  )

  const onHandleChange = (event, tabValue) => {
    setActiveTab('selectCorpuses', tabValue)
  }

  if (!selectedSpace) selectedSpace = 1

  const basicSpaceWidth = getSpaceWidth(objType, width, spacesCount, roomType)

  const { actualId, nextId, selectedSpaceId } = useMemo(() => {
    return getCorpusesSpaces(basicSpaceWidth, spacesCount, dividedSpaces, selectedSpace)
  }, [spacesCount, dividedSpaces, selectedSpace])

  const defaultSpaceWidth = useMemo(() => {
    const wardrobeWidth = getWidth(width, spacesCount)

    return getSpaceWidth(objType, wardrobeWidth, spacesCount, roomType, 'corpuse')
  }, [objType, width, spacesCount, roomType])

  const spaceWidth = useMemo(() => {
    return getCorpusesSpaceWidth(selectedSpaceId, actualId, defaultSpaceWidth, dividedSpaces, dividedSpacesWidth)
  }, [selectedSpaceId, actualId, defaultSpaceWidth, dividedSpaces, dividedSpacesWidth])

  const panes2 = useMemo(() => {
    const newPanes = []

    const onSelect = (key) => {
      setCorpusType(selectedSpace, key)
      setSpace(nextId)
      setPriceVersion()
    }

    let isFirst = true

    CORPUSES_CATEGORIES.forEach(function (category, index) {
      newPanes.push({
        spacesCount: spacesCount,
        isFirst: isFirst,
        menuItem: Lang(category),
        render: () => (
          <div id={index + '-' + spaceWidth}>
            <CorpusesMenu
              spaceWidth={spaceWidth}
              spaceHeight={spaceHeight}
              onSelect={onSelect}
              category={category}
              selected={selected}
              actualCorpusesSpaceId={actualId}
            />
          </div>
        )
      })

      isFirst = false
    })

    return newPanes
  }, [setCorpusType, setPriceVersion, spaceHeight, selectedSpace, selected, spacesCount, setSpace, actualId, nextId, spaceWidth])

  const isTypeNotSelected = useMemo(() => {
    return objType === ''
  }, [objType])

  if (isTypeNotSelected) {
    return <Redirect to={'/' + Lang(START_STEP, 'navigation')} />
  }

  return (
    <div ref={contextRef}>
      <TopMenu mainStep='selectCorpuses' />
      {!hideMiddleMenu && <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickStepsModified} />}
      <Card>
        <TabContext value={activeTab}>
          <SiteAppBar title={Lang('SelectCorpusesTitle')} onClickSteps={onClickSteps}>
            <TabList textColor='primary' onChange={onHandleChange} className={classes.tabs}>
              <Tab value='1' label={Lang('CorpusesVariantsTabName')} />
              <Tab value='2' label={Lang('SelectOwnDoorsTabName')} />
            </TabList>
          </SiteAppBar>
          <TabPanel value='1'>
            <SelectCorpusesVariants
              isModalOpen={isVariantsModalOpen}
              onCloseModal={() => closeModal('selectCorpusVariants')}
              basicSpaceWidth={basicSpaceWidth}
              spaceHeight={spaceHeight}
              spacesCount={spacesCount}
            />
          </TabPanel>
          <TabPanel value='2'>
            {panes2.map((section, index) => (
              <>
                {!section.isFirst && <Divider key={'d' + index} className={classes.sectionDivider} />}
                <Typography key={'t' + index} color='inherit' variant='h5' className={classes.sectionTitle}>
                  {section.menuItem}
                </Typography>
                {section.render()}
              </>
            ))}
          </TabPanel>
        </TabContext>
      </Card>
      <VerticalSidebar onClickSteps={onClickStepsModified} />
      <ErrorDialog
        openDialogErrors={errorTexts}
        openErrorDialog={openErrorDialog}
        setOpenErrorDialog={setOpenErrorDialog}
        additionalStartText={Lang('AllSpacesMustHaveCorpusesInfo')}
      />
    </div>
  )
}

export default SelectCorpuses
