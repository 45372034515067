import * as THREE from 'three'
import React from 'react'
import { BOARD_DEPTH } from '../../config'
import { BAIL_WIDTH } from './types'
import getMaterial from './material'

/**
 * @param {Object}  doorAttributes
 * @param {Object}  wardrobeAttributes
 * @returns {JSX.Element}
 * @constructor
 */
const Bail = ({ doorAttributes, wardrobeAttributes }) => {
  const objectMaterial = getMaterial(doorAttributes.profile)
  const objectMaterial2 = getMaterial(doorAttributes.profile)

  objectMaterial2.metalness = objectMaterial.metalness + 0.1

  let width = wardrobeAttributes.width

  if (wardrobeAttributes.showStopBar) {
    width -= BOARD_DEPTH * 2
  }

  const depth = wardrobeAttributes.depth

  const posY = 0.1
  let posX = width / 2

  if (wardrobeAttributes.showStopBar) {
    posX += BOARD_DEPTH
  }

  const posZ = depth / 2 - wardrobeAttributes.freeSpace / 2
  const rotation = [Math.PI / 2, 0, 0]

  const basicObject = new THREE.Mesh(new THREE.PlaneGeometry(width, BAIL_WIDTH), objectMaterial2)

  const bottomObject = new THREE.Mesh(new THREE.BoxGeometry(width, 1.75, 0.5), objectMaterial)

  const centerObject = new THREE.Mesh(new THREE.BoxGeometry(width, 2.5, 0.5), objectMaterial)

  const frontObject = new THREE.Mesh(new THREE.BoxGeometry(width, 1.75, 0.5), objectMaterial)

  return (
    <group position-y={posY} position-x={posX} position-z={posZ} rotation={rotation}>
      <mesh>
        <primitive object={basicObject} />
      </mesh>
      <mesh position-z={0 - 0.5} position-y={wardrobeAttributes.freeSpace / 2 - 7}>
        <primitive object={bottomObject} />
      </mesh>
      <mesh position-z={0 - 0.5} position-y={wardrobeAttributes.freeSpace / 2 - 3.85}>
        <primitive object={centerObject} />
      </mesh>
      <mesh position-z={-0.25} position-y={wardrobeAttributes.freeSpace / 2 - 0.75}>
        <primitive object={frontObject} />
      </mesh>
    </group>
  )
}

export default Bail
