import React, { useCallback, useMemo, useRef, useState } from 'react'
import * as THREE from 'three'
import useStore, { getState } from '../../store'
import { getSteps } from '../../Stores/Steps'

/**
 * @param {Number} doorId
 * @param {Array} doorAttributes
 * @returns {JSX.Element}
 * @constructor
 */
export const SelectDoor = ({ doorId, doorAttributes }) => {
  const selectedSpace = useStore((state) => state.selectedSpace)
  const setSpace = getState().setSpace
  const activeTab = getSteps().activeTab['selectDoorSpacesCount']
  const setActiveTab = getSteps().setActiveTab
  const group = useRef()
  const doorHeight = doorAttributes['height']
  const doorWidth = doorAttributes['width']

  const [isSelected, setSelected] = useState(false)

  const basicMaterial = new THREE.MeshStandardMaterial({
    color: 'green'
  })

  const basicObject = new THREE.Mesh(new THREE.BoxGeometry(doorWidth, doorHeight), basicMaterial)

  if (isSelected) {
    basicObject.material.opacity = 0.5
  } else {
    basicObject.material.visible = 0
  }

  const onClickSelectSpace = useCallback(
    (e, id) => {
      e.stopPropagation() // stop it at the first intersection
      setSpace(id)
      setSelected(false)
      setActiveTab('selectDoorSpacesCount', '2')
    },
    [setSpace, setSelected, setActiveTab]
  )

  const width = doorWidth
  const height = doorHeight

  const modelWidth1 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(width, 2, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [width])
  const modelWidth2 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(width, 2, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [width])

  const modelHeight1 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(2, height, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [height])
  const modelHeight2 = useMemo(() => {
    const model = new THREE.Mesh()
    model.geometry = new THREE.BoxBufferGeometry(2, height, 4)
    model.material = new THREE.MeshBasicMaterial({ color: 'green' })
    return model
  }, [height])

  const showGreenBorder = selectedSpace === doorId && activeTab === '2'

  return (
    <group ref={group} position-z={5}>
      <group
        onPointerDown={(e) => onClickSelectSpace(e, doorId)}
        onPointerOver={() => setSelected(true)}
        onPointerOut={() => setSelected(false)}
      >
        <primitive object={basicObject} />
      </group>
      {showGreenBorder && (
        <group position-z={5}>
          <group position-y={height / 2}>
            <primitive object={modelWidth1} />
          </group>
          <group position-y={0 - height / 2}>
            <primitive object={modelWidth2} />
          </group>
          <group position-x={0 - width / 2}>
            <primitive object={modelHeight1} />
          </group>
          <group position-x={width / 2}>
            <primitive object={modelHeight2} />
          </group>
        </group>
      )}
    </group>
  )
}
