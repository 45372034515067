import React, { useCallback } from 'react'
import useStore from '../../../store'
import Door from '../Components/Molecules/Door'
import { Badge, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'

const useStyles = makeStyles(() => ({
  root: {
    margin: 10
  },
  badge: {
    top: '88%'
  }
}))

/**
 * @param onClickSelectSpace
 * @param count
 * @param isSelected
 * @returns {*}
 * @constructor
 */
const SpaceCountButton = ({ selectedSpace, onClickSelect, count }) => {
  const classes = useStyles()
  const priceVersion = useStore((state) => state.priceVersion)
  const selectedDoorsSpace = useStore((state) => state.selectedDoorsSpace)
  let selectedSpacesCount = selectedDoorsSpace[selectedSpace]

  if (!selectedSpacesCount) selectedSpacesCount = 1

  return (
    <Badge
      key={priceVersion}
      invisible={selectedSpacesCount !== count}
      color='primary'
      overlap='circle'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      classes={{ badge: classes.badge }}
      badgeContent={<CheckIcon fontSize='small' />}
    >
      <Card className={classes.root} key={count} onPointerDown={(e) => onClickSelect(e, count)}>
        <CardActionArea>
          <CardContent>
            <Door count={count} height={200} />
          </CardContent>
        </CardActionArea>
      </Card>
    </Badge>
  )
}

const SelectDoorSpacesCount = ({ selectedSpace }) => {
  const saveDoorSpacesCount = useStore((state) => state.saveDoorSpacesCount)
  const setPriceVersion = useStore((state) => state.setPriceVersion)
  const spaces = []
  let count

  for (count = 1; count <= 6; count++) {
    spaces.push({ id: count, count: count })
  }

  const onClickSelect = useCallback(
    (e, value) => {
      e.stopPropagation()
      saveDoorSpacesCount(value)
      setPriceVersion()
    },
    [saveDoorSpacesCount, setPriceVersion]
  )

  return (
    <>
      {spaces.map((space, index) => (
        <SpaceCountButton
          key={'SpaceCountButton' + index}
          selectedSpace={selectedSpace}
          count={space.count}
          onClickSelect={onClickSelect}
        />
      ))}
    </>
  )
}

export default SelectDoorSpacesCount
