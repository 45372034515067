import React from 'react'
import Type from '../../Profile/TypeA'
import WindowContent from './WindowContent'

/**
 * @param doorAttributes
 * @param roomAttributes
 * @param id
 * @param posY
 * @param posX
 * @returns {*}
 * @constructor
 */
const Window = ({ doorAttributes, windowsAttributes, posY, posX }) => {
  const spaceDepth = windowsAttributes['depth']
  const doorHeight = doorAttributes['height']

  return (
    <group position-y={posY + doorHeight / 2 + 2} position-x={posX} position-z={spaceDepth / 2 - 7}>
      <group>
        <Type type='left' doorAttributes={doorAttributes} />
        <WindowContent count={4} doorAttributes={doorAttributes} />
        <Type type='right' doorAttributes={doorAttributes} />
      </group>
    </group>
  )
}

export default React.memo(Window)
