import * as THREE from 'three'

/**
 * @param profile
 * @returns {MeshPhongMaterial}
 */
export default function getMaterial(profile) {
  let color = ''

  if (profile) {
    switch (profile.type) {
      case 'silver':
        color = profile.type
        break
      case 'sampan':
        color = '#0c0703'
        break
      case 'gold':
        color = '#A07D4E'
        break
      default:
        color = 'white'
    }
  }

  const objectMaterial = new THREE.MeshPhongMaterial({
    color: color,
    specular: 0x000000,
    side: THREE.DoubleSide,
    reflectivity: 0.6
  })

  return objectMaterial
}
