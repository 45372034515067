// prettier-ignore

export const INFO_LANG = {
    lbl_edit_width: "Angabe der Breite des Schrankes",
    lbl_edit_width_info: "Die Breite Ihres Einbauschranks darf nicht weniger als 90 cm betragen. Sie müssen die Außenmaße des Einbauschranks angeben, da die Innenkonstruktion um die Dicke der Spanplatte kleiner ist. Wenn Sie einen Einbauschrank in einer Nische haben (Grundrisstyp A), empfehlen wir Ihnen, 3-4 mm von der gemessenen Breite abzuziehen.",
    lbl_edit_height: "Zuordnung der Schrankhöhe",
    lbl_edit_height_info: "Aufgrund von Unebenheiten der Wände oder Decken empfehlen wir, 3-4 mm von der gemessenen Höhe abzuziehen, um Probleme beim Einbau des Einbauschranks zu vermeiden. Bei den verschiedenen Maßen, die Sie angegeben haben, werden die Seiten auf die niedrigste angegebene Höhe zugeschnitten. Es ist also sehr wichtig, dass Sie messen, ob die Höhe über die gesamte Tiefe des Einbauschranks akzeptabel ist. Die maximal zulässige Höhe ohne Unterteilung der Türen beträgt 270 cm.",
    lbl_edit_depth: "Eingabe der Schranktiefe",
    lbl_edit_depth_info: "Die Tiefe des Einbauschranks ist die Tiefe der gesamten Baugruppe einschließlich des Schiebesystems, was bedeutet, dass das Innere des Einbauschranks 10 cm kleiner ist. Aus diesem Grund empfehlen wir eine Tiefe des Einbauschranks von 70 cm (die Innenseite beträgt 60 cm).",
    selectProfilesTitle: "PROFILTYP UND FARBE AUSWÄHLEN",
    selectProfilesContentVD: 'Mit "Profil" ist der Türrahmen gemeint. Wählen Sie aus einem breiten Angebot an unterschiedlichen Aluminium-Profilen (MILANO, NAPOLI und VENEZIA) aus. Alle Türen sowie die obere und untere Schiene werden mit dem gewählten Rahmentyp eingerahmt. Der Preis der Profile ist derselbe.<br><br>MILANO - Das am häufigsten verkaufte Profil für Einbauschränke. Der Griff ist C-förmig, so dass er leicht zu handhaben ist.<br><br>NAPOLI - Dieses Profil ist S-förmig und wird für Schiebetüren von Einbauschränken verwendet. Es ist am besten für Schiebetüren zur Raumaufteilung geeignet, da es eine doppelseitige Form hat.<br><br>1.&nbsp;&nbsp; in der von Ihnen gewählten Farbe haben Sie eine obere und eine untere Schiene.<br>2.&nbsp;&nbsp; Wenn Sie eine Schiebetür haben, die in mehrere Abschnitte unterteilt ist, sind die Trennschienen in der gleichen Farbe gehalten.<br><span class="text_description">3.&nbsp;&nbsp; Wenn Sie fertig sind, klicken Sie auf „Weiter“.</span>',
    selectProfilesContentD: 'Mit "Profil" ist der Türrahmen gemeint. Wählen Sie aus einem breiten Angebot an unterschiedlichen Aluminium-Profilen (MILANO, NAPOLI und VENEZIA) aus. Alle Türen sowie die obere und untere Schiene werden mit dem gewählten Rahmentyp eingerahmt. Der Preis der Profile ist derselbe.'+
        '<br><br>'+
        'MILANO - Das am häufigsten verkaufte Profil für Schiebetüren. Der Griff ist C-förmig, so dass er leicht zu handhaben ist.'+
        '<br><br>'+
        'NAPOLI - Dieses Profil ist S-förmig und wird meistens für Schiebetüren von Schränken verwendet. Es ist am besten für Schiebetüren zur Raumtrennung geeignet, da es eine doppelseitige Form hat'+
        '<br><br>'+
        'VENEZIA - Flachgehaltenes Profil für einseitige Nutzung, einfach zu handhaben. Als Raumteiler-Lösung nicht geeignet.'+
        '<br><br>'+
        '1. In der von Ihnen gewählten Farbe haben Sie eine obere und eine untere Schiene.<br>'+
        '2. Wenn Sie eine Schiebetür haben, die in mehrere Abschnitte unterteilt ist, sind die Trennschienen in der gleichen Farbe gehalten.<br>'+
        '3. Wenn Sie fertig sind, klicken Sie auf „Weiter“.',
    roomTypeContentVD: "Wählen Sie die Art des Grundrisses in Abhängigkeit von dem Ort, an dem Ihr Einbauschrank aufgestellt werden soll",
    roomTypeContentV: "Wählen Sie die Art des Grundrisses in Abhängigkeit von dem Ort, an dem Ihr Einbauschrank aufgestellt werden soll",
    roomTypeContentD:  "Wählen Sie die Art des Grundrisses in Bezug auf den Ort, an dem die Tür platziert werden soll",

  roomTypeInfoContentA:
    'Der Schrank wird von der linken Wand nach rechts verlaufen. Hinweis: die Länge der internen Konstruktion wird - 1cm',
  roomTypeInfoContentB: 'Der Schrank wird von der linken Wand bis zur Seitenwand reichen',
  roomTypeInfoContentC: 'Der Schrank wird von der rechten Wand bis zur Seitenwand reichen',
  roomTypeInfoContentD: 'Der Schrank wird eine linke und eine rechte Seite haben',

  selectDoorSpacesCountTitle: 'AUSWAHL DES TÜRTYPS',
  selectDoorSpacesCountContentVD:
    'In diesem Schritt wählen Sie die Art der Schiebetür für jeden Raum. Es gibt sowohl Volltüren als auch geteilte Türen (halbiert, gedrittelt oder auch in kleineren Teilen).  Der Raum, für den der Typ derzeit definiert ist, ist grün markiert.<br><br>TIPP: Bei Türen, die zur Hälfte, zu einem Drittel oder zu einem Fünftel geteilt sind, können Sie die Höhe des Zwischenraums nach Belieben verändern.<br><br>In den folgenden Schritten können Sie die Farbe der Tür auswählen. <span class="text_description">Wenn Sie Ihre Angaben gemacht haben, klicken Sie bitte auf die Schaltfläche „Weiter“.</span>',
  selectDoorSpacesCountContentD:
    'In diesem Schritt wählen Sie die Art bzw. die Teilung der Schiebetür im ausgewählten Segment. Es gibt sowohl Volltüren als auch geteilte Türen (halbiert, gedrittelt oder auch in kleineren Teilen). Der Bereich, für den der Typ derzeit definiert ist, ist grün markiert.' +
    '<br><br>' +
    'TIPP:  Bei Türen, die zur Hälfte, zu einem Drittel oder zu einem Fünftel geteilt sind, können Sie die Höhe des Zwischenraums nach Belieben verändern.' +
    '<br><br>' +
    'In den folgenden Schritten können Sie die Farbe der Türen auswählen. Wenn Sie Ihre Angaben gemacht haben, klicken Sie bitte auf die Schaltfläche „Weiter“.',

  selectDTDContentVD:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  basicSettingsContentVD:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  selectCorpusesContentVD:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',

  setWardrobeParametersWidthTitle: 'BREITE UND HÖHE DER SCHIEBETÜREN',
  setWardrobeParametersWidthContentVD:
    '(1) Die Breite des Einbauschranks darf nicht weniger als 90 cm betragen.<br>\n' +
    ' Für die Breite des Einbauschranks geben Sie bitte die genauen Raummaße an.<br>\n' +
    '(2) Bei Grundrissen des Typs "A" ist das genaue gemessene Maß der Nischenbreite von Wand zu Wand anzugeben.<br>\n' +
    ' Bei diesem Grundriss passt das System das Innere Ihres Einbauschranks automatisch um 1 bis 1,5 cm kleiner an, so dass das Innere des Schranks zwischen zwei feste Wände passt. In diesem Fall haben die Schiebetüren genau die gleichen Abmessungen wie die Wände.<br>\n' +
    '(3) Nachdem Sie Ihre Angaben gemacht haben, klicken Sie auf die Schaltfläche „Weiter“.',
  setWardrobeParametersWidthContentD:
    'Die Breite der Nische darf nicht weniger als 90 cm betragen. Wenn Sie alle Angaben gemacht haben, klicken Sie auf „Weiter“.',
  setWardrobeParametersWidthContentV:
    '(1) Die Breite des Einbauschranks darf nicht weniger als 90 cm betragen.<br>\n' +
    ' Für die Breite des Einbauschranks geben Sie bitte die genauen Raummaße an.<br>\n' +
    '(2) Bei Grundrissen des Typs "A" ist das genaue gemessene Maß der Nischenbreite von Wand zu Wand anzugeben.<br>\n' +
    'Bei diesem Grundriss passt das System das Innere Ihres Einbauschranks automatisch um 1 bis 1,5 cm kleiner an, so dass das Innere des Schranks zwischen zwei feste Wände passt. In diesem Fall haben die Schiebetüren genau die gleichen Abmessungen wie die Wände.<br>\n' +
    '(3) Nachdem Sie Ihre Angaben gemacht haben, klicken Sie auf die Schaltfläche „Weiter“.',

  setWardrobeParametersHeightTitle: 'EINGABE DER HÖHE',
  setWardrobeParametersHeightTitleV: 'Eingabe der Nischenhöhe',

  setWardrobeParametersHeightContentVD:
    '(1) Es ist wichtig, die Höhe des Einbauschranks (vom Boden bis zur Decke) an der Stelle zu messen, an der der Schrank beginnt und an der er endet (linke und rechte Höhe).<br>\n' +
    '(2) Die Höhe des Schrankes auf der linken und rechten Seite muss nicht gleich sein. Das genaue Maß der gemessenen Höhe muss angegeben werden.<br>\n' +
    ' Schiebetüren haben eine Höhenverstellung von bis zu 1,5 cm.<br>\n' +
    '(3) Bei größeren Höhenunterschieden geben Sie bitte die kleinere gemessene Höhe an und spezifizieren die genaue Höhe in der Bestellnotiz.<br>\n' +
    ' Wir werden die richtigen Maße für Sie anpassen, wenn wir Ihre Bestellung prüfen.<br>\n' +
    '(4) Nachdem Sie Ihre Angaben gemacht haben, klicken Sie auf die Schaltfläche „Weiter“.',
  setWardrobeParametersHeightContentD:
    'Aufgrund der Unebenheiten der Decken empfehlen wir, die Höhe der Nische zu messen, in der das Schiebesystem installiert werden soll. Wenn Sie unterschiedliche Höhenmaße angeben, wird die Tür ab der niedrigeren gemessenen Höhe gefertigt. Die obere Schiene muss auf der niedrigsten gemessenen Nischenhöhe verlegt werden. Die Höhendifferenz darf jedoch nicht größer als 1,5 cm sein. Ist die Differenz größer, muss die Oberschiene verlegt und eine neue Höhe in das System eingegeben werden. Nachdem Sie alle Daten eingegeben haben, klicken Sie auf die Schaltfläche „Weiter“.',
  setWardrobeParametersHeightContentV: 'Wenn Sie alle Angaben gemacht haben, klicken Sie auf „Weiter“',

  setWardrobeParametersDepthTitle: 'EINGABE DER SCHRANKTIEFE',
  setWardrobeParametersDepthContentVD:
    '(1) Die Tiefe des Schranks ist die Gesamttiefe des Einbauschranks.<br>\n' +
    '(2) Wenn die Gesamttiefe des Einbauschranks beispielsweise 60 cm beträgt, werden 10 cm vom Schiebesystem eingenommen und der Innenraum des Schranks beträgt 50 cm.<br>\n' +
    '(3) Der separate Innenraum des Einbauschranks ist 10 cm weniger tief.<br>\n' +
    '(4) Das Schiebesystem nimmt 10 cm ein.<br>\n' +
    '(5) Die empfohlene Gesamttiefe des Schrankes beträgt 60-70 cm.<br>\n' +
    '(6) Nachdem Sie Ihre Angaben gemacht haben, klicken Sie auf die Schaltfläche „Weiter“.',
  setWardrobeParametersDepthContentV:
    'Die von uns empfohlene Tiefe des Einbauschranks beträgt 60 cm. Wenn Sie alle Angaben gemacht haben, klicken Sie bitte auf die Schaltfläche „Weiter“',
  setWardrobeParametersDepthContentD:
    'Die von uns empfohlene Tiefe des Einbauschranks beträgt 60 cm. Wenn Sie alle Angaben gemacht haben, klicken Sie bitte auf die Schaltfläche „Weiter“',

  mainSelectDTDVDTitle: 'Innere DTD-Farbe',
  mainSelectDTDVDContent:
    'Im nächsten Schritt können Sie die Farbe der Innenausstattung Ihres Einbauschranks wählen. Klicken Sie auf eines der Holzbilder, um die gewünschte Farbe aus der entsprechenden Liste auszuwählen. Der Preis Ihres Einbauschranks wird je nach der von Ihnen gewählten Farbe der Holzverkleidung neu berechnet. Wenn Sie erneut auf ein anderes Bild der Holzmaserung klicken, haben Sie die Möglichkeit, die ursprünglich gewählte Farbe zu ändern.',

  mainSelectDTDVTitle: 'Innere DTD-Farbe',
  mainSelectDTDVContent:
    'Im nächsten Schritt können Sie die Farbe der Innenausstattung Ihres Einbauschranks wählen. Klicken Sie auf eines der Holzbilder, um die gewünschte Farbe aus der entsprechenden Liste auszuwählen. Der Preis Ihres Einbauschranks wird je nach der von Ihnen gewählten Farbe der Holzverkleidung neu berechnet. Wenn Sie erneut auf ein anderes Bild der Holzmaserung klicken, haben Sie die Möglichkeit, die ursprünglich gewählte Farbe zu ändern.',

  secondSelectDTDVDTitleA: 'Holz-Dekor-Anschlagleiste',
  secondSelectDTDVDTitleB: 'Holz-Dekor der Anschlagleiste und der Seitenwände',
  secondSelectDTDVDTitleC: 'Holz-Dekor der Seitenwände und der Anschlagleiste',
  secondSelectDTDVDTitleD: 'Holz-Dekor der Seitenwände',

  secondSelectDTDVDContent:
    'Wie beim vorherigen Schritt wählen Sie die Farbe der Anschlagleiste, die den sichtbaren Teil Ihres Einbauschranks bilden wird, aus einer Liste von Spanplattenfarben verschiedener Hersteller. Das Kantenband der Anschlagleiste wird in der Farbe der gewählten Holzmaserung ausgeführt.',

  mainSelectDTDDTitleA: 'Holz-Dekor-Anschlagleiste',
  mainSelectDTDDTitleB: 'Holz-Dekor der Anschlagleiste und der Seitenwände',
  mainSelectDTDDTitleC: 'Holz-Dekor der Seitenwände und der Anschlagleiste',
  mainSelectDTDDTitleD: 'Holz-Dekor der Seitenwände',
  mainSelectDTDDContent:
    'Im nächsten Schritt können Sie die Farbe des Äußeren wählen. Klicken Sie auf ein beliebiges Bild der Holz-Dekore, um die Farbe Ihrer Wahl aus der entsprechenden Liste auszuwählen. Der Preis Ihres Einbauschranks wird auf der Grundlage der von Ihnen gewählten Holzmaserung neu kalkuliert. Wenn Sie erneut auf ein anderes Bild der Holzarbeiten klicken, haben Sie die Möglichkeit, die ursprünglich gewählte Farbe zu ändern.',

  controlAndSetHeights: 'Bitte überprüfen Sie die eingegebenen Höhenparameter, um fortzufahren.',

  selectDoorsFillTitle: 'AUSWAHL DER TÜRFÜLLUNGEN',
  selectDoorsFillContentVD:
    '(1) Je nach gewählter Füllung wird der Preis automatisch für Sie neu berechnet, da die Preise von einer Kategorie zur anderen variieren.' +
    '<div style="margin-left:40px;">1) HOLZ-DEKOR</div>' +
    '<div style="margin-left:40px;">2) SPIEGEL</div>' +
    '<div style="margin-left:40px;">3) GlÄSER - LACOBEL</div>' +
    '<div style="margin-left:40px;">4) HOLZ-DEKOR - EXCLUSIVE</div>' +
    '(2) Am preiswertesten sind Kategorie 1 und 2 (Holz-Dekor, Spiegel).<br>' +
    '(3) Die Kategorien 3 und 4 (Lacobel, Holz-Dekor exclusive) sind die luxuriösesten Füllungen, die für die Herstellung von Schiebetüren verwendet werden.<br>' +
    '<span class="text_description">(4). Wenn Sie Ihre Angaben gemacht haben, klicken Sie bitte auf die Schaltfläche „Weiter“.</span>',
  selectDoorsFillContentD:
    '(1) Je nach gewählter Füllung wird der Preis automatisch für Sie neu berechnet, da die Preise von einer Kategorie zur anderen variieren.' +
    '<div style="margin-left:40px;">1) HOLZ-DEKOR</div>' +
    '<div style="margin-left:40px;">2) SPIEGEL</div>' +
    '<div style="margin-left:40px;">3) GlÄSER - LACOBEL</div>' +
    '<div style="margin-left:40px;">4) HOLZ-DEKOR - EXCLUSIVE</div>' +
    '(2) Am preiswertesten sind Kategorie 1 und 2 (Holz-Dekor, Spiegel).<br>' +
    '(3) Die Kategorien 3 und 4 (Lacobel, Holz-Dekor exclusive) sind die luxuriösesten Füllungen, die für die Herstellung von Schiebetüren verwendet werden.<br>' +
    '<span class="text_description">(4). Wenn Sie Ihre Angaben gemacht haben, klicken Sie bitte auf die Schaltfläche „Weiter“.</span>',
  selectCorpusesSetSpacesCountTitle: 'AUSWAHL DER ANZAHL DER TÜREN',
  selectCorpusesSetSpacesCountTitleV: 'Wahl der Anzahl der Segmente',
  selectCorpusesSetSpacesCountContent:
    'Wählen Sie unten auf der Seite die Anzahl der Türen. Sobald Sie die Anzahl der Türen eingegeben haben, klicken Sie auf die Schaltfläche „Weiter“, um fortzufahren.',
  selectCorpusesSetSpacesCountContentV:
    'Wählen Sie unten auf der Seite die Anzahl der Fächer für Ihren zukünftigen Einbauschrank. Nach dem Ausfüllen klicken Sie bitte auf die Schaltfläche „Weiter“.',

  selectCorpusesTitle: 'GESTALTUNG DES INNENBEREICHS DES SCHRANKS',
  selectCorpusesContent:
    '(1) Sie können die Innenausstattung der Schränke nach unseren vordefinierten Kombinationen auswählen oder Ihre eigene Innenausstattung entwerfen.<br>(2) Alle Abmessungen der Innenmodule sind nach Abzug des Materials angegeben (Nettoabmessungen).<br>(3) Indem Sie das Feld "geteilt" markieren, können Sie das Segment weiter in ½" unterteilen.<br>4. Das Innere des Schranks ist aus 1,8 cm dickem Material gefertigt.<br>5. Nachdem Sie Ihre Angaben gemacht haben, klicken Sie auf die Schaltfläche „Weiter“.<br><br>LIMIT: Schubladen können nur in einem Abstand von 45 cm verwendet werden<br><br>PROZEDUR: Durch Markieren der einzelnen Fächer (grün hervorgehoben) können Sie das Innenleben des Einbauschrankes aus der folgenden Liste auswählen. Das gleiche Verfahren gilt für alle anderen Segmente.  Wenn Sie eines der Felder neu markieren, haben Sie die Möglichkeit, eine Änderung nach Ihren Bedürfnissen vorzunehmen. Wenn Sie das Segment aufteilen möchten, klicken Sie auf "Segment aufteilen" und kombinieren Sie dann Ihren Einbauschrank.',

    basicInformationTitle: 'GRUNDLEGENDE INFORMATIONEN',
    basicInformationContent: 'Alle Teile sind bereits fertig montiert. Die Ober- und Unterschiene müssen von Ihnen lediglich noch befestigt werden. Material (Schrauben, Dübel etc.) dazu liegt dieser Bestellung nicht bei.',

  setOrderTitle: '',
  setOrderContent: '',

    deliveryAndDispatchTitle: "LIEFERUNG UND VERSAND IHRER SCHIEBETÜREN - VERFAHREN",
    deliveryAndDispatchContent: '(1) Im Normalfall dauert es ca. 10 Arbeitstage nach Bezahlung, um Ihre Schiebetüren herzustellen.<br>'
+ '(2) Sobald dies geschehen ist, werden Sie von uns bzw. einer von uns beauftragten Spedition telefonisch kontaktiert, um einen Liefertermin zu vereinbaren.<br>'
+ '(3) Sie werden nochmals einen Tag vor Lieferung angerufen, außerdem am Liefertag ca. 1 Stunde vor Lieferung, damit Sie sich gegebenenfalls an den Lieferort begeben können.<br>'
+ '(4) Der Aufbau der Schiebetüren wird von Ihnen vorgenommen. Auf Wunsch nimmt der Fahrer das wenige Verpackungsmaterial (Kantenschutz und Folie) wieder mit.',

  selectDTDInTitle: 'WAHL DER DEKORFARBE FÜR DIE INNENSEITE EINES EINBAUSCHRANKS',
  selectDTDInContent:
    '(1) Wir empfehlen die Innenausstattung des Einbauschrankes in der Farbe „08681 Brillantweiß“<br>' +
    ' - Dies ist eine matte schneeweiße Farbe mit einer leicht samtigen Laminat-Oberflächenstruktur.<br>' +
    ' - Sie ist kostengünstig und, da er durch Schiebetüren abgedeckt ist, auch gestalterisch ausreichend.<br>' +
    '(2) Wenn Sie auf ein beliebiges Dekorbild klicken, berechnet das System automatisch den Preis des Schranks in dem angegebenen DTD-Dekor neu, wobei der Preis von Dekor zu Dekor variiert.<br>' +
    '(3) 3. Die Materialstärke des Innenraums des Einbauschranks beträgt 1,8 cm.<br>' +
    '(4) Wenn Sie Ihre Angaben gemacht haben, klicken Sie bitte auf die Schaltfläche „Weiter“.',

  selectDTDOutTitle: 'WAHL DER DEKORFARBE FÜR DIE ANSCHLAGLEISTEN',
  selectDTDOutContent:
    '(1) Wie beim vorherigen Schritt wählen Sie die Farbe der Anschlagleiste aus einer Liste von verschiedenen Dekoren (Farben)<br>' +
    '(2) Klicken Sie auf ein beliebiges Dekorbild, und das System berechnet automatisch den Preis Ihres Produkts neu, wobei der Preis von Dekor zu Dekor variieren kann.<br>' +
    '(3) Die Materialstärke der Anschlagleiste beträgt 1,8cm.<br>' +
    '(4) Wenn Sie Ihre Angaben gemacht haben, klicken Sie bitte auf die Schaltfläche „Weiter“.'
}
