import React, { useMemo } from 'react'
import { MeshBasicMaterial } from 'react-three-fiber/components'

/**
 * @param children
 * @param {Object} roomAttributes
 * @param {Object} wardrobeAttributes
 * @param {String} wallPosition
 * @param {Boolean} useBackWalls
 * @returns {JSX.Element}
 * @constructor
 */
const WhiteWall = ({ children, roomAttributes, wardrobeAttributes, wallPosition, useBackWalls }) => {
  const roomType = roomAttributes.type
  const roomWidth = roomAttributes.width
  const roomHeight = roomAttributes.height
  const roomDepth = roomAttributes.depth
  const wardrobeDepth = wardrobeAttributes.depth

  const wallWidth = useMemo(() => {
    let width = roomDepth

    if (wallPosition === 'back' || wallPosition === 'front') {
      width = roomWidth
    } else if ((wallPosition === 'left' || wallPosition === 'right') && useBackWalls) {
      width -= wardrobeDepth
    }

    return width
  }, [wallPosition, roomWidth, roomDepth, useBackWalls, wardrobeDepth])

  const positionX = useMemo(() => {
    let posX = 0

    if (wallPosition === 'back' || wallPosition === 'front') {
      posX = roomType === 'c' || roomType === 'd' || roomType === 'a' || roomAttributes.backWallType === 'b' ? -100 : 0
    } else {
      if (wallPosition === 'left') {
        posX = 0 - roomWidth / 2
      } else {
        posX = roomWidth / 2
      }

      if (roomType === 'c' || roomType === 'd' || roomType === 'a' || roomAttributes.backWallType === 'b') {
        posX -= 100
      }
    }

    return posX
  }, [wallPosition, roomType, roomWidth, roomAttributes.backWallType])

  let positionZ = useMemo(() => {
    let posZ = roomDepth / 2 - wardrobeDepth / 2

    if (wallPosition === 'back') {
      posZ = 0 - wardrobeDepth / 2
    } else if ((wallPosition === 'left' || wallPosition === 'right') && useBackWalls) {
      posZ += wardrobeDepth / 2
    }

    return posZ
  }, [wallPosition, wardrobeDepth, roomDepth, useBackWalls])

  if (wallPosition === 'front') {
    positionZ = roomDepth - wardrobeDepth / 2
  }

  const rotation = useMemo(() => {
    let rotY = 0
    let rotX = 0

    if (wallPosition !== 'back' && wallPosition !== 'front') {
      if (wallPosition === 'left') {
        rotX = Math.PI / 2
        rotY = Math.PI * 2
      } else {
        rotX = Math.PI / 2 + Math.PI
      }
    } else if (wallPosition === 'front') {
      rotX = Math.PI
    }

    return [rotY, rotX, 0]
  }, [wallPosition])

  return (
    <group position={[positionX, 0, positionZ]} rotation={rotation}>
      {children}
      <mesh>
        <planeGeometry attach='geometry' args={[wallWidth, roomHeight]} />
        <MeshBasicMaterial attach='material' color='#fff' />
      </mesh>
    </group>
  )
}

export default WhiteWall
