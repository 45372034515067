import * as THREE from 'three'
import React from 'react'
import { BOARD_DEPTH } from '../../../config'
import getMaterial from '../../Profile/material'

/**
 * @param doorAttributes
 * @param roomAttributes
 * @returns {*}
 * @constructor
 */
const WindowBottom = ({ doorAttributes, wardrobeAttributes }) => {
  const objectMaterial = getMaterial(doorAttributes['profile'])
  const objectMaterial2 = getMaterial(doorAttributes['profile'])

  objectMaterial2.metalness = objectMaterial.metalness + 0.1

  const width = wardrobeAttributes['width'] - BOARD_DEPTH * 2
  const posX = BOARD_DEPTH + width / 2
  const rotation = [Math.PI / 2, 0, 0]

  const basicObject = new THREE.Mesh(new THREE.PlaneGeometry(width, 7.5), objectMaterial2)

  const bottomObject = new THREE.Mesh(new THREE.BoxGeometry(width, 3.2, 3.7), objectMaterial)

  return (
    <group position-y={0.1} position-x={posX} position-z={1} rotation={rotation}>
      <mesh>
        <primitive object={basicObject} />
      </mesh>
      <mesh position-z={-1.8} position-y={-2.6}>
        <primitive object={bottomObject} />
      </mesh>
    </group>
  )
}

export default WindowBottom
