import React, { useMemo, useRef, useState } from 'react'
import { useFrame } from 'react-three-fiber'
import Type from '../Profile/TypeA'
import { DoorContent } from './DoorContent'
import { SelectDoor } from './SelectDoor'
import useStore, { getState } from '../../store'
import { useDrag } from 'react-use-gesture'
import useSteps from '../../Stores/Steps'

/**
 * @param {Number} doorsCount
 * @param {Object} doorAttributes
 * @param {Object} wardrobeAttributes
 * @param {Object} previewAttributes
 * @param {Number} id
 * @param {Number} posY
 * @param {Number} posX
 * @returns {JSX.Element}
 * @constructor
 */

export const Door = ({ doorsCount, doorAttributes, wardrobeAttributes, previewAttributes, id, posY, posX }) => {
  const group = useRef()
  const selectedDoorSpacesCount = useStore((state) => state.selectedDoorsSpace[id])
  const doorSpacesCount = selectedDoorSpacesCount ?? 1
  const spaceDepth = wardrobeAttributes['depth']
  const doorHeight = doorAttributes['height']
  const doorWidth = doorAttributes['width']
  const main = useRef()
  const [, setMove] = useState(false)
  const [moveMX, setMX] = useState(0)
  const [position, setPosition] = useState([0, 0, 0])
  const spacesCount = useStore((state) => state.spacesCount)
  const cameraLocked = useStore((state) => state.cameraLocked)
  const setCameraLocked = getState().setCameraLocked
  const actualStep = useSteps((state) => state.actualStep)

  const bind = useDrag(
    ({ down, movement: [mx] }) => {
      const [, y, z] = position

      setCameraLocked(down)
      setMove(down)

      if (id === 1 && mx < 0) {
        mx = 0
      } else if (spacesCount && id === spacesCount && mx > 0) {
        mx = 0
      }

      const maxMx = doorWidth - 3
      const minMx = 3 - doorWidth

      if (down && mx < maxMx && mx > minMx) {
        setPosition([mx, y, z])
        setMX(moveMX)
      }
    },
    {
      eventOptions: { pointer: true }
    }
  )

  const startPosition = useMemo(
    () => (id === 1 && actualStep === 'setWardrobeParameters' ? doorWidth * 0.5 : 0),
    [id, actualStep, doorWidth]
  )

  useFrame(() => {
    if (!cameraLocked) {
      if (Math.abs(startPosition - main.current.position.x) < 1.5) {
        main.current.position.x = startPosition
      } else if (main.current.position.x < startPosition) {
        main.current.position.x += 1
      } else if (main.current.position.x > startPosition) {
        main.current.position.x -= 1
      }
    }
  })

  let posZ = spaceDepth / 2
  const version = doorsCount === 3 ? 0 : 1

  posZ -= id % 2 === version ? 3.5 : 7

  return (
    <group ref={group} position-y={posY + doorHeight / 2 + 2} position-x={posX} position-z={posZ}>
      <group ref={main} position={position} {...bind()}>
        {doorSpacesCount && <Type type='left' doorAttributes={doorAttributes} />}
        <DoorContent doorId={id} count={doorSpacesCount} doorAttributes={doorAttributes} previewAttributes={previewAttributes} />
        {doorSpacesCount && <Type type='right' doorAttributes={doorAttributes} />}
        {actualStep === 'selectDoorSpacesCount' && previewAttributes.showSelected && (
          <SelectDoor doorId={id} doorAttributes={doorAttributes} />
        )}
      </group>
    </group>
  )
}
