import React, { useCallback } from 'react'
import { getState } from '../../../../store'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { getDoorFillId } from '../../../../utils/door'

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = () => ({})

const useStyles = makeStyles((theme) => ({
  leftGrid: {
    paddingRight: 20
  },
  rightGrid: {
    //paddingTop: 10,
  },
  boxMain: {
    backgroundColor: '#ffffff'
  },
  mainContent: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  leftBorder: {
    width: 10,
    background: 'linear-gradient(90deg, #6a6a6a, #cccccc 50%, #dbdbdb 100%)',
    border: '1px solid ' + theme.palette.grey[500]
  },
  rightBorder: {
    width: 10,
    background: 'linear-gradient(90deg, #dbdbdb, #cccccc 50%, #6a6a6a 100%)',
    border: '1px solid ' + theme.palette.grey[500]
  },
  mainPadding: {
    paddingTop: 20
  },
  mainCenter: {
    width: 200
  },
  topLine: {
    zIndex: 1,
    width: '100%',
    background:
      'linear-gradient(90deg, ' +
      theme.palette.grey[500] +
      ', ' +
      theme.palette.grey[400] +
      '  5%, ' +
      theme.palette.grey[300] +
      ' 50%, ' +
      theme.palette.grey[400] +
      ' 95%, ' +
      theme.palette.grey[500] +
      ')',
    borderTop: '1px solid ' + theme.palette.grey[500],
    borderBottom: '1px solid ' + theme.palette.grey[500]
  },
  bottomLine: {
    zIndex: 1,
    width: '100%',
    background:
      'linear-gradient(90deg, ' +
      theme.palette.grey[500] +
      ', ' +
      theme.palette.grey[400] +
      '  5%, ' +
      theme.palette.grey[300] +
      ' 50%, ' +
      theme.palette.grey[400] +
      ' 95%, ' +
      theme.palette.grey[500] +
      ')',
    borderTop: '1px solid ' + theme.palette.grey[500],
    borderBottom: '1px solid ' + theme.palette.grey[500]
  },
  content: {
    background: 'linear-gradient(90deg, ' + theme.palette.grey[100] + ', ' + theme.palette.grey[300] + ')'
  },
  mainGrid: {
    justifyContent: 'center'
  },
  selectedSpace: {
    width: '100%',
    height: '100%',
    zIndex: 2,
    '&:hover': {
      backgroundColor: '#80FF80'
    }
  }
}))

function getDoorFillStyle(doorFillsCount, showDoorsFill, height, doorFills, productImages, selectedSpaceId, index) {
  let productId = null
  let image = null
  const doorFillId = getDoorFillId(selectedSpaceId, doorFillsCount, index)

  if (doorFills[doorFillId]) {
    productId = doorFills[doorFillId].productId

    if (productImages && productImages[productId]) {
      image = '/images/products/' + productImages[productId]
    }
  }

  if (image && showDoorsFill) {
    return { height: height, backgroundImage: 'url(' + process.env.PUBLIC_URL + image + ')' }
  } else {
    return { height: height }
  }
}

function getDoorFillBorderStyle(doorFillsCount, type, style, showDoorsFill, selectedDoorFillId, doorFills, selectedSpaceId, index) {
  let className = ''

  if (showDoorsFill || type === 'Bottom') {
    className = style
  }

  const doorFillId = getDoorFillId(selectedSpaceId, doorFillsCount, index)

  if (selectedDoorFillId === doorFillId && showDoorsFill) {
    className += ' blinkBorders' + type

    if (!doorFills[doorFillId]) {
      className += 'Empty'
    }
  }

  return className
}

function a11yProps(onSelectSpace, onClickSelectSpace) {
  if (onSelectSpace) {
    return {
      onPointerDown: onClickSelectSpace
    }
  } else {
    return {}
  }
}

const DoorContent = ({ productImages, showDoorsFill, selectedSpaceId, count, height, lineWidth, onSelectSpace, width = null }) => {
  const doorFills = getState().doorFills
  const selectedDoorFillId = getState().selectedDoorFillId
  const classes = useStyles()
  const spaces = []
  const topLineHeight = lineWidth
  const bottomLineHeight = topLineHeight * 2.5
  const contentHeight = height - bottomLineHeight
  const mainCenterWidth = width ?? height / 3

  if (!count) count = 1

  for (let num = 0; num < count; num++) {
    spaces.push({ id: num, height: contentHeight / count, topLineHeight: topLineHeight })
  }

  const doorFillsCount = count

  const onClickSelectSpace = useCallback(
    (e, doorFillId) => {
      e.stopPropagation()

      if (onSelectSpace) {
        onSelectSpace(doorFillId)
      }
    },
    [onSelectSpace]
  )

  return (
    <div className={classes.mainCenter} style={{ width: mainCenterWidth }}>
      <Grid container direction='column' justify='flex-start' alignItems='stretch'>
        {spaces.map((space, index) => (
          <Grid
            item
            key={'contentDoor' + space.id}
            className={getDoorFillBorderStyle(
              doorFillsCount,
              'Bottom',
              classes.content,
              showDoorsFill,
              selectedDoorFillId,
              doorFills,
              selectedSpaceId,
              index
            )}
            style={getDoorFillStyle(doorFillsCount, showDoorsFill, space.height, doorFills, productImages, selectedSpaceId, index)}
            {...a11yProps(onSelectSpace, (e) => onClickSelectSpace(e, getDoorFillId(selectedSpaceId, doorFillsCount, space.id)))}
          >
            <div className={classes.topLine} style={{ height: space.topLineHeight }} />
            <div
              className={getDoorFillBorderStyle(
                doorFillsCount,
                'Content',
                classes.selectedSpace,
                showDoorsFill,
                selectedDoorFillId,
                doorFills,
                selectedSpaceId,
                index
              )}
            />
          </Grid>
        ))}
        <Grid item className={classes.content}>
          <div className={classes.bottomLine} style={{ height: bottomLineHeight }}></div>
        </Grid>
      </Grid>
    </div>
  )
}

const LeftBorder = ({ lineWidth, height }) => {
  const classes = useStyles()

  return (
    <Grid item>
      <div className={classes.leftBorder} style={{ width: lineWidth, height: height }} />
    </Grid>
  )
}

const RightBorder = ({ lineWidth, height }) => {
  const classes = useStyles()

  return (
    <Grid item>
      <div className={classes.rightBorder} style={{ width: lineWidth, height: height }} />
    </Grid>
  )
}

class Door extends React.Component {
  render() {
    let paddingTop = 0
    let height = this.props.height

    if (this.props.isBehind) {
      const onePercent = this.props.height / 200
      height = height - onePercent * 2
      paddingTop = onePercent / 2
    }

    const lineWidth = (height / 100) * 2
    const showLeftBorder = this.props.isBehind && !this.props.isFirst ? false : true
    const showRightBorder = this.props.isBehind && !this.props.isLast ? false : true

    return (
      <Grid
        wrap='nowrap'
        container
        direction='row'
        justify='flex-start'
        alignItems='stretch'
        style={{ paddingTop: paddingTop, justifyContent: 'center' }}
      >
        {showLeftBorder && <LeftBorder lineWidth={lineWidth} height={height} />}
        <Grid item>
          <DoorContent
            productImages={this.props.productImages?.large}
            showDoorsFill={this.props.showDoorsFill}
            selectedSpaceId={this.props.selectedSpaceId}
            count={this.props.count}
            height={this.props.height}
            width={this.props.width}
            lineWidth={lineWidth}
            onSelectSpace={this.props.onSelectSpace}
          />
        </Grid>
        {showRightBorder && <RightBorder lineWidth={lineWidth} height={height} />}
      </Grid>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Door)
