import React from 'react'
import VerticalSidebar from '../Components/Molecules/VerticalSidebar'
import { Lang } from '../../../Utils'
import TopMenu from '../Menu/TopMenu'
import { Box, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MiddleMenu from '../Menu/MiddleMenu'
import SiteAppBar from '../Menu/SiteAppBar'
import { useHistory, useParams } from 'react-router-dom'
import LoadObj from '../Components/Molecules/LoadObj'
import { setStep } from '../../../utils/steps'

const useStyles = makeStyles(() => ({
  mainBox: {
    paddingTop: 20,
    paddingBottom: 20
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

/**
 * @returns {*}
 * @constructor
 */
const LoadStep = ({ fullScreenHandle, onClickSteps }) => {
  const classes = useStyles()
  const { objId, APIUUId } = useParams()
  const history = useHistory()

  setStep('load')

  return (
    <>
      <TopMenu mainStep='roomType' />
      <MiddleMenu fullScreenHandle={fullScreenHandle} onClickSteps={onClickSteps} />
      <Paper>
        <SiteAppBar title={Lang('Loading')} onClickSteps={onClickSteps} />
        <Box className={classes.mainBox}>
          <LoadObj objId={objId} APIUUId={APIUUId} classes={classes} history={history} />
        </Box>
      </Paper>
      <VerticalSidebar onClickSteps={onClickSteps} />
    </>
  )
}

export default LoadStep
