import React, { useMemo } from 'react'
import { MeshBasicMaterial } from 'react-three-fiber/components'

/**
 * @param {Object} roomAttributes
 * @param {Object} wardrobeAttributes
 * @param {Boolean} useBackWalls
 * @returns {JSX.Element}
 * @constructor
 */
const Ceiling = ({ roomAttributes, wardrobeAttributes, useBackWalls }) => {
  const roomType = roomAttributes.type
  const roomWidth = roomAttributes.width
  const roomHeight = roomAttributes.height
  const roomDepth = roomAttributes.depth
  const wardrobeDepth = wardrobeAttributes.depth

  const positionX = useMemo(
    () => (roomType === 'c' || roomType === 'd' || roomType === 'a' || roomAttributes.backWallType === 'b' ? -100 : 0),
    [roomType, roomAttributes.backWallType]
  )
  const positionY = useMemo(() => roomHeight / 2, [roomHeight])
  let positionZ = useMemo(() => roomDepth / 2 - wardrobeDepth / 2, [roomDepth, wardrobeDepth])

  let floorHeight = roomDepth

  if (useBackWalls) {
    floorHeight -= wardrobeDepth
    positionZ += wardrobeDepth / 2
  }

  return (
    <mesh position={[positionX, positionY, positionZ]} rotation={[Math.PI / 2, 0, 0]}>
      <planeGeometry attach='geometry' args={[roomWidth, floorHeight]} />
      <MeshBasicMaterial attach='material' color='#fff' />
    </mesh>
  )
}

export default React.memo(Ceiling)
