import React, { useCallback, useMemo } from 'react'
import useStore, { getState } from '../../../store'
import useSteps from '../../../Stores/Steps'
import { Sticky } from 'semantic-ui-react'
import { Lang } from '../../../Utils'
import '../../../App.css'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Button, Box } from '@material-ui/core'
import CorpusesStepper from '../Components/Molecules/CorpusesStepper'
import { getCorpusesSpaces } from '../../../utils/corpuses'
import { getSpaceWidth } from '../../../Space'

const useStyles = makeStyles(() => ({
  buttonBox: {
    paddingBottom: 12
  }
}))

/**
 * @returns {*}
 * @constructor
 */
const SelectCorpuses = ({ contextRef }) => {
  const classes = useStyles()
  let selectedSpace = useStore((state) => state.selectedSpace)
  const setSpace = getState().setSpace
  const spacesCount = useStore((state) => state.spacesCount)
  const activeTab = useSteps((state) => state.activeTab['selectCorpuses'])
  const dividedSpaces = JSON.parse(useStore((state) => state.dividedSpaces))
  const setSpaceDivided = getState().setSpaceDivided

  if (!selectedSpace) selectedSpace = 1

  const roomWidth = getState().width
  const roomType = getState().roomType
  const objType = getState().objType
  const spaceWidth = getSpaceWidth(objType, roomWidth, spacesCount, roomType)

  const { isDivided, selectedSpaceId } = useMemo(() => {
    return getCorpusesSpaces(spaceWidth, spacesCount, dividedSpaces, selectedSpace)
  }, [spaceWidth, spacesCount, dividedSpaces, selectedSpace])

  const onClickDivideSpace = useCallback(
    (e, seq, isDivided) => {
      e.stopPropagation() // stop it at the first intersection
      setSpaceDivided(selectedSpaceId, !isDivided)
    },
    [setSpaceDivided, selectedSpaceId]
  )

  const handleNext = useCallback(
    (e, nextId) => {
      e.stopPropagation()
      setSpace(nextId)
    },
    [setSpace]
  )

  const handleBack = useCallback(
    (e, backId) => {
      e.stopPropagation()
      setSpace(backId)
    },
    [setSpace]
  )

  const showDivedButton = spaceWidth < 100 && spacesCount > 1

  if (activeTab === '2') {
    if (contextRef) {
      return (
        <Sticky context={contextRef} offset={50}>
          <Paper>
            <CorpusesStepper selectedSpace={selectedSpace} handleNext={handleNext} handleBack={handleBack} />
            {showDivedButton && (
              <Box textAlign='center' className={classes.buttonBox}>
                <Button
                  disabled={false}
                  variant='contained'
                  color='secondary'
                  onPointerDown={(e) => onClickDivideSpace(e, selectedSpace, isDivided)}
                >
                  {Lang(isDivided ? 'DivideSpaceOff' : 'DivideSpaceOn')}
                </Button>
              </Box>
            )}
          </Paper>
        </Sticky>
      )
    } else {
      return (
        <Paper>
          <CorpusesStepper selectedSpace={selectedSpace} handleNext={handleNext} handleBack={handleBack} cameraZ={200} />
          {showDivedButton && (
            <Box textAlign='center' className={classes.buttonBox}>
              <Button
                disabled={false}
                variant='contained'
                color='secondary'
                onPointerDown={(e) => onClickDivideSpace(e, selectedSpace, isDivided)}
              >
                {Lang(isDivided ? 'DivideSpaceOff' : 'DivideSpaceOn')}
              </Button>
            </Box>
          )}
        </Paper>
      )
    }
  } else {
    return <></>
  }
}

export default SelectCorpuses
