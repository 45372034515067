import React, { useCallback, useState } from 'react'
import { getState } from '../../../store'
import useSteps, { getSteps } from '../../../Stores/Steps'
import { makeStyles, Modal, Box, Grid, Button, TextField } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Lang } from '../../../Utils'
import Alert from '@material-ui/lab/Alert'
import ErrorDialog from '../Components/Molecules/ErrorDialog'
import NumberFormat from 'react-number-format'
import { isEmailAddressValid } from '../../../utils/control'
import { getOrder } from '../../../Stores/Order'

const useStyles = makeStyles((theme) => ({
  form: {
    paddingBottom: 20
  },
  mainBox: {
    paddingTop: 10,
    paddingBottom: 10
  },
  infoBox: {
    width: 330,
    paddingTop: 10,
    paddingBottom: 10
  },
  errorBox: {
    paddingTop: 10
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  mainGridCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainGridRight: {
    textAlign: 'right'
  },
  buttonActiveText: {
    paddingBottom: 0,
    marginBottom: 0
  },
  buttonActiveCaption: {
    margin: 0,
    padding: 0,
    fontSize: 12
  },
  previewButtonActive: {
    textTransform: 'none'
  },
  nextButtonActive: {
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
      boxShadow: 'none'
    },
    '&:active': {
      boxShadow: 'none'
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)'
    }
  }
}))

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props

  const attr = {
    defaultValue: process.env.REACT_APP_PHONE_NUMBER_CODE,
    ...(process.env.REACT_APP_PHONE_NUMBER_FORMAT && { format: process.env.REACT_APP_PHONE_NUMBER_FORMAT })
  }

  return (
    <NumberFormat
      {...other}
      {...attr}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      isNumericString
    />
  )
}

/**
 * @returns {*}
 * @constructor
 */
const PhoneSave = ({ orContactUs, onSavePhoneEmail }) => {
  const classes = useStyles()
  const [errorTexts, setErrorText] = useState({})
  const [isFirstOpen, setIsFirstOpen] = useState(true)
  const isAttributesModalOpen = useSteps((state) => state.isModalOpen['phoneSave'])
  const closeModal = getSteps().closeModal
  //const openModal = getSteps().openModal;
  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const phoneNumber = getState().phoneNumber
  const emailAddress = getState().emailAddress
  const setPhoneNumber = getState().setPhoneNumber
  const setEmailAddress = getState().setEmailAddress
  const setPriceVersion = getState().setPriceVersion
  const isSaveOrderAvailable = process.env.REACT_APP_IS_SAVE_ORDER_AVAILABLE === 'true'

  const [inputPhoneValue, setInputPhoneValue] = useState(phoneNumber)
  const [inputEmailValue, setInputEmailValue] = useState(emailAddress)

  const handleClose = () => {
    closeModal('phoneSave')
    setIsFirstOpen(false)
  }

  /*
    const handleOpen = useCallback(
        () => {
            openModal('phoneSave');
            setInputPhoneValue(phoneNumber);
            setInputEmailValue(emailAddress);
        },
        [openModal, setInputPhoneValue, setInputEmailValue, phoneNumber, emailAddress]
    );
    */

  const onClickSave = useCallback(() => {
    const errorTexts = {}

    if (isSaveOrderAvailable && inputEmailValue && !isEmailAddressValid(inputEmailValue)) {
      errorTexts['email'] = Lang('BadEmailFormatError', 'inputs')
    }

    if (!isSaveOrderAvailable && (!inputPhoneValue || inputPhoneValue.length === 0)) {
      errorTexts['phone'] = Lang('InputCanNotBeEmpty', 'inputs').replace('{0}', Lang('Phone'))
    }

    if (Object.keys(errorTexts).length > 0) {
      setErrorText(errorTexts)
      setOpenErrorDialog(true)
    } else {
      setPhoneNumber(inputPhoneValue)
      setEmailAddress(inputEmailValue)
      setPriceVersion()
      getOrder().setInsert(true)
      onSavePhoneEmail()
      closeModal('phoneSave')
    }
  }, [
    isSaveOrderAvailable,
    setOpenErrorDialog,
    setErrorText,
    closeModal,
    inputPhoneValue,
    inputEmailValue,
    setPriceVersion,
    onSavePhoneEmail,
    setPhoneNumber,
    setEmailAddress
  ])

  const openDialogErrors = []

  if (errorTexts) {
    if (errorTexts['email']) {
      openDialogErrors.push(errorTexts['email'])
    }
    if (errorTexts['phone']) {
      openDialogErrors.push(errorTexts['phone'])
    }
  }

  const handleChangePhone = useCallback(
    (event) => {
      setInputPhoneValue(event.target.value)

      if (!isSaveOrderAvailable) {
        errorTexts['email'] = ''
        setErrorText(errorTexts)
      }
    },
    [setPhoneNumber, isSaveOrderAvailable]
  )

  const handleChangeEmail = useCallback(
    (event) => {
      setInputEmailValue(event.target.value)
      errorTexts['email'] = ''
      setErrorText(errorTexts)
    },
    [setInputEmailValue, setErrorText, errorTexts]
  )

  const handleBlurPhone = useCallback(
    (event) => {
      if (!setInputEmailValue) {
        errorTexts['phone'] = ''

        if (event.target.value !== '') {
          errorTexts['phone'] = Lang('InputCanNotBeEmpty', 'inputs').replace('{0}', Lang('Phone'))
        }

        setErrorText(errorTexts)
      }
    },
    [setInputEmailValue, setErrorText, errorTexts]
  )

  const handleBlurEmail = useCallback(
    (event) => {
      errorTexts['email'] = ''

      if (event.target.value !== '' && !isEmailAddressValid(event.target.value)) {
        errorTexts['email'] = Lang('BadEmailFormatError', 'inputs')
      }

      setErrorText(errorTexts)
    },
    [setErrorText, errorTexts]
  )

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={isAttributesModalOpen ?? isFirstOpen}
        onClose={handleClose}
        closeAfterTransition
        disableEnforceFocus
        disableBackdropClick
      >
        <div className={classes.paper}>
          <form className={classes.form} noValidate autoComplete='off'>
            <Box className={classes.infoBox}>
              <Alert severity='info'>
                {Lang('SavePhoneInfo', 'info')}
                {orContactUs.split(/[\r\n]+/).map((line, index) => {
                  if (index === 0) {
                    return <>{line}</>
                  } else {
                    return <div key={index}>{line}</div>
                  }
                })}
              </Alert>
            </Box>
            <TextField
              label={Lang('Phone')}
              value={inputPhoneValue}
              onChange={handleChangePhone}
              onBlur={handleBlurPhone}
              name='phone'
              id='phone-input'
              InputProps={{
                inputComponent: NumberFormatCustom
              }}
            />
            {isSaveOrderAvailable && (
              <>
                <Box className={classes.infoBox}>
                  <Alert severity='info'>{Lang('SaveEmailInfo', 'info')}</Alert>
                </Box>
                <TextField
                  error={errorTexts['email']}
                  label={Lang('Email')}
                  value={inputEmailValue}
                  onChange={handleChangeEmail}
                  onBlur={handleBlurEmail}
                  name='emailAddress'
                  id='email-address-input'
                  helperText={errorTexts['email']}
                />
              </>
            )}
          </form>
          <Grid container spacing={3}>
            <Grid item xs>
              <Button variant='outlined' className={classes.previewButtonActive} onClick={handleClose}>
                <div className={classes.buttonActiveDiv}>
                  <Typography className={classes.buttonActiveCaption}>{Lang('Skip')}</Typography>
                </div>
              </Button>
            </Grid>
            <Grid item xs className={classes.mainGridRight}>
              <Button variant='contained' color='primary' onClick={() => onClickSave()} className={classes.nextButtonActive}>
                <Typography className={classes.buttonActiveText}>{Lang('Save')}</Typography>
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <ErrorDialog openDialogErrors={openDialogErrors} openErrorDialog={openErrorDialog} setOpenErrorDialog={setOpenErrorDialog} />
    </>
  )
}

export default PhoneSave
