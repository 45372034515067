import React from 'react'
import { Tab } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Lang } from '../../../Utils'

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = () => ({})

class SelectDTDType extends React.Component {
  state = {
    activeIndex: 0
  }

  render() {
    const panes = [
      { menuItem: Lang('DTDMainTabName'), render: () => <></> },
      { menuItem: Lang('DTDSecondTabName'), render: () => <></> }
    ]

    return (
      <>
        <Tab
          activeIndex={this.props.activeIndex}
          menu={{
            attached: true,
            tabular: true,
            size: this.props.mainSize,
            style: {
              paddingLeft: 20,
              display: 'flex'
            }
          }}
          panes={panes}
          onTabChange={this.props.handleTabChange}
        />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectDTDType)
