import { DEFAULT_ORDERS, DEFAULT_ORDER_TYPE } from '../config'

/**
 * @returns {string[]}
 */
export function getOrderTypes() {
  const orders_type_string = process.env.REACT_APP_CREATE_ORDER ?? DEFAULT_ORDERS

  return orders_type_string.split(', ')
}

/**
 * @returns {boolean}
 */
export function getCountOrderTypes() {
  const orderTypes = getOrderTypes()

  return orderTypes.length
}

/**
 * @returns {boolean}
 */
export function isSelectOrderTypeAvailable() {
  const orderTypesCount = getCountOrderTypes()

  return orderTypesCount > 1
}

export function getDefaultOrderType() {
  const orderTypesCount = getCountOrderTypes()

  if (orderTypesCount === 1) {
    return process.env.REACT_APP_CREATE_ORDER
  } else {
    return process.env.REACT_APP_DEFAULT_ORDER_TYPE ?? DEFAULT_ORDER_TYPE
  }
}
