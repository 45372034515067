import * as THREE from 'three'
import React, { useMemo } from 'react'
import Windows from './Windows'

/**
 * @param children
 * @param {Object} roomAttributes
 * @param {Object} wardrobeAttributes
 * @param {String} wallPosition
 * @param {Number} windowWidth
 * @param {Boolean} useBackWalls
 * @returns {JSX.Element}
 * @constructor
 */
const Wall = ({ children, roomAttributes, wardrobeAttributes, wallPosition, windowWidth, useBackWalls }) => {
  const roomType = roomAttributes.type
  const roomWidth = roomAttributes.width
  const roomHeight = roomAttributes.height
  const roomDepth = roomAttributes.depth
  const wardrobeDepth = wardrobeAttributes.depth
  const wallColor = roomAttributes.wallColor ? roomAttributes.wallColor : null

  const wallWidth = useMemo(() => {
    let width = roomDepth

    if (wallPosition === 'back' || wallPosition === 'front') {
      width = roomWidth
    } else if ((wallPosition === 'left' || wallPosition === 'right') && useBackWalls) {
      width -= wardrobeDepth
    }

    return width
  }, [wallPosition, roomWidth, roomDepth, useBackWalls, wardrobeDepth])

  const repeatNum = useMemo(() => 10 + wallWidth / 50, [wallWidth])

  const repeatTexture = (texture, repeatNum) => {
    texture.wrapS = THREE.RepeatWrapping
    texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(repeatNum, 20)

    return texture
  }

  const wallImgDiffuseTex = useMemo(
    () => new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Wall/Rough_Painted_Wall_001_OCC_Small.jpg'),
    []
  )
  const bumpMapTex = useMemo(
    () => new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Wall/Rough_Painted_Wall_001_DISP_Small.jpg'),
    []
  )
  const lightMapTex = useMemo(
    () => new THREE.TextureLoader().load(process.env.PUBLIC_URL + '/images/Wall/Rough_Painted_Wall_001_ROUGH_Small.jpg'),
    []
  )

  const wallImgDiffuse = useMemo(() => repeatTexture(wallImgDiffuseTex, repeatNum), [wallImgDiffuseTex, repeatNum])
  const bumpMap = useMemo(() => repeatTexture(bumpMapTex, repeatNum), [bumpMapTex, repeatNum])
  const lightMap = useMemo(() => repeatTexture(lightMapTex, repeatNum), [lightMapTex, repeatNum])

  const positionX = useMemo(() => {
    let posX = 0

    if (wallPosition === 'back' || wallPosition === 'front') {
      posX = roomType === 'c' || roomType === 'd' || roomType === 'a' || roomAttributes.backWallType === 'b' ? -100 : 0
    } else {
      if (wallPosition === 'left') {
        posX = 0 - roomWidth / 2
      } else {
        posX = roomWidth / 2
      }

      if (roomType === 'c' || roomType === 'd' || roomType === 'a' || roomAttributes.backWallType === 'b') {
        posX -= 100
      }
    }

    return posX
  }, [wallPosition, roomType, roomWidth, roomAttributes.backWallType])

  let positionZ = useMemo(() => {
    let posZ = roomDepth / 2 - wardrobeDepth / 2

    if (wallPosition === 'back') {
      posZ = 0 - wardrobeDepth / 2
    } else if ((wallPosition === 'left' || wallPosition === 'right') && useBackWalls) {
      posZ += wardrobeDepth / 2
    }

    return posZ
  }, [wallPosition, wardrobeDepth, roomDepth, useBackWalls])

  if (wallPosition === 'front') {
    positionZ = roomDepth - wardrobeDepth / 2
  }

  const rotation = useMemo(() => {
    let rotY = 0
    let rotX = 0

    if (wallPosition !== 'back' && wallPosition !== 'front') {
      if (wallPosition === 'left') {
        rotX = Math.PI / 2
        rotY = Math.PI * 2
      } else {
        rotX = Math.PI / 2 + Math.PI
      }
    } else if (wallPosition === 'front') {
      rotX = Math.PI
    }

    return [rotY, rotX, 0]
  }, [wallPosition])

  if (windowWidth) {
    const newWallWeight = (wallWidth - windowWidth) / 2
    let smallWallWeight = newWallWeight

    const newPosition = newWallWeight / 2 + windowWidth / 2
    const doorAttributes = { width: 63, height: roomHeight - 5, isWindow: true }
    const windowsAttributes = { spacesCount: 2, width: 124, height: roomHeight, depth: 10 }

    return (
      <group position={[positionX, 0, positionZ]} rotation={rotation}>
        <group position-x={newPosition}>
          <mesh>
            <planeGeometry attach='geometry' args={[smallWallWeight, roomHeight]} />
            <meshPhongMaterial
              color={wallColor}
              attach='material'
              map={wallImgDiffuse}
              bumpMap={bumpMap}
              bumpScale={0.3}
              lightMap={lightMap}
              lightMapIntensity={0.2}
            />
          </mesh>
        </group>
        {children}
        <group position-y={-roomHeight / 2} position-x={-windowWidth / 2 - 4} position-z={-5}>
          <Windows doorAttributes={doorAttributes} windowsAttributes={windowsAttributes} />
        </group>
        <group position-x={0 - newPosition}>
          <mesh>
            <planeGeometry attach='geometry' args={[newWallWeight, roomHeight]} />
            <meshPhongMaterial
              color={wallColor}
              attach='material'
              map={wallImgDiffuse}
              bumpMap={bumpMap}
              bumpScale={0.3}
              lightMap={lightMap}
              lightMapIntensity={0.2}
            />
          </mesh>
        </group>
      </group>
    )
  } else {
    return (
      <group position={[positionX, 0, positionZ]} rotation={rotation}>
        {children}
        <mesh>
          <planeGeometry attach='geometry' args={[wallWidth, roomHeight]} />
          <meshPhongMaterial
            color={wallColor}
            attach='material'
            map={wallImgDiffuse}
            bumpMap={bumpMap}
            bumpScale={0.3}
            lightMap={lightMap}
            lightMapIntensity={0.2}
          />
        </mesh>
      </group>
    )
  }
}

export default Wall
