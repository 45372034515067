import React, { useCallback } from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import { Lang } from '../../../../Utils'

/**
 * @param {Ref} inputRef
 * @param {String} type
 * @param {String} defaultValue
 * @param {Function} handleChange
 * @param {Function} onFocusInputType
 * @param {Function} onBlurInputType
 * @param {Object} errorObject
 * @param {Boolean} isWrong
 * @returns {JSX.Element}
 * @constructor
 */
const DefInput = ({ inputRef, type, defaultValue, handleChange, onFocusInputType, onBlurInputType, errorObject, isWrong = false }) => {
  const onChangeInput = useCallback(
    (value, type) => {
      handleChange(value, type)
    },
    [handleChange]
  )

  const errorText = errorObject ? errorObject.label : ''

  return (
    <TextField
      inputRef={inputRef}
      error={errorObject || isWrong}
      required
      id={type + 'outlined-error-helper-text'}
      label={Lang(type, 'inputs')}
      defaultValue={defaultValue}
      helperText={errorText}
      variant='outlined'
      onChange={(event) => onChangeInput(event.target.value, type)}
      onFocus={() => onFocusInputType(type)}
      onBlur={(event) => onBlurInputType(event.target.value, type)}
      InputProps={{
        endAdornment: <InputAdornment position='end'>cm</InputAdornment>
      }}
      type='number'
      InputLabelProps={{
        shrink: true
      }}
    />
  )
}

export default DefInput
