import React, { useCallback } from 'react'
import useStore, { getState } from '../../../store'
import { Paper } from '@material-ui/core'
import DoorStepper from '../Components/Molecules/DoorStepper'
import useSteps from '../../../Stores/Steps'
import { Sticky } from 'semantic-ui-react'

/**
 * @returns {*}
 * @constructor
 */
const SelectDoorSpacesCount = ({ contextRef }) => {
  let selectedSpace = useStore((state) => state.selectedSpace)
  const setSpace = getState().setSpace
  const activeTab = useSteps((state) => state.activeTab['selectDoorSpacesCount'])

  if (!selectedSpace) selectedSpace = 1

  const handleNext = useCallback(
    (e) => {
      e.stopPropagation()
      setSpace(selectedSpace * 1 + 1)
    },
    [setSpace, selectedSpace]
  )

  const handleBack = useCallback(
    (e) => {
      e.stopPropagation()
      setSpace(selectedSpace - 1)
    },
    [setSpace, selectedSpace]
  )

  const selectedSpaceId = selectedSpace - 1

  if (activeTab === '2') {
    if (contextRef) {
      return (
        <Sticky context={contextRef} offset={50}>
          <Paper>
            <DoorStepper selectedSpaceId={selectedSpaceId} handleNext={handleNext} handleBack={handleBack} showDoorsFill={false} />
          </Paper>
        </Sticky>
      )
    } else {
      return (
        <>
          <Paper>
            <DoorStepper selectedSpaceId={selectedSpaceId} handleNext={handleNext} handleBack={handleBack} showDoorsFill={false} />
          </Paper>
        </>
      )
    }
  } else {
    return <></>
  }
}

export default SelectDoorSpacesCount
