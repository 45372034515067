import React from 'react'
import { Image } from 'semantic-ui-react'
import { Link } from '@material-ui/core'
import { connect } from 'react-redux'

const LogoImage = ({ height }) => {
  return <Image height={height} src={process.env.PUBLIC_URL + '/images/Logos/' + process.env.REACT_APP_LOGO_FILE_NAME} />
}

const LogoElement = ({ config, height }) => {
  const referer_domain = config?.referer_domain ?? ''

  return (
    <>
      {referer_domain ? (
        <Link href={referer_domain}>
          <LogoImage height={height} />
        </Link>
      ) : (
        <LogoImage height={height} />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  ...state.todos
})

const mapDispatchToProps = () => ({})

class Logo extends React.Component {
  render() {
    return <LogoElement config={this.props?.config} height={this.props?.height ?? ''} />
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logo)
