import React from 'react'
import LoadPrice from './load'
import { getSendData } from '../../utils/api'
import useSteps from '../../Stores/Steps'
import { HIDE_TOP_PRICE } from '../../config'
import useState from '../../store'

export default function PriceText() {
  const sendData = getSendData()
  const actualStep = useSteps((state) => state.actualStep)
  const priceVersion = useState((state) => state.priceVersion)

  return (
    <>
      <LoadPrice priceVersion={priceVersion} sendData={sendData} showPrice={HIDE_TOP_PRICE.indexOf(actualStep) === -1} />
    </>
  )
}
