export const PROFILES_LANG = {
  alOpen: 'Milano',
  alClosed: 'Napoli',
  alMinimax: 'Venezia',
  white: 'Profil weiß',
  sampan: 'Profil champagner',
  silver: 'Profil silber',
  gold: 'Profil gold',
  silverMinimax: 'Profil Minimax silber',
  PleaseSelectProfile: 'Bitte wählen Sie Profiltyp und Farbe',
  alOpenMobile: 'Milano',
  alClosedMobile: 'Napoli',
  alMinimaxMobile: 'Venezia'
}
